import styled from 'styled-components';
import { Button, Button1, CarouselCol, mediaQuery } from '@salutejs/plasma-ui';
import { accent, colors } from '@salutejs/plasma-tokens';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;
  min-height: var(--content-height);
  overflow-x: visible;

  > div {
    &:last-child {
      margin-left: 0;

      > div {
        padding: 0;
      }
    }
  }

  ${mediaQuery('M')(`
    height: var(--content-height);
  `)}

  ${mediaQuery('S')(`
    flex-direction: column;
  `)}
`;

export const StyledCarouselCol = styled(CarouselCol)`
  padding-left: 0;
  padding-right: 1rem;
  display: flex;

  ${mediaQuery('M')(`
    height: var(--content-height);
  `)}

  ${mediaQuery('XL')(`
    padding-right: 2rem;
  `)}
`;

export const ProductsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
`;

export const SpinnerWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
`;

export const OptionsTitle = styled(Button1)`
  font-weight: bold;
  margin-bottom: 0.75rem;
`;

export const OptionsWrapper = styled.div`
  min-width: 11.375rem;
  width: 11.375rem;
  height: var(--content-height);
  padding-right: 1rem;
  overflow-x: visible;
  overflow-y: auto;
  box-sizing: border-box;

  ${mediaQuery('XL')(`
    padding-left: 0.2rem;
    padding-top: 1.5rem;
    padding-right: 2rem;
    padding-bottom: 1.5rem;
    min-width: 22.5rem;
    width: 22.5rem;
  `)}

  ${mediaQuery('S')(`
    min-width: 100%;
    width: 100%;
    height: auto;
    padding-right: 0;
    overflow: visible;
  `)}
`;

export const OptionItemsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
`;

export const OptionItemInner = styled.div`
  display: flex;
  align-items: center;
`;

export const OptionItemWrapper = styled.button<{
  isSelected: boolean;
  isMobile: boolean;
}>`
  display: flex;
  align-products: center;
  justify-content: center;
  max-width: 100%;
  padding: ${(props) =>
    props.isMobile ? '0.5rem 0.75rem' : '0.375rem 0.75rem'};
  margin-bottom: 0.75rem;
  margin-right: 0.625rem;
  border: ${(props) =>
    props.isMobile ? '1px solid rgba(255, 255, 255, 0.2)' : 0};
  border-radius: 1rem;
  background: ${(props) =>
    props.isSelected ? colors.buttonWhite : colors.buttonSecondary};

  &:last-child {
    margin-right: 0;
  }

  :focus {
    border: none;
    box-shadow: 0 0 0 0.125rem ${accent};
  }
`;

export const OptionItemText = styled(Button1)<{ isSelected: boolean }>`
  font-weight: 500;
  color: ${(props) => (props.isSelected ? 'black' : colors.buttonWhite)};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  ${mediaQuery('XL')(`
    line-height: 1.375rem;
  `)}
`;

export const OptionItemSeparator = styled.div`
  width: 0.375rem;
`;

export const OptionsButtonWrapper = styled(Button)`
  height: 2rem;
  margin-bottom: 0.75rem;
  padding: 0.375rem 0.25rem;
  border-radius: 50%;
`;

export const NoProductsWrapper = styled(Button1)`
  margin: 2rem auto;
  text-align: center;
`;

export const Spacer = styled.div`
  min-height: 4rem;
`;
