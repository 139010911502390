import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { AppContext } from 'src/lib/contexts/AppContext';
import { ApplicationHeader } from 'src/components/ApplicationHeader';
import { CartPosition } from './CartPosition';
import { CartGift } from './CartGift';
import { observer } from 'mobx-react';
import { clientHistory } from 'src/history';
import { RootWrapper } from 'src/components/RootWrapper';
import { CartInfoCard } from 'src/components/CartInfoCard';
import { CartGiftProductType } from 'src/types/CartGiftProductType';
import {
  ConfirmationText,
  ErrorBadge,
  Inner,
  MobileCartFooterButton,
  MobileCartFooterRow,
  MobileCartInner,
  PositionWrapper,
  StyledContainer,
  Wrapper,
} from './StyledComponents';
import { isAddSelectedProductToCartCommand } from 'src/types/AssistantCommands/SmartAppDataTypeCheckers/isAddSelectedProductToCartCommand';
import { AddSelectedProductToCartCommand } from 'src/types/AssistantCommands/AddSelectedProductToCartCommand';
import { ProductType } from 'src/types';
import { isRemoveSelectedProductFromCartCommand } from 'src/types/AssistantCommands/SmartAppDataTypeCheckers/isRemoveSelectedProductFromCartCommand';
import { RemoveSelectedProductFromCartCommand } from 'src/types/AssistantCommands/RemoveSelectedProductFromCartCommand';
import { DeviceQueryEnum } from 'src/enums/DeviceQueryEnum';
import { DeviceQuery } from 'src/components/DeviceQuery';
import { Badge, Footnote2 } from '@salutejs/plasma-ui';
import { formatPrice } from 'src/utils/formatPrice';
import { Link } from 'src/components/CartInfoCard/StyledComponents';
import { isGoToAddressCommand } from 'src/types/AssistantCommands/SmartAppDataTypeCheckers/isGoToAddressCommand';

const TITLE = 'Корзина';
const DELIVERY = 'Стоимость доставки';
const SUMMARY = 'Предварительная стоимость заказа';
const PAY = 'К оплате ';
const FREE = 'Бесплатно';
const AGREEMENT = 'Условия продажи';
const PRIVACY_POLICY = 'Политика\u00A0конфиденциальности';

let isSilentCart = false;

export const Cart: React.FunctionComponent<RouteComponentProps> = observer(
  () => {
    const context = React.useContext(AppContext);
    const positions = context.CartService.cartStore.cart.positions;

    const getGifts = () => {
      const gifts: CartGiftProductType[] = [];

      context.CartService.cartStore.cart.gifts.forEach((gift) => {
        gift.products.forEach((product) => gifts.push(product));
      });

      return gifts;
    };

    const gifts: CartGiftProductType[] = getGifts();

    const deliveryPrice =
      context.CartService.cartStore.deliveryCostCalculated.deliveryCost;
    const cartAmount = context.CartService.cartStore.amount;
    const cartTotal = cartAmount + deliveryPrice;
    const loadingState = context.CartService.cartStore.loadingState;
    const [giftsToShow, setGiftsToShow] = React.useState(gifts);
    const summaryPriceText = `${PAY}${formatPrice(cartTotal)}`;

    const deliveryPriceText = !deliveryPrice
      ? `${FREE}`
      : `${formatPrice(deliveryPrice)}`;

    React.useEffect(() => {
      context.CartService.resetIntervals();
    }, []);

    React.useEffect(() => {
      if (positions.length > 0) {
        context.AssistantService.openCart(isSilentCart);
        isSilentCart = true;
      }
    }, [
      context.AssistantService,
      context.CartService.cartStore.positionsCount,
    ]);

    React.useEffect(() => {
      if (positions.length === 0) {
        clientHistory.goBack();
      }
    }, [positions]);

    React.useEffect(() => {
      const unsubscribeFromOnAddSelectedProductToCart =
        context.SmartAppAssistantHelper.conditionalOnData(
          isAddSelectedProductToCartCommand,
          (data: AddSelectedProductToCartCommand) => {
            const productInCart: ProductType = positions.find((position) => {
              return position.item.prices.find(
                (price) => price.title === data.smart_app_data.modifier
              );
            }).item;

            const selectedPrice = productInCart.prices.find(
              (price) => price.title === data.smart_app_data.modifier
            );

            const selectedInCartCount =
              context.CartService.cartStore.countByPositionIdMap.get(
                selectedPrice.id
              );

            context.CartService.changePositionCount(
              selectedPrice.id,
              productInCart,
              selectedInCartCount + parseInt(data.smart_app_data.count)
            );
          }
        );

      const unsubscribeFromOnRemoveSelectedProductFromCart =
        context.SmartAppAssistantHelper.conditionalOnData(
          isRemoveSelectedProductFromCartCommand,
          (data: RemoveSelectedProductFromCartCommand) => {
            const productInCart: ProductType = positions.find((position) => {
              return position.item.prices.find(
                (price) => price.title === data.smart_app_data.modifier
              );
            }).item;

            const selectedPrice = productInCart.prices.find(
              (price) => price.title === data.smart_app_data.modifier
            );

            const selectedInCartCount =
              context.CartService.cartStore.countByPositionIdMap.get(
                selectedPrice.id
              );

            if (
              selectedInCartCount > 0 &&
              selectedInCartCount >= parseInt(data.smart_app_data.count)
            ) {
              context.CartService.changePositionCount(
                selectedPrice.id,
                productInCart,
                selectedInCartCount - parseInt(data.smart_app_data.count)
              );
            } else if (
              selectedInCartCount > 0 &&
              selectedInCartCount < parseInt(data.smart_app_data.count)
            ) {
              context.CartService.changePositionCount(
                selectedPrice.id,
                productInCart,
                0
              );
            }
          }
        );

      const unsubscribeFromOnGoToAddress =
        context.SmartAppAssistantHelper.conditionalOnData(
          isGoToAddressCommand,
          () => {
            if (!context.CartService.cartStore.cartValidationError) {
              context.AssistantService.onGoToAddress();
            }
          }
        );

      return () => {
        unsubscribeFromOnAddSelectedProductToCart();
        unsubscribeFromOnRemoveSelectedProductFromCart();
        unsubscribeFromOnGoToAddress();
      };
    });

    const onClick = React.useCallback(() => {
      clientHistory.push('/address');
    }, []);

    const onGiftClose = (id: number) => {
      setGiftsToShow(giftsToShow.filter((gift) => gift.id !== id));
    };

    const onTermsClick = () => {
      context.AssistantService.onGoToAgreement();
    };

    const onPrivacyPolicyClick = () => {
      context.AssistantService.onGoToPrivacyPolicy();
    };

    return (
      <>
        <ApplicationHeader title={TITLE} isBack={true} />

        <RootWrapper>
          <StyledContainer>
            <Wrapper>
              <Inner>
                <PositionWrapper>
                  {positions.map((position) => (
                    <CartPosition position={position} key={position.id} />
                  ))}
                  {giftsToShow.map((gift) => (
                    <CartGift gift={gift} onClose={onGiftClose} key={gift.id} />
                  ))}
                </PositionWrapper>

                <DeviceQuery on={[DeviceQueryEnum.mobile]}>
                  {!!context.CartService.cartStore.cartValidationError && (
                    <ErrorBadge>
                      {context.CartService.cartStore.cartValidationError}
                    </ErrorBadge>
                  )}
                  <MobileCartFooterButton
                    view="primary"
                    size="m"
                    onClick={onClick}
                    disabled={
                      !!context.CartService.cartStore.cartValidationError
                    }
                  >
                    <span>{PAY}</span>
                    <span>{formatPrice(cartTotal)}</span>
                  </MobileCartFooterButton>
                  <ConfirmationText>
                    Переходя к оплате вы соглашаетесь с условиями продажи и
                    политикой конфиденциальности
                  </ConfirmationText>
                  <MobileCartInner>
                    <Link onClick={onTermsClick}>{AGREEMENT}</Link>
                    <Link onClick={onPrivacyPolicyClick}>{PRIVACY_POLICY}</Link>
                  </MobileCartInner>
                </DeviceQuery>

                <DeviceQuery off={[DeviceQueryEnum.mobile]}>
                  <CartInfoCard
                    buttonText={summaryPriceText}
                    orderSum={cartAmount}
                    deliveryPrice={deliveryPrice}
                    loadingState={loadingState}
                    isTerms
                    isPrivacy
                    showDelivery={false}
                    onClick={onClick}
                  />
                </DeviceQuery>
              </Inner>
            </Wrapper>
          </StyledContainer>
        </RootWrapper>
      </>
    );
  }
);
