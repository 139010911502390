import {
  Body1,
  Button2,
  Caption,
  Footnote2,
  Headline1,
  Headline4,
  Image,
  mediaQuery,
  ParagraphText1,
  Row,
} from '@salutejs/plasma-ui';
import styled from 'styled-components';
import { Link as ReactLink } from 'react-router-dom';
import { Tabs } from '@salutejs/plasma-ui/components/Tabs';
import { colors } from '@salutejs/plasma-tokens';
import { ParagraphText } from '../TermsOfService/StyledComponents';
import { accent } from '@salutejs/plasma-core';

export const Link = styled(ReactLink)`
  border-radius: 1.25rem;

  &:last-of-type {
    margin-bottom: 0;
  }

  :focus-visible {
    border: none;
    box-shadow: 0 0 0 0.125rem ${accent};
  }
`;

export const StyledRow = styled(Row)`
  justify-content: center;
`;

export const OrderNumber = styled(Button2)`
  margin-bottom: 0.25rem;
  font-weight: 500;
  color: ${colors.secondary};
`;

export const OrderNumberMobile = styled(ParagraphText)`
  color: ${colors.secondary};
`;

export const OrderPrice = styled(Headline4)`
  margin-bottom: 1rem;
`;
export const OrderPriceMobile = styled(Headline1)``;

export const Address = styled(Caption)`
  margin-bottom: 0.25rem;
  color: ${colors.secondary};
`;

export const OrderWrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 1rem;
  background-color: ${colors.backgroundSecondary};
  box-sizing: border-box;
  margin: 0 auto;

  ${mediaQuery('S')(`
    width: 100%;
  `)}
`;

export const Delivery = styled(Caption)`
  margin-bottom: 1rem;
  color: ${colors.secondary};
`;

export const OrderImage = styled(Image)`
  width: 3.88rem;
  height: 3.88rem;
  border-radius: 0.63rem;
  margin-right: 1rem;

  ${mediaQuery('M')(`
    order: 1;
    width: 2.8125rem;
    min-width: 2.8125rem;
    height: 2.8125rem;
    border-radius: 50%;
    margin-right: 0;

    img {
      border-radius: 50%;
    }
  `)}
`;

export const OrderInnerRow = styled.div`
  display: flex;
  flex-direction: row;
  aling-items: flex-start;
  margin-bottom: 1rem;

  ${mediaQuery('M')(`
    margin-bottom: 0;
`)}
`;

export const OrderTitleCol = styled.div`
  display: flex;
  flex-direction: column;

  ${mediaQuery('M')(`
    flex: 1;
    margin-right: 1rem;
  `)}
`;

export const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  height: var(--content-height);
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: var(--content-height);
`;

export const EmptyText = styled(ParagraphText1)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: var(--content-height);
`;

export const TabsWrapper = styled.div`
  padding-top: 0.75rem;
`;

export const TabsWithMargin = styled(Tabs)`
  margin-bottom: 1.5rem;
`;

export const OrdersCount = styled(Footnote2)`
  margin-left: 0.375rem;
  color: ${colors.secondary};
`;

export const Inner = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const OrderMobileText = styled(Body1)`
  color: ${colors.secondary};
  margin-bottom: 1rem;
`;

export const OrderLink = styled(ReactLink)`
  display: flex;
  border-radius: 1.25rem;
  margin-bottom: 0.75rem;
  overflow: hidden;

  &:focus {
    border: none;
    box-shadow: 0 0 0 0.125rem ${accent};
  }
`;
