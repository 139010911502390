import { Button, Spinner } from '@salutejs/plasma-ui';
import { observer } from 'mobx-react';
import * as React from 'react';
import styled from 'styled-components';

export const Wrapper = styled(Button)`
  margin-top: 1.5rem;
  font-size: 0.875rem;
`;

const GO_ON = 'Продолжить';

export const GoOnButton: React.FunctionComponent<{
  isDisabled: boolean;
  isProcessing?: boolean;
  onClick: (evt?: React.SyntheticEvent) => void;
}> = observer((props) => {
  return (
    <Wrapper
      view="primary"
      text={props.isProcessing ? <Spinner size={32} /> : GO_ON}
      size="m"
      stretch
      disabled={props.isDisabled}
      onClick={props.onClick}
    />
  );
});
