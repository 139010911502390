import { H3 } from '@salutejs/plasma-core';
import { Button, CarouselCol, mediaQuery, TabItem } from '@salutejs/plasma-ui';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 1rem;

  ${mediaQuery('S')(`
    display: block;
  `)};
`;

export const DatesCarouselComponent = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  padding: 1rem 0 0.5rem;
  margin-bottom: -0.5rem;
  flex-wrap: nowrap;
  max-width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const StyledCarouselCol = styled.div`
  display: flex;
  padding-left: 0;
  padding-right: 1rem;

  &:last-child {
    padding-right: 0;
  }
`;

export const TimePickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  ${mediaQuery('S')(`
    box-sizing: border-box;
    padding-bottom: 4.25rem;
  `)};
`;

export const Inner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
`;

export const SpinnerWrapper = styled.div`
  height: calc(var(--content-height) - 2.25rem - 0.75rem);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const IntervalsWrapper = styled.div`
  margin-top: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  ${mediaQuery('S')(`
    width: 100%;
  `)}
`;

export const CarouselWrapper = styled.div`
  margin-top: 1.5rem;
  width: 100%;

  ${mediaQuery('XL')(`
    & > div {
      & > div {
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
      }
    }
  `)}
`;

export const TabsWrapper = styled.div`
  width: 100%;
  padding-top: 0.75rem;
`;

export const StyledTabItem = styled(TabItem)`
  white-space: initial;
  & > * {
    white-space: initial;
  }
`;

export const ErrorText = styled(H3)`
  text-align: center;
  margin-bottom: 1rem;
  margin-right: 2rem;
  margin-left: 2rem;
`;
