import { inject, injectable } from 'inversify';
import { AssistantClientInterface } from 'src/types';
import { SmartAppFeedbackType } from 'src/types/SmartAppFeedbackType';
import { UnsubscribeFunctionType } from 'src/types/UnsubscribeFunctionType';
import { Identifiers } from '../identifiers';
import { PubsubHelper } from './PubsubHelper';
import { GenericActionType } from 'src/types/SaluteActions/GenericActionType';

@injectable()
export class SmartAppAssistantHelper {
  @inject(Identifiers.helpers.PubsubHelper) private pubsubHelper: PubsubHelper;

  private _assistantClient: AssistantClientInterface<any>;

  private get assistantClient(): AssistantClientInterface<any> {
    if (!this._assistantClient) {
      throw new Error('Assistant client is not set');
    }

    return this._assistantClient;
  }

  private onAssistantDataEventName = `${this.constructor.name}.event`;

  private onAssistantData = (data: any) => {
    console.info({ data });
    this.pubsubHelper.trigger(this.onAssistantDataEventName, data);
  };

  setAssistantClient = (assistantClient: AssistantClientInterface<any>) => {
    this._assistantClient = assistantClient;
  };

  createAssistantEvents = () => {
    console.info('Create assistant events');
    this._assistantClient.on('data', this.onAssistantData);
  };

  /**
   * Sends voice feedback to salute assistant
   */
  sendVoiceFeedback = (feedback: SmartAppFeedbackType) => {
    const random = Math.round(Math.random() * (feedback.text.length - 1));
    this.assistantClient.sendData({
      action: {
        action_id: 'VoiceFeedback',
        payload: {
          text: feedback.text[random],
        },
      },
    });
  };

  sendAction = (action: GenericActionType) => {
    console.info('action: ', action.action_id);

    this.assistantClient.sendData({
      action: action,
    });
  };

  onData(callback: (data: any) => void): UnsubscribeFunctionType {
    this.pubsubHelper.on(this.onAssistantDataEventName, callback);

    return () => this.pubsubHelper.off(this.onAssistantDataEventName, callback);
  }

  /**
   * Add conditional listener,
   * triggers if comparator returns true
   * @param comparator
   * @param callback
   * @returns
   */
  conditionalOnData(
    comparator: (data: any) => boolean,
    callback: (data: any) => void
  ): UnsubscribeFunctionType {
    const createdCallback = (data: any) => {
      if (!comparator(data)) {
        return;
      }

      callback(data);
    };
    this.pubsubHelper.on(this.onAssistantDataEventName, createdCallback);

    return () =>
      this.pubsubHelper.off(this.onAssistantDataEventName, createdCallback);
  }

  /**
   * Close app
   */
  close(): void {
    this.assistantClient.close();
  }
}
