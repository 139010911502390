import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { AppContext } from 'src/lib/contexts/AppContext';
import { ProductType } from 'src/types';
import { Button, Spinner } from '@salutejs/plasma-ui';
import { ApplicationHeader } from 'src/components/ApplicationHeader';
import { RootWrapper } from 'src/components/RootWrapper';
import { observer } from 'mobx-react';
import { ApplicationProduct } from 'src/components/ApplicationProduct';
import { LoadingStateEnum } from 'src/types/enums/LoadingStateEnum';
import { NotFound } from './NotFound';
import {
  CardAmount,
  CardText,
  Form,
  Input,
  Inner,
  ProductsWrapper,
  SearchCard,
  StyledCarouselCol,
  StyledContainer,
  TextWrapper,
  Wrapper,
  ApplicationCarousel,
} from './StyledComponents';
import { DeviceQuery } from 'src/components/DeviceQuery';
import { DeviceQueryEnum } from 'src/enums/DeviceQueryEnum';
import { isMakeSearchCommand } from 'src/types/AssistantCommands/SmartAppDataTypeCheckers/isMakeSearchCommand';
import { MakeSearchCommand } from 'src/types/AssistantCommands/MakeSearchCommand';
import * as qs from 'qs';
import { useScrollIntoView } from 'src/hooks/useScrollintoView';
import { clientHistory } from 'src/history';

const pluralize = require('pluralize-ru');

const TITLE = 'Поиск';
const BUTTON_TEXT = 'Найти';
const RESULTS = 'Результаты поиска';
const FOUND = 'Нашлось';

export const Search: React.FunctionComponent<RouteComponentProps> = observer(
  (props) => {
    const context = React.useContext(AppContext);

    const [searchText, setSearchText] = React.useState('');

    const [loadingState, setLoadingState] = React.useState(
      LoadingStateEnum.initial
    );

    const makeSearch = React.useCallback(
      async (newSearchText: string) => {
        if (searchText !== newSearchText) {
          setSearchText(newSearchText);
        }

        setLoadingState(LoadingStateEnum.processing);
        clientHistory.replace(`/search?text=${newSearchText}`);
        await context.CategoriesService.setFoundProducts(newSearchText);

        setLoadingState(LoadingStateEnum.success);
      },
      [setLoadingState]
    );

    React.useEffect(() => {
      const search = props.location.search.replace(/^\?/, '');
      const searchParams = qs.parse(search);
      console.info(searchParams);

      if (searchParams?.text) {
        makeSearch(searchParams.text.toString());
      }

      const unsubscribe = context.SmartAppAssistantHelper.conditionalOnData(
        isMakeSearchCommand,
        (command: MakeSearchCommand) => {
          makeSearch(command.smart_app_data.searchText);
        }
      );
      return () => {
        unsubscribe();
      };
    }, []);

    const products = context.CategoriesService.categoriesStore.foundProducts;

    const amount = pluralize(
      products.length,
      'нет товаров',
      'товар',
      'товара',
      'товаров'
    );

    const getCount = (product: ProductType) => {
      return product.prices.reduce((sum, price) => {
        return (
          sum + context.CartService.cartStore.countByPositionIdMap.get(price.id)
        );
      }, 0);
    };

    const onChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
      setSearchText(evt.target.value);
    };

    const onSubmit = async (evt: React.FormEvent) => {
      evt.preventDefault();

      if (!searchText) return;

      makeSearch(searchText);
    };

    const onBackButtonClick = async () => {
      await context.CategoriesService.resetSearch();
      setLoadingState(LoadingStateEnum.initial);
      setSearchText('');
    };

    const onFocus = useScrollIntoView();

    const renderProducts = () => {
      if (loadingState === LoadingStateEnum.processing) {
        return (
          <Inner>
            <Spinner />
          </Inner>
        );
      } else if (loadingState === LoadingStateEnum.success) {
        if (products.length === 0) {
          return (
            <Inner>
              <NotFound onClick={onBackButtonClick} />
            </Inner>
          );
        } else {
          return (
            <>
              <DeviceQuery on={[DeviceQueryEnum.mobile]}>
                <TextWrapper>
                  {FOUND}
                  {'\u00A0'}
                  {products.length}
                  {'\u00A0'}
                  {amount}
                  {'\u00A0'}
                </TextWrapper>

                <ProductsWrapper>
                  {products.map((product) => (
                    <ApplicationProduct
                      product={product}
                      count={getCount(product)}
                      key={`${product.id}`}
                    />
                  ))}
                </ProductsWrapper>
              </DeviceQuery>

              <DeviceQuery off={[DeviceQueryEnum.mobile]}>
                <Wrapper>
                  <SearchCard>
                    <CardText>{FOUND}</CardText>

                    <CardAmount>{products.length}</CardAmount>

                    <CardText>{amount}</CardText>
                  </SearchCard>

                  <ApplicationCarousel>
                    {products.map((product) => (
                      <StyledCarouselCol key={`${product.id}`}>
                        <ApplicationProduct
                          product={product}
                          count={getCount(product)}
                          onFocus={onFocus}
                        />
                      </StyledCarouselCol>
                    ))}
                  </ApplicationCarousel>
                </Wrapper>
              </DeviceQuery>
            </>
          );
        }
      }
    };

    const isSuccess = loadingState === LoadingStateEnum.success;

    return (
      <>
        <ApplicationHeader
          title={isSuccess ? searchText : TITLE}
          subtitle={isSuccess ? RESULTS : ''}
          isBack={true}
          isReversed={isSuccess}
        />
        <RootWrapper>
          <StyledContainer>
            {loadingState === LoadingStateEnum.initial && (
              <Form onSubmit={onSubmit}>
                <Input
                  placeholder={TITLE}
                  value={searchText}
                  onChange={onChange}
                  autoFocus
                />
                <DeviceQuery on={[DeviceQueryEnum.mobile]}>
                  <Button
                    view="primary"
                    type="submit"
                    outlined={false}
                    stretch
                    disabled={searchText.length === 0}
                    onClick={onSubmit}
                  >
                    {BUTTON_TEXT}
                  </Button>
                </DeviceQuery>
              </Form>
            )}
            {renderProducts()}
          </StyledContainer>
        </RootWrapper>
      </>
    );
  }
);
