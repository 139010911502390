import * as React from 'react';
import styled from 'styled-components';
import { Button, Footnote2, mediaQuery } from '@salutejs/plasma-ui';

const Wrapper = styled(Button)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(50% - 0.5rem);
  margin-bottom: 0.5rem;

  &:last-child {
    margin-bottom: 0;
  }

  ${mediaQuery('S')(`
    width: calc(50% - 0.25rem);
  `)}
`;

const Text = styled(Footnote2)`
  font-weight: bold;
`;

export const Interval: React.FunctionComponent<{
  id: number;
  period: string;
  onClick: (date: number) => void;
}> = (props) => {
  const onClick = React.useCallback(() => {
    props.onClick(props.id);
  }, [props.id]);

  const period =
    props.period.length > 11 ? props.period.slice(0, 11) : props.period;

  return (
    <Wrapper size="m" onClick={onClick}>
      <Text>{period}</Text>
    </Wrapper>
  );
};
