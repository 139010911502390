import * as React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

interface ImageProps {
  alt: string;
  height?: number;
  src: string;
  width?: number;
}
export const ApplicationImage: React.FunctionComponent<ImageProps> = (
  props
) => {
  return <LazyLoadImage {...props} />;
};
