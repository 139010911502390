import { IconDisclosureRight } from '@salutejs/plasma-icons';
import { colors } from '@salutejs/plasma-tokens';
import { Button, Caption, Footnote1, Underline } from '@salutejs/plasma-ui';
import * as React from 'react';
import { DeviceQuery } from 'src/components/DeviceQuery';
import { DeviceQueryEnum } from 'src/enums/DeviceQueryEnum';
import { changeQuotes } from 'src/utils/changeQuotes';
import styled from 'styled-components';

const Wrapper = styled(Button)`
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: auto;
  padding-top: 0.875rem;
  padding-right: 2rem;
  padding-bottom: 0.875rem;
  padding-left: 1rem;
  margin-bottom: 0.75rem;
  background: ${colors.backgroundSecondary};
  box-sizing: border-box;

  div {
    text-align: left;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const Title = styled(Caption)`
  margin-bottom: 0.25rem;
  color: ${colors.tertiary};
`;

const MobileTitle = styled(Underline)`
  margin-bottom: 0.25rem;
  color: ${colors.secondary};
`;

const ArrowRight = styled(IconDisclosureRight)`
  position: absolute;
  top: calc(50% - 0.75rem);
  right: 0.5rem;
`;

export const CheckoutCard: React.FunctionComponent<{
  title: string;
  text: string;
  onClick: () => void;
}> = (props) => {
  return (
    <Wrapper view="secondary" onClick={props.onClick}>
      <DeviceQuery on={[DeviceQueryEnum.mobile]}>
        <MobileTitle>{changeQuotes(props.title)}</MobileTitle>
      </DeviceQuery>
      <DeviceQuery off={[DeviceQueryEnum.mobile]}>
        <Title>{changeQuotes(props.title)}</Title>
      </DeviceQuery>
      <Footnote1>{props.text}</Footnote1>
      <ArrowRight color={colors.secondary} />
    </Wrapper>
  );
};
