import * as React from 'react';
import { MainItem } from './MainItem';
import { CarouselGridWrapper, mediaQuery } from '@salutejs/plasma-ui';
import styled from 'styled-components';
import { AppContext } from 'src/lib/contexts/AppContext';
import { RootWrapper } from 'src/components/RootWrapper';
import { Link as ReactLink } from 'react-router-dom';
import { accent } from '@salutejs/plasma-tokens';

const Inner = styled.div`
  height: var(--content-height);
  display: flex;
  align-items: center;
`;

const Link = styled(ReactLink)`
  display: block;

  ${mediaQuery('XL')(`
    padding-top: 0.75rem;

    &:focus {
      border: none;
      outline: none;

      .mainItemWrapper {
        box-shadow: 0 0 0 0.375rem ${accent};
      }
    }
  `)}
`;

const SyledApplicationCarousel = styled.div`
  width: 100%;
  padding: 0 3rem;
  box-sizing: border-box;
  overflow: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  & > * {
    margin: 0 1rem;
  }
`;

enum KeyboardKeys {
  ArrowRight = 'ArrowRight',
  ArrowLeft = 'ArrowLeft',
}
const allowedEvents = [KeyboardKeys.ArrowLeft, KeyboardKeys.ArrowRight];

export const SberPortalMain = () => {
  const context = React.useContext(AppContext);
  const categories = context.CategoriesService.categoriesStore.categories;

  const [activeIndex, setActiveIndex] = React.useState(0);
  const carouselItems = React.useRef([]);

  const onKeyDown = React.useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (!allowedEvents.includes(event.key as KeyboardKeys)) {
        return;
      }

      if (event.key === KeyboardKeys.ArrowLeft && activeIndex === 0) {
        return;
      }

      event.preventDefault();
      const nextIndex =
        event.key === KeyboardKeys.ArrowLeft
          ? Math.max(0, activeIndex - 1)
          : Math.min(activeIndex + 1, categories.length - 1);

      setActiveIndex(nextIndex);
      if (carouselItems.current[nextIndex]) {
        carouselItems.current[nextIndex].focus();
      }
    },
    [setActiveIndex, activeIndex]
  );

  return (
    <RootWrapper>
      <Inner>
        <CarouselGridWrapper className="carousel">
          <SyledApplicationCarousel onKeyDown={onKeyDown}>
            {categories &&
              categories.map((item, i) => {
                return (
                  <Link
                    to={`/catalog/${item.id}`}
                    key={item.id}
                    ref={(link) => (carouselItems.current[i] = link)}
                    tabIndex={i}
                    onFocus={(event) => {
                      event.target.scrollIntoView({
                        behavior: 'smooth',
                        inline: 'center',
                        block: 'center',
                      });
                    }}
                  >
                    <MainItem item={item} />
                  </Link>
                );
              })}
          </SyledApplicationCarousel>
        </CarouselGridWrapper>
      </Inner>
    </RootWrapper>
  );
};
