import { colors } from '@salutejs/plasma-tokens';
import { Button, Container, mediaQuery } from '@salutejs/plasma-ui';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-direction: column;
  padding-bottom: 1rem;
  box-sizing: border-box;
  width: 100%;

  ${mediaQuery('S')(`
    display: block;
  `)}

  ${mediaQuery('M')(`
    flex-direction: row
  `)}

  ${mediaQuery('XL')(`
    flex-direction: row;
  `)}
`;

export const StyledContainer = styled(Container)`
  ${mediaQuery('S')(`
    display: block;
  `)}
`;

export const CardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
  align-self: stretch;
  align-items: stretch;

  ${mediaQuery('S')(`
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
`)}

  ${mediaQuery('M')(`
    width: 100%;
    margin-right: 3.5rem;
    align-items: flex-start;
`)}

  ${mediaQuery('XL')(`
    width: 100%;
    margin-right: 3.5rem;
  `)}
`;

export const LoaderWrapper = styled.div`
  height: var(--content-height);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MobileFooterRowWrapper = styled.div`
  margin-top: 1.5rem;
  align-self: stretch;
  display: flex;
  flex-direction: column;

  ${mediaQuery('S')(`
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
`)}
`;

export const MobileFooterRow = styled.div`
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: stretch;

  & > :first-child {
    margin-right: 1rem;
    color: ${colors.secondary};
  }
`;

export const MobileButton = styled(Button)`
  position: fixed;
  bottom: calc(var(--global-bottom-insets) + 0.5rem);
  right: 1rem;

  & > b {
    margin-right: 1rem;
  }
`;

export const SubmitButton = styled(Button)`
  margin: 0;
  margin-bottom: 2rem;
  box-sizing: border-box;
  max-width: 100%;
  ${mediaQuery('S')(`
    width: 100%;
`)}
`;
