import {
  Button,
  Caption,
  Container,
  mediaQuery,
  TextField,
  TextFieldProps,
} from '@salutejs/plasma-ui';
import styled from 'styled-components';
import { colors } from '@salutejs/plasma-tokens';
import { withAutoFocus } from '@salutejs/plasma-core/hocs';

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-top: 0.5rem;

  ${mediaQuery('S')(`
    display: block;
  `)}
`;

export const StyledContainer = styled(Container)`
  ${mediaQuery('S')(`
  display: block;
`)}
`;

export const Inner = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1.5rem;

  ${mediaQuery('S')(`
    display: block;
    margin-bottom: 0;
  `)}
`;

export const Form = styled.form`
  width: 100%;
`;

export const Input = styled(TextField)`
  width: 100%;
  margin-bottom: 0.75rem;

  ${mediaQuery('S')(`
    margin-bottom: 1rem;
  `)}
`;

export const InputWrapper = styled.div`
  width: calc(50% - 0.5rem);

  ${mediaQuery('S')(`
    width: 100%;
    margin-bottom: 2rem;
  `)}
`;

export const Text = styled(Caption)`
  color: ${colors.secondary};

  ${mediaQuery('S')(`
    padding-right: 7rem;
  `)}
`;

export const CheckboxWrapper = styled(Caption)`
  margin-top: -0.75rem;

  ${mediaQuery('S')(`
    margin-top: 1rem;
  `)}
`;

export const GoOnButton = styled(Button)`
  width: calc(50% - 0.5rem);
  z-index: 1;

  ${mediaQuery('S')(`
    width: 100%;
`)}
`;

export const AutoFocusTextField = withAutoFocus<TextFieldProps>(Input);
