import { Footnote1 } from '@salutejs/plasma-ui';
import * as React from 'react';
import { DeviceQuery } from 'src/components/DeviceQuery';
import { screenResolution } from 'src/constants';
import { DeviceQueryEnum } from 'src/enums/DeviceQueryEnum';
import { CartGiftProductType } from 'src/types/CartGiftProductType';
import { changeQuotes } from 'src/utils/changeQuotes';
import crossIcon from './cross.svg';
import {
  CardGiftButton,
  CardGiftImage,
  CardGiftTitle,
  CardGiftText,
  CardGiftWrapper,
  MobilecardGiftDesc,
} from './StyledComponents';
import placeholder from '../../assets/empty.svg';

const TITLE = 'Бесплатный бонус';
const CLOSE = 'Закрыть';

export const CartGift: React.FunctionComponent<{
  gift: CartGiftProductType;
  onClose: (id: number) => void;
}> = (props) => {
  const onClick = React.useCallback(() => {
    props.onClose(props.gift.id);
  }, [props]);

  return (
    <CardGiftWrapper>
      <CardGiftImage
        src={props.gift.images[0].lowres_url || placeholder}
        srcSet={`${props.gift.images[0].lowres_url} ${screenResolution.s}w, ${props.gift.images[0].midres_url} ${screenResolution.m}w, ${props.gift.images[0].highres_url} ${screenResolution.l}w`}
        alt={props.gift.title}
      />

      <DeviceQuery on={[DeviceQueryEnum.mobile]}>
        <div>
          <Footnote1>{changeQuotes(props.gift.title)}</Footnote1>
          <MobilecardGiftDesc>{TITLE}</MobilecardGiftDesc>
        </div>
      </DeviceQuery>

      <DeviceQuery off={[DeviceQueryEnum.mobile]}>
        <div>
          <CardGiftTitle>{TITLE}</CardGiftTitle>
          <CardGiftText>{changeQuotes(props.gift.title)}</CardGiftText>
        </div>
      </DeviceQuery>

      <CardGiftButton
        size="s"
        view="secondary"
        pin="circle-circle"
        onClick={onClick}
      >
        <img src={crossIcon} alt={CLOSE} />
      </CardGiftButton>
    </CardGiftWrapper>
  );
};
