import * as React from 'react';
import { CategoryType, ProductType } from 'src/types';
import { ApplicationCarousel } from 'src/components/ApplicationCarousel';
import { AppContext } from 'src/lib/contexts/AppContext';
import { observer } from 'mobx-react';
import {
  NoProductsWrapper,
  ProductsWrapper,
  OptionsWrapper,
  StyledCarouselCol,
  Spacer,
  SpinnerWrapper,
  Wrapper,
} from './StyledComponents';
import { FiltersMap, sortOptions } from 'src/constants';
import { PriceFiltersType } from 'src/types/PriceFiltersType';
import { isSortProductsCommand } from 'src/types/AssistantCommands/SmartAppDataTypeCheckers/isSortProductsCommand';
import { SortProductsCommand } from 'src/types/AssistantCommands/SortProductsCommand';
import { ProductSortTypeEnum } from 'src/types/ProductSortTypeEnum';
import { clientHistory } from 'src/history';
import { ProductFilterType } from 'src/types/ProductFilterType';
import { useQuery } from 'src/hooks/useQuery';
import { FilterProductsCommand } from 'src/types/AssistantCommands/FilterProductsCommand';
import { isFilterProductsCommand } from 'src/types/AssistantCommands/SmartAppDataTypeCheckers/isFilterProductsCommand';
import { SortOptionType } from 'src/types/SortOptionType';
import { CategoriesOptions } from './CategoriesOptions';
import { ApplicationProduct } from 'src/components/ApplicationProduct';
import { isSelectSubcategoryCommand } from 'src/types/AssistantCommands/SmartAppDataTypeCheckers/isSelectSubcategoryCommand';
import { SelectSubcategoryCommand } from 'src/types/AssistantCommands/SelectSubcategoryCommand';
import { FiltersOptions } from './FiltersOptions';
import { SortingOptions } from './SortingOptions';
import { DeviceQueryEnum } from 'src/enums/DeviceQueryEnum';
import { DeviceTypeContext } from 'src/contexts/DeviceTypeContext';
import { DeviceQuery } from 'src/components/DeviceQuery';
import { ShowMoreCommand } from 'src/types/AssistantCommands/ShowMoreCommand';
import { isShowMoreCommand } from 'src/types/AssistantCommands/SmartAppDataTypeCheckers/isShowMoreCommand';
import { LoadingStateEnum } from 'src/types/enums/LoadingStateEnum';
import { Spinner, Button } from '@salutejs/plasma-ui';
import { SberboxLisingShowMore } from 'src/components/SberboxListingShowMore';
import { isShowMoreProductsCommand } from 'src/types/AssistantCommands/SmartAppDataTypeCheckers/isShowMoreProductsCommand';
import { ShowMoreProductsCommand } from 'src/types/AssistantCommands/ShowMoreProductsCommand';
import { SberPortalProductsList } from './SberPortalProductsList';

const NO_PRODUCTS = 'Нет товаров';

export const Products: React.FunctionComponent<{
  category: CategoryType;
}> = observer((props) => {
  const context = React.useContext(AppContext);

  const deviceType = React.useContext(DeviceTypeContext);

  const isMobile = deviceType === DeviceQueryEnum.mobile;

  const query = useQuery();

  const sortQuery = query.get('sort');

  const categoryQuery = query.get('category') || props.category.id;

  const lowestFilterQuery = query.has('lowest')
    ? Number(query.get('lowest'))
    : undefined;

  const highestFilterQuery = query.has('highest')
    ? Number(query.get('highest'))
    : undefined;

  const products = context.CategoriesService.categoriesStore.currentProducts;

  const [isShowMore, setIsShowMore] = React.useState(false);

  const currentCategory =
    context.CategoriesService.categoriesStore.currentCategory;

  const priceFilters: PriceFiltersType[] = currentCategory.filters.map(
    (filter) => ({
      value: { lowest: filter.lowest_price, highest: filter.highest_price },
      label: `${filter.name} ₽`,
    })
  );

  const currentSortType = sortQuery || ProductSortTypeEnum.default;

  const sortOption = sortOptions.find(
    (option) => option.value === currentSortType
  );

  const filterOption: ProductFilterType = {
    lowest: lowestFilterQuery,
    highest: highestFilterQuery,
  };

  const isTitleShown = !isMobile || (isMobile && isShowMore);

  const loadingState = context.CategoriesService.categoriesStore.loadingState;

  const isProcessing = loadingState === LoadingStateEnum.processing;

  React.useEffect(() => {
    context.CategoriesService.setCurrentProducts(
      Number(categoryQuery),
      sortOption.value,
      filterOption
    );

    const unsubscribeFromSortProducts =
      context.SmartAppAssistantHelper.conditionalOnData(
        isSortProductsCommand,
        (data: SortProductsCommand) => {
          const newOption = sortOptions.find(
            (option) => option.label === data.smart_app_data.sortType
          );
          onChange(newOption);
        }
      );

    const unsubscribeFromFilterProducts =
      context.SmartAppAssistantHelper.conditionalOnData(
        isFilterProductsCommand,
        (data: FilterProductsCommand) => {
          const option = FiltersMap[data.smart_app_data.filterType];
          clientHistory.replace(
            `/catalog/${props.category.id}?sort=${sortOption.value}&lowest=${option.lowest}&highest=${option.highest}&category=${categoryQuery}`
          );
        }
      );

    const unsubscribeFromCategorySelect =
      context.SmartAppAssistantHelper.conditionalOnData(
        isSelectSubcategoryCommand,
        (data: SelectSubcategoryCommand) => {
          const category = props.category.children.find(
            (category) =>
              category.title.toLowerCase() ===
              data.smart_app_data.subcategory.toLowerCase()
          );

          if (category) {
            onChange(category);
          }
        }
      );

    const unsubscribeFromShowMore =
      context.SmartAppAssistantHelper.conditionalOnData(
        isShowMoreCommand,
        (data: ShowMoreCommand) => {
          onShowMoreClick();
        }
      );

    const unsubscribeFromShowMoreProducts =
      context.SmartAppAssistantHelper.conditionalOnData(
        isShowMoreProductsCommand,
        (data: ShowMoreProductsCommand) => {
          context.CategoriesService.loadMoreProducts();
        }
      );

    return () => {
      unsubscribeFromSortProducts();
      unsubscribeFromFilterProducts();
      unsubscribeFromCategorySelect();
      unsubscribeFromShowMore();
      unsubscribeFromShowMoreProducts();
    };
  }, [
    categoryQuery,
    sortQuery,
    lowestFilterQuery,
    highestFilterQuery,
    isShowMore,
  ]);

  React.useEffect(() => {
    if (
      context.CategoriesService.categoriesStore.currentCategory &&
      context.CategoriesService.categoriesStore.currentProducts.length > 0
    ) {
      context.AssistantService.setCategoryProducts();
    }
  }, [context.CategoriesService.categoriesStore.currentProducts]);

  const getCount = (product: ProductType) => {
    return product.prices.reduce((sum, price) => {
      return (
        sum + context.CartService.cartStore.countByPositionIdMap.get(price.id)
      );
    }, 0);
  };

  const onChange = (
    option: ProductFilterType | SortOptionType | CategoryType
  ) => {
    if ('value' in option) {
      let url = `/catalog/${props.category.id}?sort=${option.value}&category=${categoryQuery}`;

      if (filterOption.lowest !== undefined) {
        url += `&lowest=${filterOption.lowest}`;
      }

      if (filterOption.highest !== undefined) {
        url += `&highest=${filterOption.highest}`;
      }

      clientHistory.replace(url);
    } else if ('lowest' in option) {
      if (
        lowestFilterQuery === option.lowest &&
        highestFilterQuery === option.highest
      ) {
        clientHistory.replace(
          `/catalog/${props.category.id}?sort=${sortOption.value}&category=${categoryQuery}`
        );
      } else {
        clientHistory.replace(
          `/catalog/${props.category.id}?sort=${sortOption.value}&category=${categoryQuery}&lowest=${option.lowest}&highest=${option.highest}`
        );
      }
    } else if ('id' in option) {
      let url = `/catalog/${props.category.id}?sort=${sortOption.value}`;

      if (filterOption.lowest !== undefined) {
        url += `&lowest=${filterOption.lowest}`;
      }

      if (filterOption.highest !== undefined) {
        url += `&highest=${filterOption.highest}`;
      }

      if (Number(categoryQuery) === option.id) {
        url += `&category=${props.category.id}`;
      } else {
        url += `&category=${option.id}`;
      }

      clientHistory.replace(url);
    }
  };

  const onShowMoreClick = () => {
    setIsShowMore(isShowMore ? false : true);
  };

  return (
    <Wrapper>
      <OptionsWrapper>
        {props.category.children.length > 0 && (
          <CategoriesOptions
            id={Number(categoryQuery)}
            items={props.category.children}
            isShowMore={isShowMore}
            isTitleShown={isTitleShown}
            isMobile={isMobile}
            onChange={onChange}
            onShowMoreClick={onShowMoreClick}
          />
        )}

        {((isMobile && props.category.children.length === 0) ||
          (isMobile && isShowMore) ||
          !isMobile) && (
          <>
            <FiltersOptions
              option={filterOption}
              items={priceFilters}
              isShowMore={isShowMore}
              isMobile={isMobile}
              isTitleShown={
                isTitleShown ||
                (isMobile && props.category.children.length === 0)
              }
              onChange={onChange}
            />
            <SortingOptions
              option={sortOption}
              items={sortOptions}
              isShowMore={isShowMore}
              isMobile={isMobile}
              isTitleShown={
                isTitleShown ||
                (isMobile && props.category.children.length === 0)
              }
              onChange={onChange}
              onShowMoreClick={onShowMoreClick}
            />
          </>
        )}
      </OptionsWrapper>

      <DeviceQuery on={[DeviceQueryEnum.mobile]}>
        <ProductsWrapper>
          {isProcessing ? (
            <SpinnerWrapper>
              <Spinner />
            </SpinnerWrapper>
          ) : products.length > 0 ? (
            <>
              {products.map((product) => (
                <ApplicationProduct
                  product={product}
                  count={getCount(product)}
                  key={`${product.id}`}
                />
              ))}
              {context.CategoriesService.categoriesStore.hasMoreProducts &&
                (context.CategoriesService.categoriesStore.loadMoreState ===
                LoadingStateEnum.processing ? (
                  <>
                    <SpinnerWrapper>
                      <Spinner size={40} />
                    </SpinnerWrapper>
                  </>
                ) : (
                  <Button
                    size="m"
                    stretch
                    onClick={context.CategoriesService.loadMoreProducts}
                  >
                    Показать еще
                  </Button>
                ))}
            </>
          ) : (
            <NoProductsWrapper>{NO_PRODUCTS}</NoProductsWrapper>
          )}
        </ProductsWrapper>
        <Spacer />
      </DeviceQuery>

      <DeviceQuery off={[DeviceQueryEnum.mobile]}>
        {isProcessing ? (
          <SpinnerWrapper>
            <Spinner />
          </SpinnerWrapper>
        ) : (
          <SberPortalProductsList
            products={products}
            getCount={getCount}
            hasMoreProducts={
              context.CategoriesService.categoriesStore.hasMoreProducts
            }
            loadMoreProducts={context.CategoriesService.loadMoreProducts}
            loadMoreState={
              context.CategoriesService.categoriesStore.loadMoreState
            }
          />
        )}
      </DeviceQuery>
    </Wrapper>
  );
});
