import { P } from '@salutejs/plasma-ui';
import { inject, injectable } from 'inversify';
import { runInAction } from 'mobx';
import { CartGiftType, CartPositionType, ProductType } from 'src/types';
import { DeliveryIntervalType } from 'src/types/DeliveryIntervalType';
import { EnabledExactDeliveryIntervalType } from 'src/types/EnabledExactDeliveryIntervalType';
import { LoadingStateEnum } from 'src/types/enums/LoadingStateEnum';
import { ParsedAddress } from 'src/types/ParsedAddress';
import { WrongCartError } from '../errors/WrongCartError';
import { Identifiers } from '../identifiers';
import { CartServiceInterface } from '../interfaces/services/CartServiceInterface';
import { CartProvider } from '../providers/CartProvider';
import { CartStore } from '../stores/CartStore';
import { UserStore } from '../stores/UserStore';

@injectable()
export class CartService implements CartServiceInterface {
  @inject(Identifiers.stores.CartStore) public cartStore: CartStore;

  @inject(Identifiers.stores.UserStore) public userStore: UserStore;

  @inject(Identifiers.providers.CartProvider)
  private cartProvider: CartProvider;

  addAdditionalService = async (serviceId: number) => {
    console.info('CartService addAdditionalService');
    runInAction(() => {
      this.cartStore.loadingState = LoadingStateEnum.processing;
    });

    try {
      const delivery = await this.cartProvider.getDelivery();
      const additionalServices = [...delivery.additionalServices];

      additionalServices.push({ service_id: serviceId });

      const newDelivery = { ...delivery, additionalServices };

      await this.cartProvider.setDelivery(newDelivery);

      await this.getCart(false);

      runInAction(() => {
        this.cartStore.loadingState = LoadingStateEnum.success;
      });
    } catch (error) {
      runInAction(() => {
        this.cartStore.loadingState = LoadingStateEnum.fail;
      });

      throw error;
    }
  };

  private createPosition = async (
    id: number,
    item: ProductType,
    count?: number
  ) => {
    runInAction(() => {
      this.cartStore.loadingState = LoadingStateEnum.processing;
    });

    try {
      await this.cartProvider.createPosition(id, item, count);

      runInAction(() => {
        this.cartStore.loadingState = LoadingStateEnum.success;
      });
    } catch (error) {
      runInAction(() => {
        this.cartStore.loadingState = LoadingStateEnum.fail;
      });

      throw error;
    }
  };

  private getCheckedCartPositions = async (): Promise<{
    positions: CartPositionType[];
    gifts: CartGiftType[];
  }> => {
    runInAction(() => {
      this.cartStore.loadingState = LoadingStateEnum.processing;
    });

    try {
      const allPositions = await this.cartProvider.getCartPositions();

      if (allPositions.length === 0) {
        return { positions: [], gifts: [] };
      }

      const checkedCart = await this.cartProvider.checkCart(allPositions);
      const availableProducts = checkedCart?.available_products;
      const gifts = checkedCart?.gifts;
      const positions: CartPositionType[] = [];

      availableProducts?.forEach((product) => {
        const price_id = parseInt(product.price_id as unknown as string);
        const position = allPositions.find(
          (position) => price_id === position.id
        );
        if (!position) return;
        positions.push({
          id: product.price_id,
          item: position.item,
          count: product.quantity,
        });
      });

      await this.cartProvider.updateCartPositions(positions);

      runInAction(() => {
        this.cartStore.loadingState = LoadingStateEnum.success;
      });

      return { positions, gifts };
    } catch (error) {
      if (error instanceof WrongCartError) {
        runInAction(() => {
          this.cartStore.loadingState = LoadingStateEnum.success;
        });
        const allPositions = await this.cartProvider.getCartPositions();

        return {
          positions: allPositions,
          gifts: [],
        };
      } else {
        runInAction(() => {
          this.cartStore.loadingState = LoadingStateEnum.fail;
        });
      }

      throw error;
    }
  };

  changePositionCount = async (
    id: number,
    item: ProductType,
    count?: number,
    checkCartPositions?: boolean
  ) => {
    console.info('CartService changePositionCount');
    runInAction(() => {
      this.cartStore.loadingState = LoadingStateEnum.processing;
    });

    try {
      const inCartCount = this.cartStore.countByPositionIdMap.get(id);

      if (inCartCount >= 0) {
        console.info('change position count');

        await this.cartProvider.changePositionCount(id, count);
      } else {
        console.info('create position');
        if (count === 0) {
          return;
        }

        await this.createPosition(id, item, count);
      }

      // if (!checkCartPositions) {
      //   const positions = await this.cartProvider.getCartPositions();
      //   runInAction(() => {
      //     this.cartStore.cart.positions = positions;
      //   });
      // }

      await this.getCart();

      runInAction(() => {
        this.cartStore.loadingState = LoadingStateEnum.success;
      });
    } catch (error) {
      runInAction(() => {
        this.cartStore.loadingState = LoadingStateEnum.fail;
      });

      throw error;
    }
  };

  changeAddress = async (address: ParsedAddress | string) => {
    console.info('CartService change address', address);

    runInAction(() => {
      this.cartStore.loadingState = LoadingStateEnum.processing;
    });

    try {
      if (typeof address === 'string') {
        await this.cartProvider.setAddress({
          value: address,
          address: {
            location: {
              latitude: null,
              longitude: null,
            },
            street: null,
            number: null,
            building: null,
            room: null,
          },
        });
      } else {
        await this.cartProvider.setAddress(address);
      }

      const storedAddress = await this.cartProvider.getAddress();
      let deliveryIntervals: DeliveryIntervalType[] = [];
      let deliberyIntervalsError: string = null;
      try {
        deliveryIntervals = await this.cartProvider.loadDeliveryIntervals(
          this.cartStore.cart.positions,
          this.cartStore.cart?.options?.isAskAddress
            ? {
                value: null,
                address: null,
              }
            : storedAddress
        );
      } catch (error) {
        deliberyIntervalsError = (error as Error).message;
      }

      runInAction(() => {
        this.cartStore.loadingState = LoadingStateEnum.success;
        this.cartStore.cart.selectedAddress = storedAddress;
        this.cartStore.deliveryIntervals = deliveryIntervals;
        this.cartStore.deliberyIntervalsError = deliberyIntervalsError;
      });
    } catch (error) {
      console.error(error);
      runInAction(() => {
        this.cartStore.loadingState = LoadingStateEnum.fail;
      });

      throw error;
    }
  };

  changeComment = async (comment: string) => {
    runInAction(() => {
      this.cartStore.loadingState = LoadingStateEnum.processing;
    });

    try {
      await this.cartProvider.setComment(comment);

      runInAction(() => {
        this.cartStore.cart.comment = comment;
        this.cartStore.loadingState = LoadingStateEnum.success;
      });
    } catch (error) {
      runInAction(() => {
        this.cartStore.loadingState = LoadingStateEnum.fail;
      });

      throw error;
    }
  };

  changeDelivery = async (intervalId: number, date: string) => {
    console.info('CartService change delivery');
    runInAction(() => {
      this.cartStore.loadingState = LoadingStateEnum.processing;
    });

    try {
      await this.cartProvider.setDelivery({
        intervalId,
        date,
        type: 'courier',
        additionalServices: [],
      });

      await this.getCart(false);

      runInAction(() => {
        this.cartStore.loadingState = LoadingStateEnum.success;
      });
    } catch (error) {
      runInAction(() => {
        this.cartStore.loadingState = LoadingStateEnum.fail;
      });

      throw error;
    }
  };

  changeCartOption = async (option: { [key: string]: boolean }) => {
    runInAction(() => {
      this.cartStore.loadingState = LoadingStateEnum.processing;
    });

    try {
      await this.cartProvider.setCartOption(option);
      const options = await this.cartProvider.getCartOptions();

      runInAction(() => {
        this.cartStore.cart.options = options;
        this.cartStore.loadingState = LoadingStateEnum.success;
      });
    } catch (error) {
      runInAction(() => {
        this.cartStore.loadingState = LoadingStateEnum.fail;
      });

      throw error;
    }
  };

  changeReceiverName = async (name: string) => {
    runInAction(() => {
      this.cartStore.loadingState = LoadingStateEnum.processing;
    });

    try {
      await this.cartProvider.setReceiverName(name);

      const receiver = {
        ...this.cartStore.cart.receiver,
        name,
      };

      runInAction(() => {
        this.cartStore.cart.receiver = receiver;
        this.cartStore.loadingState = LoadingStateEnum.success;
      });
    } catch (error) {
      runInAction(() => {
        this.cartStore.loadingState = LoadingStateEnum.fail;
      });

      throw error;
    }
  };

  changeReceiverPhone = async (phone: string) => {
    runInAction(() => {
      this.cartStore.loadingState = LoadingStateEnum.processing;
    });

    try {
      await this.cartProvider.setReceiverPhone(phone);

      const receiver = {
        ...this.cartStore.cart.receiver,
        phone,
      };

      runInAction(() => {
        this.cartStore.cart.receiver = receiver;
        this.cartStore.loadingState = LoadingStateEnum.success;
      });
    } catch (error) {
      runInAction(() => {
        this.cartStore.loadingState = LoadingStateEnum.fail;
      });

      throw error;
    }
  };

  isPaymentSuccessful = async (invoiceId: string): Promise<boolean> => {
    runInAction(() => {
      this.cartStore.loadingState = LoadingStateEnum.processing;
    });

    try {
      const isPaymentSuccessful = await this.cartProvider.checkPaymentStatus(
        invoiceId
      );

      runInAction(() => {
        this.cartStore.loadingState = LoadingStateEnum.success;
      });

      return isPaymentSuccessful;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  async checkStorageKeys(): Promise<void> {
    await this.cartProvider.checkStorageKeys();
  }

  getCart = async (shouldCheckPositions = true) => {
    console.info('Get cart');
    runInAction(() => {
      this.cartStore.loadingState = LoadingStateEnum.processing;
    });

    try {
      let positions: CartPositionType[];
      let gifts: any;
      if (shouldCheckPositions) {
        const checkedCart = await this.getCheckedCartPositions();
        positions = checkedCart.positions;
        gifts = checkedCart.gifts;
      } else {
        positions = this.cartStore.cart?.positions || [];
        gifts = this.cartStore.cart?.gifts || [];
      }

      const comment = await this.cartProvider.getComment();
      const selectedAddress = await this.cartProvider.getAddress();
      const delivery = await this.cartProvider.getDelivery();
      const name = await this.cartProvider.getReceiverName();
      const phone = await this.cartProvider.getReceiverPhone();
      const receiver = { name, phone };
      const options = await this.cartProvider.getCartOptions();

      runInAction(() => {
        this.cartStore.loadingState = LoadingStateEnum.success;
        this.cartStore.cart = {
          comment,
          amount: 0,
          gifts,
          delivery,
          positions,
          receiver,
          selectedAddress,
          options,
        };
      });
    } catch (error) {
      runInAction(() => {
        this.cartStore.loadingState = LoadingStateEnum.fail;
      });

      throw error;
    }
  };

  getInvoiceId = async (): Promise<string> => {
    const { invoice, customerOrderId, orderId } =
      await this.cartProvider.getInvoiceId(
        this.cartStore.cart,
        this.userStore.user
      );

    runInAction(() => {
      this.cartStore.currentProccessingOrderId = orderId;
      this.cartStore.currentProccessingClientOrderId = customerOrderId;
    });

    return invoice;
  };

  isServiceAvaiable = (id: number) => {
    let isAvaiable = false;
    const date = this.cartStore.cart.delivery?.date;

    if (date.length > 10) {
      const deliveryDate = new Date(date);
      const hour = deliveryDate.getHours();
      const minutes = deliveryDate.getMinutes();

      const services = this.cartStore.exactDeliveryIntervals.find(
        (interval) =>
          interval.date.hour === hour && interval.date.minutes === minutes
      )?.services;

      isAvaiable =
        services?.findIndex((service) => Number(service) === id) !== -1;
    } else {
      const interval = this.cartStore.deliveryIntervalByDateMap.get(date);

      isAvaiable = interval?.deliveries
        ? interval?.deliveries
            .find((delivery) => delivery.key_id === 'courier')
            .additional_services.findIndex(
              (service) => service.key_id === id
            ) !== -1
        : false;
    }

    return isAvaiable;
  };

  loadExactDeliveryIntervals = async (date: string): Promise<void> => {
    runInAction(() => {
      this.cartStore.loadingState = LoadingStateEnum.processing;
      this.cartStore.exactDeliveryIntervals = [];
    });

    try {
      const exactDeliveryIntervals =
        await this.cartProvider.loadExactDeliveryIntervals(
          this.cartStore.cart.positions,
          this.cartStore.cart.options.isAskAddress
            ? {
                value: null,
                address: null,
              }
            : this.cartStore.cart.selectedAddress,
          date
        );
      const enabledExactDeliveryIntervals: EnabledExactDeliveryIntervalType[] =
        [];
      const year = new Date(date).getFullYear();
      const month = new Date(date).getMonth() + 1;
      const day = new Date(date).getDate();

      exactDeliveryIntervals?.forEach((interval) => {
        if (interval.enabled) {
          interval.minutes_interval?.forEach((minutesInterval) => {
            if (minutesInterval.enabled) {
              enabledExactDeliveryIntervals.push({
                date: {
                  year,
                  month,
                  day,
                  hour: Number(interval.hour),
                  minutes: Number(minutesInterval.minute),
                },
                services: minutesInterval.enabled_additional_services_ids,
              });
            }
          });
        }
      });

      runInAction(() => {
        this.cartStore.loadingState = LoadingStateEnum.success;
        this.cartStore.exactDeliveryIntervals = enabledExactDeliveryIntervals;
      });
    } catch (error) {
      runInAction(() => {
        this.cartStore.loadingState = LoadingStateEnum.fail;
      });
    }
  };

  parseAddress = async (address: string): Promise<void> => {
    runInAction(() => {
      this.cartStore.loadingState = LoadingStateEnum.processing;
      this.cartStore.parsedAddresses = null;
    });

    try {
      const parsedAddresses = await this.cartProvider.parseAddress(address);
      runInAction(() => {
        this.cartStore.loadingState = LoadingStateEnum.success;
        this.cartStore.parsedAddresses = parsedAddresses;
      });
    } catch (error) {
      runInAction(() => {
        this.cartStore.loadingState = LoadingStateEnum.fail;
      });
    }
  };

  removeAdditionalService = async (serviceId: number) => {
    console.info('cartService removeAdditionalService');
    const delivery = await this.cartProvider.getDelivery();
    const additionalServices = [...delivery.additionalServices];
    const index = additionalServices.findIndex(
      (service) => service.service_id === serviceId
    );
    const newAdditionalServices = additionalServices.filter(
      (service, i) => i !== index
    );

    const newDelivery = {
      ...delivery,
      additionalServices: newAdditionalServices,
    };

    await this.cartProvider.setDelivery(newDelivery);

    await this.getCart(false);
  };

  resetCart = async () => {
    await this.cartProvider.resetCart();
    await this.getCart();
  };

  resetOptions = async () => {
    await this.cartProvider.resetOptions();
    const cartOptions = await this.cartProvider.getCartOptions();
    runInAction(() => {
      this.cartStore.cart.options = cartOptions;
    });
  };

  resetReceiverInfo = async () => {
    await this.cartProvider.resetReceiverInfo();
    const name = await this.cartProvider.getReceiverName();
    const phone = await this.cartProvider.getReceiverPhone();
    const receiver = { name, phone };
    runInAction(() => {
      this.cartStore.cart.receiver = receiver;
    });
  };

  resetIntervals(): Promise<void> {
    runInAction(() => {
      this.cartStore.deliveryIntervals = [];
      this.cartStore.exactDeliveryIntervals = [];
    });

    return Promise.resolve();
  }

  cancelOrder(orderId: string): Promise<void> {
    return this.cartProvider.cancelOrder(orderId);
  }

  setShouldNavigateToCheckout(value: boolean): Promise<void> {
    runInAction(() => {
      this.cartStore.shouldNavigateToCheckout = value;
    });

    return Promise.resolve();
  }
}
