import * as React from 'react';
import { Container, H2Compatible } from '@salutejs/plasma-ui';
import { AppContext } from 'src/lib/contexts/AppContext';
import { RootWrapper } from 'src/components/RootWrapper';
import { MobileCategory } from './MobileCategory';
import styled from 'styled-components';
import { changeQuotes } from 'src/utils/changeQuotes';
import { MobileCartButtonSpacer } from 'src/components/MobileCartButtonSpacer';

const Heading = styled(H2Compatible)`
  margin-bottom: 1rem;
  width: 100%;
  max-width: 100%;
`;

const Inner = styled.div`
  columns: 2;
  column-gap: 0.5rem;
`;

export const MobileMain = (): JSX.Element => {
  const context = React.useContext(AppContext);
  const categories = context.CategoriesService.categoriesStore.categories;
  const randomCategory = React.useMemo(() => {
    if (categories.length === 0) return null;

    const random = Math.round(
      Math.random() * Math.max(0, categories.length - 1)
    );

    return categories[random];
  }, [categories]);

  return (
    <>
      {categories && (
        <RootWrapper>
          <Container>
            <Inner>
              <Heading>
                Салют, покажи{' '}
                {changeQuotes(randomCategory?.title.toLowerCase())}
              </Heading>
              {categories.map((category, index) => (
                <MobileCategory
                  category={category}
                  key={category.id}
                  isStyled={Math.floor(categories.length / 2) === index}
                />
              ))}
            </Inner>
            <MobileCartButtonSpacer />
          </Container>
        </RootWrapper>
      )}
    </>
  );
};
