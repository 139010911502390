import {
  Button,
  Image,
  HeaderLogo,
  HeaderRoot,
  HeaderTitle,
  mediaQuery,
} from '@salutejs/plasma-ui';
import { IconChevronLeft } from '@salutejs/plasma-icons';
import styled from 'styled-components';
import { accent } from '@salutejs/plasma-core';
import { fonts } from '@salutejs/plasma-tokens';

export const Wrapper = styled(HeaderRoot)`
  position: relative;
  position: fixed;
  width: 100%;
  height: 4.5rem;
  min-height: 4.5rem;
  top: 0px;
  z-index: 1;
  padding: 1.125rem 3.5rem;
  box-sizing: border-box;
  background: linear-gradient(
    to bottom,
    var(--plasma-colors-background-primary) 0%,
    var(--plasma-colors-background-primary) 45%,
    rgba(0, 0, 0, 0) 100%
  );

  ${mediaQuery('S')(`
    padding-right: 1rem;
  `)}
`;

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
`;

export const IconList = styled(Image)`
  width: 1rem;
  height: 1.25rem;
  overflow: visible;
`;

export const IconChevronDown = styled(IconChevronLeft)`
  transform: rotate(-90deg);
`;

export const BackButton = styled(Button)`
  position: absolute;
  left: -2.5rem;

  ${mediaQuery('XL')(`
    position: relative;
    left: 0;
    height: 2.5rem;
    margin-right: 0.5rem;
  `)}
`;

export const CartButton = styled(Button)`
  position: relative;
`;

export const MobileCartButton = styled(Button)`
  z-index: 1;
  position: fixed;
  bottom: var(--global-bottom-insets);
  right: 1rem;
  left: 1rem;
  width: calc(100% - 2rem);
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  justify-content: space-between;
`;

export const OrdersButton = styled(Button)`
  ${mediaQuery('S')(`
    padding-top: 1.2rem;
  `)}

  ${mediaQuery('M')(`
    padding-top: 1.2rem;
  `)}
`;

export const Badge = styled.span`
  position: absolute;
  top: 0.5rem;
  right: 1.1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 0.9rem;
  height: 0.9rem;
  padding: 0.1rem;
  background-color: ${accent};
  border-radius: 50%;

  ${mediaQuery('XL')(`
    right: auto;
    left: 2rem;
    top: 0;
  `)}
`;

export const MobileBadge = styled.span`
  position: absolute;
  top: 0.5rem;
  right: auto;
  left: 1.85rem;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 0.9rem;
  height: 1rem;
  background-color: white;
  border-radius: 50%;
  border: 1px solid ${accent};
  font-family: ${fonts.Medium};
  font-size: 0.625rem;
  line-height: 0.75rem;
  color: ${accent};
`;

export const Logo = styled(HeaderLogo)`
  margin-right: 0.75rem;
  background-color: white;
  border-radius: 0.5rem;

  ${mediaQuery('S')(`
    width: 1.875rem;
    height: 1.875rem;
    flex: inherit;
    margin-right: 1.5rem;
  `)}
`;
