import * as React from 'react';
import { Footnote1 } from '@salutejs/plasma-ui';
import { Link } from 'react-router-dom';
import { CategoryType } from 'src/types';
import styled from 'styled-components';
import { colors } from '@salutejs/plasma-tokens';
import { changeQuotes } from 'src/utils/changeQuotes';
import placeholder from '../../assets/empty.svg';

const LinkWrapper = styled(Link)<{ $isStyled: boolean }>`
  padding: 1em;
  display: flex;
  flex-direction: column;
  color: ${colors.text};
  background: ${colors.surfaceLiquid01};
  border-radius: 1em;
  margin-bottom: 0.5em;
  margin-top: ${(props) => (props.$isStyled ? '2rem' : 0)};
`;

const CategroyImage = styled.img`
  width: 6.25em;
  height: 6.25em;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
  margin-top: 0.75em;
  align-self: center;
  background-color: ${colors.surfaceSolid01};
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
`;

const Col = styled.div`
  width: 100%;
  margin: 0;
  page-break-inside: avoid;
  break-inside: avoid-column;
  display: table;
`;

export const MobileCategory = ({
  category,
  isStyled,
}: {
  category: CategoryType;
  isStyled?: boolean;
}): JSX.Element => {
  return (
    <Col key={category.id}>
      <LinkWrapper to={`/catalog/${category.id}`} $isStyled={isStyled}>
        <Footnote1>{changeQuotes(category.title)}</Footnote1>

        <CategroyImage
          src={category.images2.midres_url || placeholder}
          alt={category.title}
        />
      </LinkWrapper>
    </Col>
  );
};
