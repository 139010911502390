import styled from 'styled-components';
import {
  Button,
  Image,
  Footnote2,
  Caption,
  mediaQuery,
  Footnote1,
  Container,
  Badge,
} from '@salutejs/plasma-ui';
import { colors, colorValues, fonts } from '@salutejs/plasma-tokens';

export const Wrapper = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;

  ${mediaQuery('S')(`
    align-items: flex-start;
    padding-top: 0.5rem;
  `)}
`;

export const Inner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-grow: 1;
  flex-direction: column;

  ${mediaQuery('M')(`
    flex-direction: row;
  `)}

  ${mediaQuery('XL')(`
    flex-direction: row;
    width: 100%;
    max-width: 100%;
  `)}
`;

export const PositionWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const CardGiftWrapper = styled.div`
  position: relative;
  border-radius: 1.5rem;
  background-color: ${colors.backgroundSecondary};
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-self: stretch;
  padding: 1rem;
  padding-right: 2.75rem;
  margin-bottom: 1.5rem;
  margin-right: 3.1875rem;

  ${mediaQuery('S')(`
    margin-right: 0;
  `)}

  ${mediaQuery('XL')(`
    margin-right: 10rem;
  `)}
`;

export const CardGiftImage = styled(Image)`
  margin-right: 1rem;
  min-width: 2rem;
  width: 2rem;
  height: 2rem;
  border-radius: 0.5rem;

  ${mediaQuery('M')(`
    margin-bottom: 1rem;
    width: 4.625rem;
    min-width: 4.625rem;
    height: 4.625rem;
    border-radius: 20px;
  `)}
`;

export const CardGiftTitle = styled(Caption)`
  margin-bottom: 0.125rem;
  padding-right: 2rem;
  color: ${colors.secondary};
`;

export const CardGiftText = styled(Footnote2)`
  margin-bottom: 0.875rem;
  padding-right: 2rem;
  font-family: ${fonts.Bold};

  ${mediaQuery('XL')(`
    font-size: 0.875rem;
    line-height: 1.125rem;
  `)}
`;

export const CardGiftButton = styled(Button)`
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  width: 2rem;
  min-width: 2rem;
  height: 2rem;
  padding: 0;

  img {
    width: 1.25rem;
    height: 1.25rem;
  }
`;

export const CartPositionWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  margin-bottom: 1.5rem;
  padding-right: 3.1875rem;

  ${mediaQuery('XL')(`
    padding-right: 10rem;
  `)}
`;

export const CartPositionInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const CartPositionImage = styled(Image)`
  border-radius: 1.25rem;
  margin-right: 1rem;
  flex-shrink: 0;
  width: 7.5rem;
  height: 7.5rem;

  ${mediaQuery('M')(`
    width: 8rem;
    height: 8rem;
  `)}
`;

export const StepperWrapper = styled.div`
  margin-top: auto;

  ${mediaQuery('XL')(`
    button {
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 50%;
    }
  `)}
`;

export const CartPositionTitleWrapper = styled.div`
  margin-bottom: 0.5rem;

  ${mediaQuery('M')(`
    margin-bottom: 0.75rem;
  `)}
`;

export const CartPositionText = styled(Caption)`
  margin-bottom: 0.875rem;
  font-family: ${fonts.Bold};
  color: ${colors.secondary};

  ${mediaQuery('XL')(`
    margin-bottom: 0.5rem;
    font-size: 0.875rem;
    line-height: 1.125rem;
  `)}
`;

export const CartPositionTextMobile = styled(Footnote1)`
  color: ${colors.secondary};
  margin-bottom: 0.5rem;
`;

export const MobilecardGiftDesc = styled(CartPositionTextMobile)``;

export const MobileCartFooterRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  align-self: stretch;

  & > :first-child {
    color: ${colors.secondary};
    margin-right: 1rem;
  }
`;

export const ConfirmationText = styled(Caption)`
  margin-top: 1.5rem;
  margin-bottom: -1rem;
`;

export const MobileCartInner = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: stretch;
  margin-bottom: 1rem;
`;

export const MobileCartFooterButton = styled(Button)`
  align-self: stretch;
  display: flex;
  justify-content: space-between;
`;

export const StyledContainer = styled(Container)`
  height: var(--content-height);
`;

export const SpinnerWrapper = styled.div`
  height: 2rem;
  width: 6.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ErrorBadge = styled.div`
  font-family: ${fonts.Regular};
  margin-bottom: 1rem;
  background-color: ${colorValues.buttonCritical};
  max-width: 100%;
  overflow: hidden;
  padding: 0.5rem;
  border-radius: 5px;
  opacity: 0.9;
`;
