import { GenericSmartAppDataCommand } from '../GenericSmartAppDataCommand';
import { MakeGlobalSearchCommand } from '../MakeGlobalSearchCommand';

export function isGlobalMakeSearchCommand(
  command: GenericSmartAppDataCommand
): command is MakeGlobalSearchCommand {
  console.info(
    command,
    command?.smart_app_data?.action === 'makeGlobalSearch' &&
      !!command?.smart_app_data?.searchText
  );
  return (
    command?.smart_app_data?.action === 'makeGlobalSearch' &&
    !!command?.smart_app_data?.searchText
  );
}
