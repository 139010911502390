import { mediaQuery } from '@salutejs/plasma-ui';
import styled from 'styled-components';

export const RootWrapper = styled.div`
  margin-top: 4.5rem;
  padding-right: 0;
  margin-bottom: var(--global-bottom-insets);
  padding-left: 0;
  overflow: auto;
`;
