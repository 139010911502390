import styled from 'styled-components';
import { Footnote1, mediaQuery } from '@salutejs/plasma-ui';
import { Link } from 'react-router-dom';
import { ApplicationImage } from 'src/components/ApplicationImage';
import { accent, colors } from '@salutejs/plasma-tokens';

export const Wrapper = styled(Link)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  min-width: calc(var(--content-height) * 0.65);
  max-width: calc(var(--content-height) * 0.65);
  margin-bottom: 0;
  box-sizing: border-box;
  border-radius: 1.25rem;
  background: ${colors.backgroundSecondary};
  overflow: hidden;
  align-self: stretch;

  img {
    border-radius: 1.25rem 1.25rem 0 0;
  }

  ${mediaQuery('S')(`
    min-height: 17rem;
    height: auto;
    min-width: calc(50% - 0.5rem);
    max-width: calc(50% - 0.5rem);
    margin-bottom: 0.5rem;
  
    img {
      width: 100%;
      height: auto; 
    }
  `)}

  ${mediaQuery('XL')(`
    width: 10rem;
  
    &::before {
      z-index: 1;
      display: none;
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      border: 0.375rem solid ${accent};
      border-radius: 1.25rem;
    }

    &:focus {
      &::before {
        display: block;
      }
    }
  `)}
`;

export const Inner = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  position: relative;
  width: 100%;
  box-sizing: border-box;

  ${mediaQuery('M')(`
    align-items: flex-start;
  `)}
`;

export const ProductImage = styled(ApplicationImage)`
  border-radius: 1.25rem 1.25rem 0 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin: 0;
  margin-top: 0;
`;

export const ProductImageWrapper = styled.div`
  max-width: 100%;
  border-radius: 1.25rem 1.25rem 0 0;
  position: relative;
  overflow: hidden;
  aspect-ratio: 1;

  img {
    position: absolute;
    width: 100%;
  }
`;

export const TitleWrapper = styled.div`
  display: inline;
  margin-bottom: 0.25rem;
  white-space: normal;

  ${mediaQuery('S')(`
    margin-bottom: 1rem;
  `)}
`;

export const Title = styled(Footnote1)`
  display: inline;
  margin-right: 0.25rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-align: start;

  ${mediaQuery('S')(`
    text-align: center;
  `)}

  ${mediaQuery('M')(`
    display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
  `)}
`;

export const AddToCartButton = styled.div`
  top: -1.55rem;
  right: 0.75rem;
  position: absolute;
  padding: 0.5rem;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  color: ${colors.primary};
  background: ${colors.buttonAccent};
  box-sizing: border-box;
`;
