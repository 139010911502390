import * as React from 'react';
import { observer } from 'mobx-react';
import { CartPositionType } from 'src/types';
import {
  Footnote1,
  Footnote2,
  Price,
  Spinner,
  Stepper,
} from '@salutejs/plasma-ui';
import { AppContext } from 'src/lib/contexts/AppContext';
import {
  CartPositionImage,
  CartPositionInner,
  CartPositionText,
  CartPositionWrapper,
  StepperWrapper,
  SpinnerWrapper,
  CartPositionTitleWrapper,
  CartPositionTextMobile,
} from './StyledComponents';
import { DeviceQueryEnum } from 'src/enums/DeviceQueryEnum';
import { DeviceQuery } from 'src/components/DeviceQuery';
import { formatPrice } from 'src/utils/formatPrice';
import { changeQuotes } from 'src/utils/changeQuotes';
import { useLoadingWrapper } from 'src/hooks/useLoadingWrapper';
import { removeRoundBracketsContent } from 'src/utils/removeRoundBracketsContent';
import placeholder from '../../assets/empty.svg';

const BOUQUET = 'Букет';

export const CartPosition: React.FunctionComponent<{
  position: CartPositionType;
}> = observer((props) => {
  const context = React.useContext(AppContext);
  const count = context.CartService.cartStore.countByPositionIdMap.get(
    props.position.id
  );
  const item = props.position.item.prices.find(
    (position) => position.id === props.position.id
  );
  const price = item.price;
  const description =
    item.title.toLowerCase() !== 'без размера' && changeQuotes(item.title);
  const useLoading = useLoadingWrapper();
  const waitForPromise = useLoading.waitForPromise;
  const isProcessing = useLoading.isLoading;
  const title = removeRoundBracketsContent(
    changeQuotes(props.position.item.title_short)
  );

  const onChange = React.useCallback(
    (count: number) => {
      waitForPromise(
        context.CartService.changePositionCount(
          props.position.id,
          props.position.item,
          count
        )
      );
    },
    [props.position.item, count]
  );

  const onRemove = React.useCallback(() => {
    waitForPromise(
      context.CartService.changePositionCount(
        props.position.id,
        props.position.item,
        0
      )
    );
  }, [props.position.item]);

  return (
    <CartPositionWrapper>
      <CartPositionImage
        src={props.position.item.images2[0].midres_url || placeholder}
        alt={BOUQUET}
      />
      <CartPositionInner>
        <CartPositionTitleWrapper>
          <DeviceQuery on={[DeviceQueryEnum.mobile]}>
            <Footnote1>{title}</Footnote1>
          </DeviceQuery>
          <DeviceQuery off={[DeviceQueryEnum.mobile]}>
            <Footnote2>{title}</Footnote2>
          </DeviceQuery>
        </CartPositionTitleWrapper>

        <DeviceQuery on={[DeviceQueryEnum.mobile]}>
          <Footnote1>{formatPrice(price)}</Footnote1>
          <CartPositionTextMobile>{description}</CartPositionTextMobile>
        </DeviceQuery>
        <DeviceQuery off={[DeviceQueryEnum.mobile]}>
          <Footnote2>{formatPrice(price)}</Footnote2>
          <CartPositionText>{description}</CartPositionText>
        </DeviceQuery>

        {!isProcessing && (
          <StepperWrapper>
            <Stepper
              step={1}
              value={count}
              min={1}
              showRemove={true}
              pin="circle-circle"
              onChange={onChange}
              onRemove={onRemove}
            />
          </StepperWrapper>
        )}
        {isProcessing && (
          <SpinnerWrapper>
            <Spinner size={32} />
          </SpinnerWrapper>
        )}
      </CartPositionInner>
    </CartPositionWrapper>
  );
});
