import {
  Caption,
  Footnote2,
  Headline4,
  Image,
  mediaQuery,
  Row,
} from '@salutejs/plasma-ui';
import styled from 'styled-components';
import { colors, fonts } from '@salutejs/plasma-tokens';

export const Wrapper = styled.div`
  position: relative;
  margin: 0 auto;
  padding-top: 0.25rem;
  padding-right: 1rem;
  padding-bottom: 0.25rem;
  padding-left: 1rem;
  background-color: ${colors.backgroundSecondary};
  border-radius: 20px;
  box-sizing: border-box;

  ${mediaQuery('S')(`
    width: 100%;
    background-color: transparent;
  `)}

  ${mediaQuery('XL')(`
    width: 100%;
    padding-top: 0.5rem;
    padding-right: 1.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1.5rem;
  `)}
`;

export const FlexInner = styled.div<{ isLong?: boolean }>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 0.0625rem solid ${colors.buttonSecondary};

  ${mediaQuery('M')(`
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  `)}

  ${mediaQuery('XL')(`
    justify-content: flex-start;
  `)}

  ${(props) => {
    if (props.isLong) {
      return mediaQuery('XL')(`
      justify-content: space-between;
    `);
    }
  }}
`;

export const Inner = styled.div<{ isLast?: boolean }>`
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  border-bottom: ${(props) =>
    props.isLast
      ? ''
      : '0.0625rem solid var(--plasma-colors-button-secondary,rgba(255,255,255,0.12))'};

  ${mediaQuery('S')(`
    padding-top: 1rem;
    padding-bottom: 1rem;
  `)}

  ${mediaQuery('XL')(`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
  `)}
`;

export const Price = styled(Headline4)`
  margin-bottom: 1rem;
`;

export const PositionsWrapper = styled.div`
  ${mediaQuery('S')(`
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-bottom: 0.0625rem solid ${colors.buttonSecondary};
  `)}

  ${mediaQuery('XL')(`
    border-bottom: 0.0625rem solid ${colors.buttonSecondary};
  `)}
`;

export const PositionWrapper = styled.div`
  position: relative;
  padding-top: 0.75rem;
  padding-right: 1rem;
  padding-bottom: 0.75rem;
  padding-left: 3.75rem;
  border-bottom: 0.0625rem solid ${colors.buttonSecondary};

  ${mediaQuery('S')(`
    display: flex;
    flex-direction: column;
    min-height: 5rem;
    padding-left: 6rem;
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 1rem;
    border-bottom: none;

    &:last-of-type {
      margin-bottom: 0;
    }
  `)}

  ${mediaQuery('XL')(`
    min-height: 4.5rem;
    padding-left: 6rem;
    margin-bottom: 1rem;
    border-bottom: none;

    &:last-of-type {
      margin-bottom: 0;
    }
  `)}
`;

export const PositionTitle = styled(Footnote2)`
  font-weight: bold;
  margin-bottom: 0.25rem;

  ${mediaQuery('S')(`
    font-weight: 500;
    margin-bottom: 0.5rem;
  `)}

  ${mediaQuery('XL')(`
    font-size: 1rem;
    line-height: 1.375rem;
    margin-bottom: 0.5rem;
  `)}
`;

export const PositionPrice = styled(Footnote2)`
  font-weight: bold;

  ${mediaQuery('S')(`
    margin-top: auto;
    font-weight: 500;
  `)}

  ${mediaQuery('XL')(`
    font-size: 1rem;
    line-height: 1.25rem;
  `)}
`;

export const PositionText = styled(Caption)`
  font-family: ${fonts.Medium};
  color: ${colors.secondary};

  ${mediaQuery('S')(`
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.125rem;
  `)}

  ${mediaQuery('XL')(`
    font-size: 1rem;
    line-height: 1.375rem;
    font-weight: normal;
  `)}
`;

export const PositionImage = styled(Image)`
  position: absolute;
  top: 0.75rem;
  left: 0;
  width: 3rem;
  min-width: 3rem;
  height: 3rem;
  border-radius: 12px;

  img {
    border-radius: 12px;
  }

  ${mediaQuery('S')(`
    top: 0;
    width: 5rem;
    min-width: 5rem;
    height: 5rem;
  `)}

  ${mediaQuery('XL')(`
    top: auto;
    width: 4.5rem;
    min-width: 4.5rem;
    height: 4.5rem;
  `)}
`;

export const Text = styled(Footnote2)`
  margin-top: 0.25rem;

  ${mediaQuery('S')(`
    margin-top: 0.5rem;
    font-size: 1rem;
    line-height: 1.25rem;
    font-weight: 500;
  `)}
`;

export const Title = styled(Caption)<{ isLong?: boolean }>`
  color: ${colors.secondary};

  ${mediaQuery('S')(`
    font-size: 1rem;
    line-height: 1.25rem;
  `)}

  ${mediaQuery('XL')(`
    width: 13.25rem;
    min-width: 13.25rem;
    font-size: 1rem;
    line-height: 1.25rem;
  `)}

  ${(props) => {
    if (props.isLong) {
      return mediaQuery('XL')(`
      width: auto;
    `);
    }
  }}
`;

export const SpinnerWrapper = styled(Caption)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
`;

export const Quantity = styled.span`
  margin-left: 0.75rem;
  color: ${colors.secondary};
`;

export const InfoText = styled(Caption)<{ isPhone?: boolean }>`
  white-space: ${(props) => (props.isPhone ? 'nowrap' : 'normal')};

  ${mediaQuery('S')(`
    font-size: 1rem;
    line-height: 1.25rem;
    font-weight: 500;
  `)}

  ${mediaQuery('XL')(`
    font-size: 1rem;
    line-height: 1.25rem;
    font-weight: 500;
  `)}
`;

export const CheckboxWrapper = styled.div`
  margin-top: 0.75rem;

  ${mediaQuery('XL')(`
    width: 100%;
  `)}
`;

export const StyledRow = styled(Row)`
  justify-content: center;
`;
