import * as React from 'react';
import styled from 'styled-components';

interface ToastCloseButtonProps {
  closeToast: () => void;
}

const Wrapper = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-width: 12px;
  min-height: 12px;
  max-width: 12px;
  max-height: 12px;
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none;

  svg {
    width: 100%;
  }
`;

export const ToastCloseButton: React.FunctionComponent<ToastCloseButtonProps> =
  (props: ToastCloseButtonProps) => {
    return (
      <Wrapper onClick={props.closeToast}>
        <svg viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M11.5 0.5L0.5 11.5"
            stroke="white"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M0.5 0.5L11.5 11.5"
            stroke="white"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </Wrapper>
    );
  };
