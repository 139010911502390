import * as React from 'react';
import { Price } from '@salutejs/plasma-ui';
import { OrderItemType } from 'src/types/OrderItemType';
import {
  PositionImage,
  PositionPrice,
  PositionTitle,
  PositionText,
  PositionWrapper,
  Quantity,
} from './StyledComponents';
import { changeQuotes } from 'src/utils/changeQuotes';
import placeholder from '../../assets/empty.svg';

export const Position: React.FunctionComponent<{ position: OrderItemType }> = ({
  position,
}) => {
  return (
    <PositionWrapper>
      <PositionImage
        src={position.images2[0].lowres_url || placeholder}
        alt={position.title}
      />

      <PositionTitle>{changeQuotes(position.title)}</PositionTitle>

      <PositionPrice>
        <Price>{position.price}</Price>
        {position.quantity > 1 && <Quantity>x{position.quantity}</Quantity>}
      </PositionPrice>
    </PositionWrapper>
  );
};
