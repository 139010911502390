import { H1 } from '@salutejs/plasma-ui';
import * as React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';

export const NotFound: React.FunctionComponent<RouteComponentProps> = () => {
  return (
    <div>
      <H1>Данного экрана не существует</H1>
      <Link to="/">На главную</Link>
    </div>
  );
};
