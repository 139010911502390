require('react-toastify/dist/ReactToastify.css');
import styled from 'styled-components';
import { colors } from '@salutejs/plasma-tokens';
import { ToastContainer } from 'react-toastify';

export const StyledToastContainer = styled(ToastContainer)`
  .Toastify__toast {
    border-radius: 20px;
    padding: 1rem;
    margin: 1rem;
  }
  .Toastify__toast--success {
    background-color: ${colors.accent};
  }
  .Toastify__toast-body {
    padding: 0;
  }
`;
