import { injectable } from 'inversify';
import * as idb from 'idb-keyval';

@injectable()
export class IDBStorageHelper {
  setItem(key: string, value: string): Promise<void> {
    return idb.set(key, value);
  }

  getItem(key: string): Promise<string> {
    return idb.get(key);
  }

  removeItem(key: string): Promise<void> {
    return idb.del(key);
  }

  clear(): Promise<void> {
    return idb.clear();
  }
}
