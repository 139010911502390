import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Body2, Caption, Spinner } from '@salutejs/plasma-ui';
import { ApplicationHeader } from 'src/components/ApplicationHeader';
import { AppContext } from 'src/lib/contexts/AppContext';
import doneOstrich from 'src/assets/doneOstrich.png';
import doneOstrichM from 'src/assets/doneOstrich@2x.png';
import doneOstrichL from 'src/assets/doneOstrich@3x.png';
import doneOstrichXL from 'src/assets/doneOstrich@4x.png';
import runningOstrich from 'src/assets/runningOstrich.png';
import runningOstrichM from 'src/assets/runningOstrich@2x.png';
import runningOstrichL from 'src/assets/runningOstrich@3x.png';
import runningOstrichXL from 'src/assets/runningOstrich@4x.png';
import erorrOstrich from 'src/assets/errorOstrich.png';
import erorrOstrichM from 'src/assets/errorOstrich@2x.png';
import erorrOstrichL from 'src/assets/errorOstrich@3x.png';
import erorrOstrichXL from 'src/assets/errorOstrich@4x.png';
import { screenResolution } from 'src/constants';
import { isPaymentResultCommand } from 'src/types/AssistantCommands/SmartAppDataTypeCheckers/isPaymentResultCommand';
import { PaymentResultCommand } from 'src/types/AssistantCommands/PaymentResultCommand';
import { clientHistory } from 'src/history';
import { observer } from 'mobx-react';
import { PaymentStatusEnum } from 'src/types/enums/PaymentStatusEnum';
import { LoadingStateEnum } from 'src/types/enums/LoadingStateEnum';
import {
  ButtonsWrapper,
  Image,
  ImageWrapper,
  Inner,
  Button,
  SpinnerWrapper,
  SmallSpinnerWrapper,
  StyledContainer,
  Text,
  Title,
  TextWrapper,
  Wrapper,
} from './StyledComponents';
import { isRepeatPaymentCommand } from 'src/types/AssistantCommands/SmartAppDataTypeCheckers/isRepeatPaymentCommand';
import { isGoToOrderDetailsCommand } from 'src/types/AssistantCommands/SmartAppDataTypeCheckers/isGoToOrderDetailsCommand';
import { DeviceQuery } from 'src/components/DeviceQuery';
import { DeviceQueryEnum } from 'src/enums/DeviceQueryEnum';
import { GetInvoiceIdError } from 'src/lib/errors/GetInvoiceIdError';
import { toast } from 'react-toastify';

const TITLE = 'Оформляем заказ';
const RUNNING_OSTRICH = 'Бегущий страус';
const ATTENTION = 'Предупреждающий страус';
const OSTRICH_WITH_FLOWER = 'Страус с цветком';
const SUCCESS = 'Спасибо за заказ!';
const FAIL = 'Не получилось оформить заказ';
const FAIL_TEXT = 'Пожалуйста, попробуйте ещё раз';
const REPEAT = 'Повторить';
const CANCEL = 'Не сейчас';
const DETAILS = 'Детали заказа';

export const Payment: React.FunctionComponent<RouteComponentProps> = observer(
  () => {
    const context = React.useContext(AppContext);
    const orders = context.OrdersService.ordersStore.orders;
    const [paymentStatus, setPaymentStatus] =
      React.useState<PaymentStatusEnum>(null);
    const [loadingState, setLoadingState] = React.useState<LoadingStateEnum>(
      LoadingStateEnum.initial
    );
    const successText = `Заказ № ${context.CartService.cartStore.currentProccessingClientOrderId} успешно оплачен и передан в магазин. Мы сообщим вам об изменении статуса заказа`;

    const onPaymentResultSubmit = async (data: PaymentResultCommand) => {
      setLoadingState(LoadingStateEnum.processing);

      const isPaymentSuccessful =
        data.smart_app_data.status === 0
          ? await context.CartService.isPaymentSuccessful(
              data.smart_app_data.invoice
            )
          : false;

      if (isPaymentSuccessful) {
        await context.CartService.resetCart();

        setPaymentStatus(PaymentStatusEnum.success);
      } else {
        setPaymentStatus(PaymentStatusEnum.fail);
        context.CartService.cancelOrder(
          context.CartService.cartStore.currentProccessingOrderId
        );
      }

      await context.OrdersService.getOrders();

      setLoadingState(LoadingStateEnum.success);
    };

    const onCancelButtonClick = () => {
      clientHistory.push('/');
    };

    const makePayment = React.useCallback(async () => {
      try {
        await context.AssistantService.makePayment();
      } catch (error) {
        setPaymentStatus(PaymentStatusEnum.fail);
        if (error instanceof GetInvoiceIdError) {
          toast.error(
            <>
              <Body2>При оплате возникла ошибка</Body2>
              <Caption style={{ marginBottom: 8 }}>
                {error.messages.map((message) => (
                  <div key={message}>{message}</div>
                ))}
              </Caption>
            </>
          );
        }
      }
    }, [context.AssistantService.makePayment]);

    const onRepeatButtonClick = () => {
      setPaymentStatus(null);

      makePayment();
    };

    const onOrderDetailsButtonClick = () => {
      clientHistory.push(
        `/order/${context.CartService.cartStore.currentProccessingOrderId}`
      );
    };

    React.useEffect(() => {
      //on mount
      if (clientHistory.action === 'POP') {
        clientHistory.push('/');
      } else {
        makePayment();
      }
    }, []);

    React.useEffect(() => {
      const unsubscribeFromOnPaymentResult =
        context.SmartAppAssistantHelper.conditionalOnData(
          isPaymentResultCommand,
          onPaymentResultSubmit
        );

      const unsubscribeFromOnPaymentRepeat =
        context.SmartAppAssistantHelper.conditionalOnData(
          isRepeatPaymentCommand,
          onRepeatButtonClick
        );

      const unsubscribeFromOnOrderDetailsClick =
        context.SmartAppAssistantHelper.conditionalOnData(
          isGoToOrderDetailsCommand,
          onOrderDetailsButtonClick
        );

      return () => {
        unsubscribeFromOnPaymentResult();
        unsubscribeFromOnPaymentRepeat();
        unsubscribeFromOnOrderDetailsClick();
      };
    }, [onPaymentResultSubmit, onRepeatButtonClick, onOrderDetailsButtonClick]);

    React.useEffect(() => {
      if (paymentStatus === PaymentStatusEnum.fail) {
        context.AssistantService.changePaymentStatus('fail');
      } else if (paymentStatus === PaymentStatusEnum.success) {
        context.AssistantService.changePaymentStatus('success');
      }
    }, [paymentStatus]);

    return (
      <>
        <ApplicationHeader isLogo title={TITLE} isBack={true} />

        {loadingState !== LoadingStateEnum.processing && (
          <Wrapper>
            <StyledContainer>
              {paymentStatus === null && (
                <Inner>
                  <TextWrapper>
                    <Title isShort>{TITLE}</Title>

                    <DeviceQuery on={[DeviceQueryEnum.mobile]}>
                      <SmallSpinnerWrapper>
                        <Spinner size={56} />
                      </SmallSpinnerWrapper>
                    </DeviceQuery>

                    <DeviceQuery off={[DeviceQueryEnum.mobile]}>
                      <SmallSpinnerWrapper>
                        <Spinner size={112} />
                      </SmallSpinnerWrapper>
                    </DeviceQuery>
                  </TextWrapper>

                  <ImageWrapper type="processing">
                    <Image
                      src={runningOstrich}
                      srcSet={`${runningOstrich} ${screenResolution.s}w, ${runningOstrichM} ${screenResolution.m}w, ${runningOstrichL} ${screenResolution.l}w, ${runningOstrichXL} ${screenResolution.xl}w`}
                      alt={RUNNING_OSTRICH}
                    />
                  </ImageWrapper>
                </Inner>
              )}

              {paymentStatus === PaymentStatusEnum.success && (
                <Inner>
                  <TextWrapper>
                    <Title>{SUCCESS}</Title>

                    <Text>{successText}</Text>

                    <ButtonsWrapper>
                      <DeviceQuery on={[DeviceQueryEnum.mobile]}>
                        <Button
                          size="m"
                          view="secondary"
                          text={DETAILS}
                          stretch
                          isLong
                          onClick={onOrderDetailsButtonClick}
                        />
                      </DeviceQuery>

                      <DeviceQuery off={[DeviceQueryEnum.mobile]}>
                        <Button
                          size="s"
                          view="secondary"
                          text={DETAILS}
                          onClick={onOrderDetailsButtonClick}
                        />
                      </DeviceQuery>
                    </ButtonsWrapper>
                  </TextWrapper>

                  <ImageWrapper type="success">
                    <Image
                      src={doneOstrich}
                      srcSet={`${doneOstrich} ${screenResolution.s}w, ${doneOstrichM} ${screenResolution.m}w, ${doneOstrichL} ${screenResolution.l}w, ${doneOstrichXL} ${screenResolution.xl}w`}
                      alt={OSTRICH_WITH_FLOWER}
                    />
                  </ImageWrapper>
                </Inner>
              )}

              {paymentStatus === PaymentStatusEnum.fail && (
                <Inner>
                  <TextWrapper>
                    <Title>{FAIL}</Title>

                    <Text>{FAIL_TEXT}</Text>

                    <ButtonsWrapper>
                      <DeviceQuery on={[DeviceQueryEnum.mobile]}>
                        <Button
                          size="m"
                          view="primary"
                          text={REPEAT}
                          stretch
                          onClick={onRepeatButtonClick}
                        />
                        <Button
                          size="m"
                          view="secondary"
                          text={CANCEL}
                          stretch
                          onClick={onCancelButtonClick}
                        />
                      </DeviceQuery>

                      <DeviceQuery off={[DeviceQueryEnum.mobile]}>
                        <Button
                          size="s"
                          view="secondary"
                          text={REPEAT}
                          onClick={onRepeatButtonClick}
                        />
                        <Button
                          size="s"
                          view="secondary"
                          text={CANCEL}
                          onClick={onCancelButtonClick}
                        />
                      </DeviceQuery>
                    </ButtonsWrapper>
                  </TextWrapper>

                  <ImageWrapper type="fail">
                    <Image
                      src={erorrOstrich}
                      srcSet={`${erorrOstrich} ${screenResolution.s}w, ${erorrOstrichM} ${screenResolution.m}w, ${erorrOstrichL} ${screenResolution.l}w, ${erorrOstrichXL} ${screenResolution.xl}w`}
                      alt={ATTENTION}
                    />
                  </ImageWrapper>
                </Inner>
              )}
            </StyledContainer>
          </Wrapper>
        )}

        {loadingState === LoadingStateEnum.processing && (
          <SpinnerWrapper>
            <Spinner />
          </SpinnerWrapper>
        )}
      </>
    );
  }
);
