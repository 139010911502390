import * as React from 'react';
import { Footnote2, Price } from '@salutejs/plasma-ui';
import { ProductType } from 'src/types';
import {
  ProductImage,
  ProductImageWrapper,
  Inner,
  TitleWrapper,
  Title,
  Wrapper,
} from './StyledComponents';
import { changeQuotes } from 'src/utils/changeQuotes';
import { removeRoundBracketsContent } from 'src/utils/removeRoundBracketsContent';
import placeholder from '../../assets/empty.svg';

export const ApplicationProduct = React.forwardRef<
  HTMLAnchorElement,
  {
    product: ProductType;
    count: number;
    onFocus?: (event: React.FocusEvent<HTMLAnchorElement>) => void;
  }
>((props, ref) => {
  const title = React.useMemo(() => {
    const trimmedTitle = removeRoundBracketsContent(props.product.title_short);
    return changeQuotes(
      trimmedTitle.length !== 0 ? trimmedTitle : props.product.title_short
    );
  }, [props.product.title_short]);

  const lowestPrice = React.useMemo(() => {
    let lowestPrice = Infinity;
    props.product.prices.forEach((price) => {
      if (lowestPrice > price.price) {
        lowestPrice = price.price;
      }
    });
    return lowestPrice;
  }, [props.product]);

  return (
    <Wrapper
      to={`/catalog/products/${props.product.id}`}
      ref={ref}
      onFocus={props.onFocus}
    >
      <ProductImageWrapper>
        <ProductImage
          src={props.product.images2[0].midres_url || placeholder}
          alt={title}
        />
      </ProductImageWrapper>

      <Inner>
        <TitleWrapper>
          <Title>{title}</Title>
        </TitleWrapper>

        <Footnote2>
          {props.product.prices.length > 1 && 'от '}
          <Price>{lowestPrice}</Price>
        </Footnote2>
      </Inner>
    </Wrapper>
  );
});

ApplicationProduct.displayName = 'ApplicationProduct';
