import * as React from 'react';
import { AppContext } from 'src/lib/contexts/AppContext';
import { Col, Container, Spinner } from '@salutejs/plasma-ui';
import { ApplicationHeader } from 'src/components/ApplicationHeader';
import { RootWrapper } from 'src/components/RootWrapper';
import { observer } from 'mobx-react';
import { Position } from './Position';
import { formatPrice } from 'src/utils/formatPrice';
import {
  CheckboxWrapper,
  FlexInner,
  Inner,
  InfoText,
  Title,
  PositionsWrapper,
  SpinnerWrapper,
  StyledRow,
  Text,
  Wrapper,
} from './StyledComponents';
import { RouteComponentProps } from 'react-router-dom';
import { OrderStatus } from 'src/components/OrderStatus';
import { LoadingStateEnum } from 'src/types/enums/LoadingStateEnum';
import { ASK_ADDRESS, PHOTO_SERVICE_ID, SUPPORT_PHONE } from 'src/constants';
import { ApplicationCheckbox } from 'src/components/ApplicationCheckbox';
import { FullScreenLoading } from 'src/components/FullScreenLoading';

const TITLE = 'Заказ № ';
const STATUS = 'Статус заказа';
const DELIVERY_COST = 'Стоимость доставки';
const SUMMARY = 'Итого';
const ADDRESS = 'Адрес доставки';
const DATE_TIME = 'Дата и время доставки';
const COMMENT = 'Комментарий к доставке';
const NAME = 'Имя получателя';
const PHONE = 'Телефон получателя';
const SENDER_NAME = 'Имя отправителя';
const SENDER_PHONE = 'Телефон отправителя';
const SENDER_EMAIL = 'Email отправителя';
const FREE = 'Бесплатно';
const SURPRISE = 'Это сюрприз — звонить получателю только при получении';
const ANONYMOUS = 'Я желаю остаться анонимным перед получателем';
const PHOTO =
  'Сфотографировать получателя в момент вручения — только при согласии получателя';
const GIVE_OTHER = 'Оставить заказ родственникам/друзьям/сослуживцам';
const SUPPORT = 'Телефон службы поддержки (Москва и МО круглосуточно)';

export const Order: React.FunctionComponent<
  RouteComponentProps<{ orderId: string }>
> = observer((props) => {
  const context = React.useContext(AppContext);
  const id = props.match.params.orderId;
  const order = context.OrdersService.ordersStore.order;
  const isLoading =
    context.OrdersService.ordersStore.loadingState ===
    LoadingStateEnum.processing;

  React.useEffect(() => {
    context.OrdersService.getOrder(id);
  }, []);

  const isMakephoto = order?.services.find(
    (service) => Number(service.id) === PHOTO_SERVICE_ID
  );

  const isGiveOther = order?.give_other === 'yes';
  const isSurprise = order?.call_back === 'yes';
  const isAnonymous = order?.anonym === 'yes';

  const orderDeliveryPrice =
    order?.sum - order?.items.reduce((acc, curr) => acc + curr.price, 0);
  const deliveryPrice =
    orderDeliveryPrice === 0 ? FREE : formatPrice(orderDeliveryPrice);

  const onFocus = React.useCallback((event: React.FocusEvent<HTMLElement>) => {
    event.target.scrollIntoView({
      behavior: 'smooth',
      inline: 'center',
      block: 'center',
    });
  }, []);

  return (
    <>
      <ApplicationHeader
        title={`${TITLE}${!!order ? order.title : ''}`}
        isBack={true}
      />

      {order && (
        <RootWrapper>
          <Container>
            <StyledRow>
              <Col sizeS={12} sizeM={4} sizeXL={8}>
                <Wrapper>
                  <FlexInner isLong onFocus={onFocus} tabIndex={0}>
                    <Title isLong>{STATUS}</Title>
                    <OrderStatus id={order.status_code} />
                  </FlexInner>

                  <FlexInner onFocus={onFocus} tabIndex={0}>
                    <Title>{SUPPORT}</Title>
                    <InfoText isPhone>{SUPPORT_PHONE}</InfoText>
                  </FlexInner>

                  <PositionsWrapper>
                    {order.items.map((item) => (
                      <Position key={item.id} position={item} />
                    ))}
                  </PositionsWrapper>

                  <FlexInner onFocus={onFocus} tabIndex={0}>
                    <Title>{DELIVERY_COST}</Title>
                    <InfoText>{deliveryPrice}</InfoText>
                  </FlexInner>

                  <FlexInner onFocus={onFocus} tabIndex={0}>
                    <Title>{SUMMARY}</Title>
                    <InfoText>{formatPrice(order.sum)}</InfoText>
                  </FlexInner>

                  <Inner>
                    <Title>{ADDRESS}</Title>
                    <Text>{order.address ? order.address : ASK_ADDRESS}</Text>
                  </Inner>

                  <Inner onFocus={onFocus} tabIndex={0}>
                    <Title>{DATE_TIME}</Title>
                    <Text>{order.delivery_datetime}</Text>
                  </Inner>

                  {order.note_delivery && (
                    <Inner onFocus={onFocus} tabIndex={0}>
                      <Title>{COMMENT}</Title>
                      <Text>{order.note_delivery}</Text>
                    </Inner>
                  )}

                  <Inner onFocus={onFocus} tabIndex={0}>
                    <Title>{NAME}</Title>
                    <Text>{order.receiver_name}</Text>
                    {isMakephoto && (
                      <CheckboxWrapper>
                        <ApplicationCheckbox
                          width={'100%'}
                          label={PHOTO}
                          isChecked
                        />
                      </CheckboxWrapper>
                    )}
                    {isGiveOther && (
                      <CheckboxWrapper>
                        <ApplicationCheckbox
                          width={'100%'}
                          label={GIVE_OTHER}
                          isChecked
                        />
                      </CheckboxWrapper>
                    )}
                  </Inner>

                  <Inner onFocus={onFocus} tabIndex={0}>
                    <Title>{PHONE}</Title>
                    <Text>{order.receiver_phone}</Text>
                    {isSurprise && (
                      <CheckboxWrapper>
                        <ApplicationCheckbox
                          width={'100%'}
                          label={SURPRISE}
                          isChecked
                        />
                      </CheckboxWrapper>
                    )}
                  </Inner>

                  <Inner onFocus={onFocus} tabIndex={0}>
                    <Title>{SENDER_NAME}</Title>
                    <Text>{order.sender_name}</Text>
                    {isAnonymous && (
                      <CheckboxWrapper>
                        <ApplicationCheckbox
                          width={'100%'}
                          label={ANONYMOUS}
                          isChecked
                        />
                      </CheckboxWrapper>
                    )}
                  </Inner>

                  <Inner onFocus={onFocus} tabIndex={0}>
                    <Title>{SENDER_PHONE}</Title>
                    <Text>{order.sender_phone}</Text>
                  </Inner>

                  <Inner isLast onFocus={onFocus} tabIndex={0}>
                    <Title>{SENDER_EMAIL}</Title>
                    <Text>{order.sender_email}</Text>
                  </Inner>
                </Wrapper>
              </Col>
            </StyledRow>
          </Container>
        </RootWrapper>
      )}
      {isLoading && <FullScreenLoading />}
    </>
  );
});
