import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { ApplicationHeader } from 'src/components/ApplicationHeader';

import { AppContext } from 'src/lib/contexts/AppContext';
import { DeviceQuery } from 'src/components/DeviceQuery';
import { MobileMain } from './MobileMain';
import { DeviceQueryEnum } from 'src/enums/DeviceQueryEnum';
import { SberPortalMain } from './SberPortalMain';

const TITLE = 'Доставка цветов';

export const Main: React.FunctionComponent<RouteComponentProps> = () => {
  const context = React.useContext(AppContext);

  React.useEffect(() => {
    context.AssistantService.setMainCategories();
  }, [context.AssistantService]);

  return (
    <>
      <ApplicationHeader
        title={TITLE}
        isBack={false}
        isCart
        isOrders
        isLogo
        isSearch
      />
      <DeviceQuery on={[DeviceQueryEnum.mobile]}>
        <MobileMain />
      </DeviceQuery>
      <DeviceQuery off={[DeviceQueryEnum.mobile]}>
        <SberPortalMain />
      </DeviceQuery>
    </>
  );
};
