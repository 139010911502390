import styled from 'styled-components';
import {
  Button,
  Container,
  Footnote1,
  Headline2,
  Headline4,
  mediaQuery,
} from '@salutejs/plasma-ui';

export const Title = styled(Headline2)`
  margin-bottom: 1.5rem;
  font-weight: bold;
`;

export const ParagraphTitle = styled(Headline4)`
  margin-bottom: 0.75rem;
  font-weight: bold;
`;

export const ParagraphText = styled(Footnote1)`
  margin-bottom: 0.5rem;
`;

export const ParagraphWrapper = styled.div`
  margin-bottom: 0.75rem;
`;

export const BackButton = styled(Button)`
  position: fixed;
  bottom: var(--global-bottom-insets);
  right: var(--plasma-grid-margin);
  left: var(--plasma-grid-margin);

  ${mediaQuery('S')(`
    bottom: calc(var(--global-bottom-insets) + 0.5rem);
  `)}
`;

export const BackButtonSpacer = styled.div`
  min-height: 4rem;
`;

export const PageContainer = styled(Container)`
  position: relative;
`;
