import * as React from 'react';
import arrowLeft from './assets/arrowLeft.svg';
import arrowRight from './assets/arrowRight.svg';
import { ProductType } from 'src/types';
import {
  Amount,
  ButtonsWrapper,
  CurrentImageIndex,
  CurrentPage,
  Gallery,
  GalleryButton,
  Image,
  ImageWrapper,
} from './StyledComponents';
import { screenResolution } from 'src/constants';
import placeholder from '../../assets/empty.svg';

const ARROW_LEFT = 'Стрелка влево';
const ARROW_RIGHT = 'Стрелка вправо';

export const ImageGallery: React.FunctionComponent<{
  product: ProductType;
}> = (props) => {
  const [imageIndex, setImageIndex] = React.useState(0);
  const imageSrc = props.product.images2[imageIndex].lowres_url;
  const imageSrcM = props.product.images2[imageIndex].midres_url;
  const imageSrcL = props.product.images2[imageIndex].highres_url;
  const length = props.product.images2.length;

  const onLeftyButtonClick = () => {
    setImageIndex(imageIndex - 1);
  };

  const onRightButtonClick = () => {
    setImageIndex(imageIndex + 1);
  };

  return (
    <Gallery>
      <ImageWrapper>
        <Image
          src={imageSrc || placeholder}
          srcSet={`${imageSrc} ${screenResolution.s}w, ${imageSrcM} ${screenResolution.m}w, ${imageSrcL} ${screenResolution.l}w`}
          alt={props.product.title_short}
        />
      </ImageWrapper>

      {length > 1 && (
        <ButtonsWrapper>
          <GalleryButton
            view="secondary"
            disabled={imageIndex === 0}
            outlined
            onClick={onLeftyButtonClick}
            className="scrollToBottom"
          >
            <img src={arrowLeft} alt={ARROW_LEFT} />
          </GalleryButton>
          <CurrentPage>
            <CurrentImageIndex>{imageIndex + 1}</CurrentImageIndex>
            <Amount>{`\u00A0из ${length}`}</Amount>
          </CurrentPage>
          <GalleryButton
            view="secondary"
            disabled={imageIndex === length - 1}
            outlined
            onClick={onRightButtonClick}
            className="scrollToBottom"
          >
            <img src={arrowRight} alt={ARROW_RIGHT} />
          </GalleryButton>
        </ButtonsWrapper>
      )}
    </Gallery>
  );
};
