import * as React from 'react';
import {
  ButtonsWrapper,
  CardImage,
  ExtraText,
  RefuseButton,
  SubmitButton,
  Text,
  TextWrapper,
  Title,
  Wrapper,
} from './StyledComponents';
import { DeviceQuery } from '../DeviceQuery';
import { DeviceQueryEnum } from 'src/enums/DeviceQueryEnum';
import { Spinner, withAutoFocus } from '@salutejs/plasma-ui';
import { observer } from 'mobx-react';

const YES = 'Да, всё верно';
const YES_SHORT = 'Всё верно';
const NO = 'Нет, указать заново';
const NO_SHORT = 'Указать заново';

const AutoFocusButton = withAutoFocus(SubmitButton);

interface Props {
  alt?: string;
  icon?: string;
  title?: string;
  text: string;
  extraText?: string;
  submitButtonText?: string;
  isProcessing?: boolean;
  onSubmit: () => void;
  onRefuse?: () => void;
}

export const ApplicationCardWithButtons: React.FunctionComponent<Props> =
  observer((props) => {
    return (
      <Wrapper>
        {props.icon && props.alt && (
          <CardImage src={props.icon} alt={props.alt} />
        )}

        {props.title && <Title>{props.title}</Title>}

        <TextWrapper>
          <Text>{props.text}</Text>

          {props.extraText && <ExtraText>{props.extraText}</ExtraText>}
        </TextWrapper>

        <ButtonsWrapper>
          <DeviceQuery on={[DeviceQueryEnum.mobile]}>
            <SubmitButton
              view="primary"
              size="m"
              text={
                props.isProcessing ? (
                  <Spinner size={32} />
                ) : props.submitButtonText ? (
                  props.submitButtonText
                ) : (
                  YES_SHORT
                )
              }
              stretch
              disabled={props.isProcessing}
              onClick={props.onSubmit}
            />
          </DeviceQuery>
          <DeviceQuery off={[DeviceQueryEnum.mobile]}>
            <AutoFocusButton
              view="primary"
              size="s"
              autoFocus
              text={
                props.isProcessing ? (
                  <Spinner size={32} />
                ) : props.submitButtonText ? (
                  props.submitButtonText
                ) : (
                  YES
                )
              }
              disabled={props.isProcessing}
              onClick={props.onSubmit}
            />
          </DeviceQuery>

          {props.onRefuse && (
            <>
              <DeviceQuery on={[DeviceQueryEnum.mobile]}>
                <RefuseButton
                  view="secondary"
                  size="m"
                  text={NO_SHORT}
                  stretch
                  onClick={props.onRefuse}
                />
              </DeviceQuery>
              <DeviceQuery off={[DeviceQueryEnum.mobile]}>
                <RefuseButton
                  view="secondary"
                  size="s"
                  text={NO}
                  onClick={props.onRefuse}
                />
              </DeviceQuery>
            </>
          )}
        </ButtonsWrapper>
      </Wrapper>
    );
  });
