import * as React from 'react';
import { DatesCarouselComponent, StyledCarouselCol } from './StyledComponents';
import { DateItem } from './DateItem';
import { DeliveryIntervalType } from 'src/types/DeliveryIntervalType';

export const DatesCarousel: React.FunctionComponent<{
  intervals: DeliveryIntervalType[];
  date: string;
  onDateClick: (date: Date) => void;
}> = (props) => {
  const onDateClick = (date: Date) => {
    props.onDateClick(date);
  };

  return (
    <DatesCarouselComponent>
      {props.intervals.map((interval, index) => (
        <StyledCarouselCol key={`${index}`}>
          <DateItem
            key={index}
            date={new Date(interval.date)}
            isActive={props.date === interval.date}
            isToday={index === 0}
            onClick={onDateClick}
          />
        </StyledCarouselCol>
      ))}
    </DatesCarouselComponent>
  );
};
