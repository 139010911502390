import { colors } from '@salutejs/plasma-tokens';
import { Body2, Footnote1, Headline2, mediaQuery } from '@salutejs/plasma-ui';
import styled from 'styled-components';

const isShort = window.innerHeight < 750;

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: var(--global-bottom-insets);
  padding-right: 14rem;
  padding-bottom: var(--global-bottom-insets);
  padding-left: 3.5rem;
  background: radial-gradient(
    farthest-corner at 100% 100%,
    rgba(224, 146, 0, 1) 0%,
    rgba(18, 63, 4, 1) 52%,
    rgba(16, 21, 58, 1) 100%
  );
  box-sizing: border-box;
  overflow: auto;

  & > * {
    position: relative;
    z-index: 1;
  }

  ${mediaQuery('S')(`
    display: ${isShort ? 'block' : 'flex'};
    text-align: center;
    padding-top: 0rem;
    padding-right: 1rem;
    padding-left: 1rem;
  `)};
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 1.5rem;
`;

export const ButtonsWrapperMobile = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled(Headline2)`
  margin-bottom: 0.75rem;
`;

export const TitleMobile = styled(Headline2)`
  margin-bottom: 0.75rem;
  text-align: center;
`;

export const Text = styled(Footnote1)`
  color: ${colors.secondary};
`;

export const TextMobile = styled(Body2)`
  margin-bottom: 3rem;
  padding: 0 1rem;
  color: ${colors.secondary};
  line-height: 1.375rem;
  letter-spacing: -0.019em;
  text-align: center;
`;

export const Separator = styled.div`
  width: 0.75rem;
  min-width: 0.75rem;

  ${mediaQuery('S')(`
    height: 0.75rem;
  `)};
`;

export const Image = styled.img`
  position: absolute;
  right: 0;
  bottom: auto;
  width: calc(9.4rem * 2);
  height: calc(9.4rem * 2);
  z-index: 0;
  object-fit: contain;
  object-position: center right;
  margin-top: 0rem;

  ${mediaQuery('S')(`
    position: relative;
    margin: 0 auto;
    width: 100%;
    height: auto;
    margin-bottom: 2.25rem;
    aspect-ratio: 1.29;
    object-fit: contain;
    object-position: center;
    margin-top: 1rem;
  `)};
`;
