import * as React from 'react';

export const useLoadingWrapper = (): {
  isLoading: boolean;
  waitForPromise(promise: any): Promise<void>;
} => {
  const [isLoading, setIsLoading] = React.useState(false);

  const waitForPromise = React.useCallback(
    async (promise: Promise<void>) => {
      setIsLoading(true);
      try {
        await promise;
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    },
    [setIsLoading]
  );

  return {
    isLoading,
    waitForPromise,
  };
};
