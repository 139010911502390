import * as React from 'react';
import { Button, mediaQuery } from '@salutejs/plasma-ui';
import { IconKeyboard } from '@salutejs/plasma-icons';
import styled from 'styled-components';

export const Wrapper = styled(Button)`
  ${mediaQuery('XL')(`
    position: relative;
    left: 0;
    height: 2.5rem;
    margin-right: 0.5rem;
  `)}
`;

export const KeyboardInputButton: React.FunctionComponent<{
  onClick: () => void;
}> = (props) => (
  <Wrapper view="clear" shiftRight outlined onClick={props.onClick}>
    <IconKeyboard size="s" />
  </Wrapper>
);
