import * as React from 'react';
const pluralize = require('pluralize-ru');

export const getDateMonthString = (day: number, month: string): string => {
  switch (month) {
    case 'март':
    case 'август':
      return pluralize(
        day,
        `нет ${month.slice(0, -1)}а`,
        `%d ${month.slice(0, -1)}`,
        `%d ${month.slice(0, -1)}а`,
        `%d ${month.slice(0, -2)}ов`
      );

    default:
      return pluralize(
        day,
        `нет ${month.slice(0, -1)}я`,
        `%d ${month.slice(0, -1)}`,
        `%d ${month.slice(0, -1)}я`,
        `%d ${month.slice(0, -1)}я`
      );
  }
};
