import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { AppContext } from 'src/lib/contexts/AppContext';
import { Button, Col, Spinner, TabItem } from '@salutejs/plasma-ui';
import { ApplicationHeader } from 'src/components/ApplicationHeader';
import { RootWrapper } from 'src/components/RootWrapper';
import { observer } from 'mobx-react';
import { Interval } from './Interval';
import { LoadingStateEnum } from 'src/types/enums/LoadingStateEnum';
import { isEnterExactTimeCommand } from 'src/types/AssistantCommands/SmartAppDataTypeCheckers/isEnterExactTimeCommand';
import { isEnterStandartIntervalCommand } from 'src/types/AssistantCommands/SmartAppDataTypeCheckers/isEnterStandartIntervalCommand';
import { isSelectDateCommand } from 'src/types/AssistantCommands/SmartAppDataTypeCheckers/isSelectDateCommand';
import { SelectDateCommand } from 'src/types/AssistantCommands/SelectDateCommand';
import { getDateMonthString } from 'src/utils/getDateMonthString';
import { isSelectIntervalCommand } from 'src/types/AssistantCommands/SmartAppDataTypeCheckers/isSelectIntervalCommand';
import { SelectIntervalCommand } from 'src/types/AssistantCommands/SelectIntervalCommand';
import {
  IntervalsWrapper,
  SpinnerWrapper,
  TabsWrapper,
  Wrapper,
  StyledTabItem,
  ErrorText,
} from './StyledComponents';
import { Container, Tabs } from '@salutejs/plasma-ui';
import { DeviceTypeContext } from 'src/contexts/DeviceTypeContext';
import { DeviceQueryEnum } from 'src/enums/DeviceQueryEnum';
import { formatPrice } from 'src/utils/formatPrice';
import { TimePickerControl } from './TimePicker';
import { DeliveryIntervalType } from 'src/types/DeliveryIntervalType';
import { DatesCarousel } from './DatesCarousel';

const TITLE = 'Дата и время доставки';
const STANDART = 'Стандартный интервал';
const EXACT = 'Точное время';
const SUBTITLE = 'Шаг оформления 3 из 6';

export const DeliveryTime: React.FunctionComponent<RouteComponentProps> =
  observer((props) => {
    const context = React.useContext(AppContext);
    const deviceType = React.useContext(DeviceTypeContext);
    const isMobile = deviceType === DeviceQueryEnum.mobile;
    const loadingState = context.CartService.cartStore.loadingState;
    const intervals = context.CartService.cartStore.deliveryIntervals;

    const [date, setDate] = React.useState(
      intervals.length > 0 ? intervals[0].date : ''
    );

    const dateObj = React.useMemo(() => new Date(date), [date]);

    const [index, setIndex] = React.useState(0);

    const selectedInterval =
      context.CartService.cartStore.deliveryIntervalByDateMap.get(date);

    const items = [
      { label: STANDART },
      {
        label: `${EXACT}${
          selectedInterval
            ? ` +${formatPrice(selectedInterval.exact_delivery_time.price)}`
            : ''
        }`,
      },
    ];

    const onDateClick = React.useCallback(
      (date: Date) => {
        const day =
          date.getDate().toString().length > 1
            ? date.getDate()
            : `0${date.getDate()}`;

        const month =
          (date.getMonth() + 1).toString().length > 1
            ? date.getMonth() + 1
            : `0${date.getMonth() + 1}`;

        const dateString = `${date.getFullYear()}-${month}-${day}`;

        console.info({ dateString, index });
        if (index === 1) {
          context.CartService.loadExactDeliveryIntervals(dateString).then(
            () => {
              setDate(dateString);
            }
          );
        } else {
          setDate(dateString);
        }
      },
      [setDate, date, index]
    );

    const onIntervalClick = React.useCallback(
      (intervalId: number) => {
        context.CartService.changeDelivery(intervalId, date);

        context.AssistantService.onGoToUserName();
      },
      [context.AssistantService, context.CartService, date]
    );

    const onTimePickerSubmit = React.useCallback(
      (exactTime: string) => {
        context.CartService.changeDelivery(null, date + ' ' + exactTime);

        context.AssistantService.onGoToUserName();
      },
      [context.CartService, context.AssistantService, date]
    );

    const onDateSelectByVoice = (data: SelectDateCommand) => {
      onDateClick(new Date(data.smart_app_data.date));
    };

    const onIntervalSelectByVoice = (data: SelectIntervalCommand) => {
      const intervalId = selectedInterval?.intervals.find(
        (interval) =>
          interval.description.replace('Доставка с', 'с') ===
          data.smart_app_data.interval
      )?.id;

      onIntervalClick(Number(intervalId));
    };

    const openTime = React.useCallback(
      (selectedInterval: DeliveryIntervalType) => {
        if (index === 0 && selectedInterval) {
          const dates = intervals.map((interval) => {
            const date = new Date(interval.date);

            return getDateMonthString(
              Number(date.getDate()),
              date.toLocaleDateString('ru-RU', {
                month: 'long',
              })
            );
          });

          const selectedIntervals = selectedInterval?.intervals.map(
            (interval) => interval.description.replace('Доставка с', 'с')
          );

          context.AssistantService.openTime(dates, selectedIntervals);
        } else if (index === 1) {
          context.AssistantService.enterExactTime();
        }
      },
      [index]
    );

    React.useEffect(() => {
      // on mount
      const date = context.CartService.cartStore.deliveryIntervals[0]?.date;
      setDate(date);
      openTime(
        context.CartService.cartStore.deliveryIntervalByDateMap.get(date)
      );
    }, []);

    const onTabChange = (index: number) => {
      if (index === 1) {
        context.CartService.loadExactDeliveryIntervals(date);
      }

      setIndex(index);
    };

    React.useEffect(() => {
      const unsubscribeFromExactTimeClick =
        context.SmartAppAssistantHelper.conditionalOnData(
          isEnterExactTimeCommand,
          () => onTabChange(1)
        );

      const unsubscribeFromDateSelect =
        context.SmartAppAssistantHelper.conditionalOnData(
          isSelectDateCommand,
          onDateSelectByVoice
        );

      const unsubscribeFromStandartIntervalClick =
        context.SmartAppAssistantHelper.conditionalOnData(
          isEnterStandartIntervalCommand,
          () => {
            onTabChange(0);
          }
        );

      const unsubscribeFromTimeSelect =
        context.SmartAppAssistantHelper.conditionalOnData(
          isSelectIntervalCommand,
          onIntervalSelectByVoice
        );

      return () => {
        unsubscribeFromExactTimeClick();
        unsubscribeFromStandartIntervalClick();
        unsubscribeFromDateSelect();
        unsubscribeFromTimeSelect();
      };
    }, [
      context.SmartAppAssistantHelper,
      onTimePickerSubmit,
      onIntervalSelectByVoice,
      onDateSelectByVoice,
    ]);

    return (
      <>
        <ApplicationHeader title={TITLE} subtitle={SUBTITLE} isBack={true} />

        <RootWrapper>
          <Container>
            <Wrapper>
              {!!context.CartService.cartStore.deliberyIntervalsError ? (
                <>
                  <ErrorText style={{ textAlign: 'center' }}>
                    {context.CartService.cartStore.deliberyIntervalsError}
                  </ErrorText>
                  <Button
                    onClick={() => {
                      window.history.go(-3);
                    }}
                    stretch
                  >
                    Назад
                  </Button>
                </>
              ) : (
                <>
                  <TabsWrapper>
                    <Tabs size={isMobile ? 's' : 'l'} stretch>
                      {items.map((item, i) => (
                        <StyledTabItem
                          key={`item:${i}`}
                          isActive={i === index}
                          tabIndex={0}
                          onClick={() => onTabChange(i)}
                        >
                          {item.label}
                        </StyledTabItem>
                      ))}
                    </Tabs>
                  </TabsWrapper>

                  {loadingState === LoadingStateEnum.success && index === 0 && (
                    <>
                      <DatesCarousel
                        date={date}
                        intervals={intervals}
                        onDateClick={onDateClick}
                      />

                      <Col sizeS={4} sizeM={4} sizeL={6} sizeXL={10}>
                        <IntervalsWrapper>
                          {selectedInterval?.intervals.map((interval) => (
                            <Interval
                              id={Number(interval.id)}
                              key={interval.id}
                              period={interval.description
                                .replace('Доставка с ', '')
                                .replace(' до ', '-')}
                              onClick={onIntervalClick}
                            />
                          ))}
                        </IntervalsWrapper>{' '}
                      </Col>
                    </>
                  )}

                  {loadingState === LoadingStateEnum.processing && (
                    <SpinnerWrapper>
                      <Spinner />
                    </SpinnerWrapper>
                  )}

                  {loadingState === LoadingStateEnum.success && index === 1 && (
                    <>
                      <DatesCarousel
                        date={date}
                        intervals={intervals}
                        onDateClick={onDateClick}
                      />

                      <TimePickerControl
                        value={dateObj}
                        onSubmit={onTimePickerSubmit}
                      />
                    </>
                  )}
                </>
              )}
            </Wrapper>
          </Container>
        </RootWrapper>
      </>
    );
  });
