import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { AppContext } from 'src/lib/contexts/AppContext';
import { ApplicationHeader } from 'src/components/ApplicationHeader';
import { isSubmitUserNameCommand } from 'src/types/AssistantCommands/SmartAppDataTypeCheckers/isSubmitUserNameCommand';
import { isChangeUserNameCommand } from 'src/types/AssistantCommands/SmartAppDataTypeCheckers/isChangeUserNameCommand';
import { SubmitUserNameCommand } from 'src/types/AssistantCommands/SubmitUserNameCommand';
import { RootWrapper } from 'src/components/RootWrapper';
import voiceIcon from 'src/assets/voice.png';
import { ApplicationAddInfoText } from 'src/components/ApplicationAddInfoText';
import { ApplicationCardWithButtons } from 'src/components/ApplicationCardWithButtons';
import { AddressViewEnum } from 'src/types/enums/AddressViewEnum';
import { clientHistory } from 'src/history';
import { KeyboardInputButton } from 'src/components/KeyboardInputButton';
import { ApplicationCheckbox } from 'src/components/ApplicationCheckbox';
import { isGoToInputCommand } from 'src/types/AssistantCommands/SmartAppDataTypeCheckers/isGoToInputCommand';
import { isGetOneselfCommand } from 'src/types/AssistantCommands/SmartAppDataTypeCheckers/isGetOneselfCommand';
import { capitalizeFirstLetter } from 'src/utils/capitalizeFirstLetter';
import {
  AutoFocusTextField,
  FirstCheckboxWrapper,
  Inner,
  Form,
  ReceiveYourselfButton,
  SecondCheckboxWrapper,
  Separator,
} from './StyledComponents';
import { Container, Spinner } from '@salutejs/plasma-ui';
import { PHOTO_SERVICE_ID } from 'src/constants';
import { isSelectFirstCheckboxCommand } from 'src/types/AssistantCommands/SmartAppDataTypeCheckers/isSelectFirstCheckboxCommand';
import { isSelectSecondCheckboxCommand } from 'src/types/AssistantCommands/SmartAppDataTypeCheckers/isSelectSecondCheckboxCommand';
import { DeviceQuery } from 'src/components/DeviceQuery';
import { DeviceQueryEnum } from 'src/enums/DeviceQueryEnum';
import { isSubmitInputCommand } from 'src/types/AssistantCommands/SmartAppDataTypeCheckers/isSubmitInputCommand';
import { GoOnButton } from 'src/components/GoOnButton';
import { isGoToUserPhoneCommand } from 'src/types/AssistantCommands/SmartAppDataTypeCheckers/isGoToUserPhoneCommand';
import { observer } from 'mobx-react';
import { useLoadingWrapper } from 'src/hooks/useLoadingWrapper';

const TITLE = 'Имя получателя';
const TEXT = 'Получатель';
const SUBTITLE = 'Шаг оформления 4 из 6';
const PHOTO =
  'Сфотографировать получателя в момент вручения — только при согласии получателя';
const FRIENDS = 'Оставить заказ родственникам/друзьям/сослуживцам';
const RECEIVE_YOURSELF = 'Я сам/а получу цветы';

export const UserName: React.FunctionComponent<RouteComponentProps> = observer(
  (props) => {
    const [userName, setUserName] = React.useState('');
    const [input, setInput] = React.useState('');
    const [isMakePhotoChecked, setIsMakePhotoChecked] = React.useState(false);
    const [isGiveToFriendsChecked, setIsGiveToFriendsChecked] =
      React.useState(false);
    const view = props.location.pathname.includes('input')
      ? AddressViewEnum.input
      : AddressViewEnum.voice;
    const context = React.useContext(AppContext);

    const useLoading = useLoadingWrapper();
    const waitForPromise = useLoading.waitForPromise;
    const isProcessing = useLoading.isLoading;
    const receiveText = isProcessing ? <Spinner size={32} /> : RECEIVE_YOURSELF;

    const changeCartOptions = async () => {
      return isMakePhotoChecked
        ? await Promise.all([
            context.CartService.changeReceiverName(userName),

            context.CartService.changeCartOption({
              ['isGiveOther']: isGiveToFriendsChecked,
            }),
            context.CartService.addAdditionalService(PHOTO_SERVICE_ID),
          ])
        : await Promise.all([
            context.CartService.changeReceiverName(userName),

            context.CartService.changeCartOption({
              ['isGiveOther']: isGiveToFriendsChecked,
            }),
          ]);
    };

    const onGoToUserPhone = React.useCallback(async () => {
      waitForPromise(changeCartOptions());

      context.AssistantService.onGoToUserPhone();
    }, [
      context.AssistantService,
      context.CartService,
      userName,
      isMakePhotoChecked,
      isGiveToFriendsChecked,
    ]);

    const onInputChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
      setInput(capitalizeFirstLetter(evt.target.value));
    };

    const onInputSubmit = React.useCallback(
      (evt?: React.SyntheticEvent) => {
        if (evt.preventDefault) {
          evt.preventDefault();
        }

        if (input.length > 0) {
          waitForPromise(context.CartService.changeReceiverName(input));

          setUserName(input);

          context.AssistantService.enterUserName(capitalizeFirstLetter(input));
        }
      },
      [context.CartService, context.AssistantService, input]
    );

    const onUserNameSubmit = React.useCallback(
      (data: SubmitUserNameCommand) => {
        const name = capitalizeFirstLetter(data.smart_app_data.userName);

        setUserName(name);

        context.CartService.changeReceiverName(name);
      },
      [context.CartService]
    );

    const onUserNameChange = React.useCallback(() => {
      setUserName('');

      setIsMakePhotoChecked(false);

      setIsGiveToFriendsChecked(false);

      context.AssistantService.onGoToUserName();

      context.AssistantService.openUserName();
    }, [context.AssistantService]);

    const onViewChange = () => {
      clientHistory.push('/name/input');

      context.AssistantService.enterTextInput('name');
    };

    const onReceiveYourselfClick = React.useCallback(() => {
      waitForPromise(
        context.CartService.changeCartOption({
          ['isSelfReceive']: true,
        })
      );

      context.AssistantService.onGoToUserInfo();
    }, [context.CartService]);

    const onIsMakePhotoCheckedChange = React.useCallback(
      (evt: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = isMakePhotoChecked ? false : true;

        setIsMakePhotoChecked(newValue);

        context.AssistantService.enterUserName(userName);
      },
      [context.AssistantService, isMakePhotoChecked, userName]
    );

    const onIsGiveToFriendsCheckedChange = React.useCallback(
      (evt: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = isGiveToFriendsChecked ? false : true;

        setIsGiveToFriendsChecked(newValue);

        context.AssistantService.enterUserName(userName);
      },
      [context.AssistantService, isGiveToFriendsChecked, userName]
    );

    React.useEffect(() => {
      context.CartService.changeReceiverName('');

      setUserName('');

      if (view === AddressViewEnum.voice) {
        context.AssistantService.openUserName();
      }
    }, [view]);

    React.useEffect(() => {
      // on mount
      context.CartService.resetReceiverInfo();
      context.CartService.changeCartOption({
        ['isSelfReceive']: false,
      });
    }, []);

    React.useEffect(() => {
      const unsubscribeFromUserNameSubmit =
        context.SmartAppAssistantHelper.conditionalOnData(
          isSubmitUserNameCommand,
          onUserNameSubmit
        );

      const unsubscribeFromUserNameChange =
        context.SmartAppAssistantHelper.conditionalOnData(
          isChangeUserNameCommand,
          onUserNameChange
        );

      const unsubscribeFromOnGoToInput =
        context.SmartAppAssistantHelper.conditionalOnData(
          isGoToInputCommand,
          onViewChange
        );

      const unsubscribeFromOnGetOneself =
        context.SmartAppAssistantHelper.conditionalOnData(
          isGetOneselfCommand,
          onReceiveYourselfClick
        );

      const unsubscribeFromOnIsMakePhotoCheckedChange =
        context.SmartAppAssistantHelper.conditionalOnData(
          isSelectFirstCheckboxCommand,
          onIsMakePhotoCheckedChange
        );

      const unsubscribeFromOnIsGiveToFriendsCheckedChange =
        context.SmartAppAssistantHelper.conditionalOnData(
          isSelectSecondCheckboxCommand,
          onIsGiveToFriendsCheckedChange
        );

      const unsubscribeFromOnInputSubmit =
        context.SmartAppAssistantHelper.conditionalOnData(
          isSubmitInputCommand,
          onInputSubmit
        );

      const unsubscribeFromOnGoToUserPhone =
        context.SmartAppAssistantHelper.conditionalOnData(
          isGoToUserPhoneCommand,
          onGoToUserPhone
        );

      return () => {
        unsubscribeFromUserNameChange();
        unsubscribeFromUserNameSubmit();
        unsubscribeFromOnGoToInput();
        unsubscribeFromOnGetOneself();
        unsubscribeFromOnIsMakePhotoCheckedChange();
        unsubscribeFromOnIsGiveToFriendsCheckedChange();
        unsubscribeFromOnInputSubmit();
        unsubscribeFromOnGoToUserPhone();
      };
    }, [
      context.SmartAppAssistantHelper,
      onIsMakePhotoCheckedChange,
      onIsGiveToFriendsCheckedChange,
      onReceiveYourselfClick,
      onViewChange,
      onUserNameChange,
      onUserNameSubmit,
      onInputSubmit,
      onGoToUserPhone,
    ]);

    return (
      <>
        <ApplicationHeader title={TITLE} subtitle={SUBTITLE} isBack={true}>
          {view === AddressViewEnum.voice && (
            <KeyboardInputButton onClick={onViewChange} />
          )}
        </ApplicationHeader>

        <RootWrapper>
          <Container>
            {view === AddressViewEnum.input && userName.length === 0 && (
              <>
                <DeviceQuery on={[DeviceQueryEnum.mobile]}>
                  <Inner>
                    <Form onSubmit={onInputSubmit}>
                      <AutoFocusTextField
                        placeholder={TITLE}
                        onChange={onInputChange}
                        autoFocus
                      />
                      <GoOnButton
                        isDisabled={input.length === 0}
                        isProcessing={isProcessing}
                        onClick={onInputSubmit}
                      />

                      <ReceiveYourselfButton
                        size="m"
                        view="secondary"
                        text={receiveText}
                        stretch
                        onClick={onReceiveYourselfClick}
                      />
                    </Form>
                  </Inner>
                </DeviceQuery>
                <DeviceQuery off={[DeviceQueryEnum.mobile]}>
                  <Form onSubmit={onInputSubmit}>
                    <AutoFocusTextField
                      placeholder={TITLE}
                      onChange={onInputChange}
                      autoFocus
                    />
                  </Form>
                </DeviceQuery>
              </>
            )}

            {(view === AddressViewEnum.voice || userName.length > 0) && (
              <Inner>
                {view === AddressViewEnum.voice && userName.length === 0 && (
                  <>
                    <ApplicationAddInfoText
                      text={TITLE.toLowerCase()}
                      icon={voiceIcon}
                      alt={TITLE}
                    />

                    <DeviceQuery on={[DeviceQueryEnum.mobile]}>
                      <Separator />

                      <ReceiveYourselfButton
                        size="m"
                        view="secondary"
                        text={receiveText}
                        stretch
                        onClick={onReceiveYourselfClick}
                      />
                    </DeviceQuery>

                    <DeviceQuery off={[DeviceQueryEnum.mobile]}>
                      <ReceiveYourselfButton
                        size="s"
                        view="secondary"
                        text={receiveText}
                        onClick={onReceiveYourselfClick}
                      />
                    </DeviceQuery>
                  </>
                )}

                {userName.length > 0 && (
                  <>
                    <ApplicationCardWithButtons
                      title={TEXT}
                      text={userName}
                      onSubmit={onGoToUserPhone}
                      onRefuse={onUserNameChange}
                    />
                    {context.CartService.isServiceAvaiable(
                      PHOTO_SERVICE_ID
                    ) && (
                      <FirstCheckboxWrapper>
                        <ApplicationCheckbox
                          width={20}
                          label={PHOTO}
                          isChecked={isMakePhotoChecked}
                          onChange={onIsMakePhotoCheckedChange}
                        />
                      </FirstCheckboxWrapper>
                    )}

                    <SecondCheckboxWrapper>
                      <ApplicationCheckbox
                        width={20}
                        label={FRIENDS}
                        isChecked={isGiveToFriendsChecked}
                        onChange={onIsGiveToFriendsCheckedChange}
                      />
                    </SecondCheckboxWrapper>
                  </>
                )}
              </Inner>
            )}
          </Container>
        </RootWrapper>
      </>
    );
  }
);
