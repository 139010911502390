import {
  Button,
  Container,
  Footnote2,
  Headline2,
  mediaQuery,
  TextField,
  TextFieldProps,
} from '@salutejs/plasma-ui';
import styled from 'styled-components';
import { colors } from '@salutejs/plasma-tokens';
import { withAutoFocus } from '@salutejs/plasma-core/hocs';

const AutoFocusTextField = withAutoFocus<TextFieldProps>(TextField);

export const Form = styled.form`
  width: 100%;
  margin-top: 1.5rem;

  ${mediaQuery('S')(`
    margin-top: auto;
    margin-bottom: auto;
  `)}
`;

export const Input = styled(AutoFocusTextField)`
  width: 100%;
`;

export const Inner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: var(--content-height);
`;

export const ParsedAddressesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1.5rem;
`;

export const ParsedAddressesTitle = styled(Headline2)`
  margin-bottom: 1.5rem;
`;

export const NoAddressButton = styled(Button)`
  width: 19.375rem;

  ${mediaQuery('S')(`
    width: 100%;
    font-size: 0.875rem;
  `)}
`;

export const AddressWrapper = styled(Button)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem 1.5rem;
  background-color: ${colors.backgroundSecondary};
  height: auto;
  width: 19.375rem;
  margin-bottom: 0.5rem;
  border-radius: 0.75rem;

  &:last-оf-type {
    margin-bottom: 0;
  }

  ${mediaQuery('S')(`
    width: 100%;
  `)}
`;

export const AddressText = styled(Footnote2)`
  text-align: start;
`;

export const SkipButton = styled(Button)`
  margin-top: 0.75rem;
  font-size: 0.875rem;

  ${mediaQuery('S')(`
    margin-top: 1.5rem;
  `)}

  ${mediaQuery('M')(`
    min-width: 14.625rem;
  `)}

  ${mediaQuery('XL')(`
    min-width: 14.625rem;
  `)}
`;

export const Separator = styled.div`
  height: 1rem;
`;
