import * as React from 'react';
import { createGlobalStyle } from 'styled-components';
import { darkSber, darkEva, darkJoy } from '@salutejs/plasma-tokens/themes'; // Или один из списка: darkEva, darkJoy, lightEva, lightJoy, lightSber
import { text } from '@salutejs/plasma-tokens';
import { SberInsetsType } from './types/SberInsetsType';
import { SberCharacterEnum } from './types/SberCharacterEnum';
import { mediaQuery } from '@salutejs/plasma-ui';

interface GlobalStyleProps {
  insets: SberInsetsType;
  isInitialized: boolean;
  character: SberCharacterEnum;
  device: string;
}

const DocumentStyle = createGlobalStyle<GlobalStyleProps>`
    html:root {
      min-height: 100vh;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: column;
      align-items: stretch;
    }

    body {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      padding: 0;
      margin: 0;
      flex-grow: 1;
      color: ${text};
      background: var(--plasma-colors-background-primary);
      
      --global-top-insets: ${(props) => props.insets.top}px;
      --global-bottom-insets: ${(props) => {
        return props.device === 'sberBox'
          ? props.insets.bottom * 2
          : props.insets.bottom;
      }}px;
      --content-height: calc(
        100vh - var(--global-bottom-insets) - 4.5rem
      );

      *:focus {
        outline: 0;
      }
    }

    .root {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      flex-grow: 1;
    }

    .nativePanel {
      .suggestPanel {
        overflow: scroll;
        left: 0;
        right: 0;
      }
    }

    .suggest {
      white-space: nowrap;

      &:first-child {
        margin-left: 1rem;
      }
    }
    
    a {
      color: ${text};
      text-decoration: none;
    }

    .Toastify__toast-container {
      ${mediaQuery('XL')(`
        width: 50vw;

        button {
          min-width: 0.75rem;
          min-height: 0.75rem;
          max-width: 0.5rem;
          max-height: 0.5rem;
        }
      `)}

      ${mediaQuery('M')(`
        width: calc(100vw - 2 * var(--plasma-grid-margin));

        button {
          min-width: 0.75rem;
          min-height: 0.75rem;
          max-width: 0.5rem;
          max-height: 0.5rem;
        }
      `)}
    }
`;

const EvaStyle = createGlobalStyle(darkEva);
const SberStyle = createGlobalStyle(darkSber);
const JoyStyle = createGlobalStyle(darkJoy);

const createThemeStyle = (character: SberCharacterEnum) => {
  switch (character) {
    case 'eva':
      return EvaStyle;
    case 'joy':
      return JoyStyle;
    default:
      return SberStyle;
  }
};

export const GlobalStyle: React.FunctionComponent<GlobalStyleProps> = (
  props
) => {
  const ThemeStyle = createThemeStyle(props.character);

  return (
    <>
      <DocumentStyle {...props} key={props.character} />
      <ThemeStyle />
    </>
  );
};
