import * as React from 'react';
import { ApplicationHeader } from 'src/components/ApplicationHeader';
import { RootWrapper } from 'src/components/RootWrapper';
import {
  Title,
  Text,
  Subtitle,
  Separator,
  Wrapper,
  TextWrapper,
} from './StyledComponents';

const TITLE = 'AMF — международная сеть доставки цветов';
const SUBTITLE =
  'В данной версии приложения временно заказы к доставке принимаются только по Москве и в ближайшее время география городов будет расширена.';

export const About: React.FunctionComponent = () => {
  return (
    <RootWrapper>
      <ApplicationHeader isBack />
      <Wrapper>
        <Title>{TITLE}</Title>
        <TextWrapper>
          <Text>
            Более 5 000 наших специалистов ежедневно в течение более 20 лет
            занимаются изготовлением и профессиональной доставкой цветов и
            цветочных композиций, свадебных букетов и других флористических
            подарков по всему миру.
            <Separator />
            Главный принцип работы AMF — международная сеть доставки цветов —
            доставленный букет на заказ должен на 100% соответствовать
            заказанному на нашем сайте или фирменном салоне. Компания дает
            гарантию, на то, что близкие и партнеры клиента увидят именно тот
            букет или композицию, которые им заказали, а не более дешевую или
            имеющуюся в наличии копию.
          </Text>
        </TextWrapper>
        <Subtitle>{SUBTITLE}</Subtitle>
        <TextWrapper>
          <Text>
            Автопарк AMF только по Москве состоит из 50 единиц автотранспорта.
            Это специализированные автомобили, оснащенные системой
            климат-контроля и оборудованием для перевозки цветов в вертикальном
            положении в воде.
            <Separator />
            География доставки цветов — 1 100 городов России и 195 стран мира.
            При этом международная сеть доставки регулярно расширяется,
            увеличивая число флористических салонов-партнеров, которые работают
            в соответствии с высокими стандартами качества компании.
            <Separator />
            Заказ цветов можно осуществить круглосуточно, 7 дней в неделю.
            <Separator />
            Время доставки цветов по Москве — около 4-х часов. Доставка цветов
            по Санкт-Петербургу — от 2 часов. Доставка цветов по России,
            ближнему и дальнему зарубежью — 1 сутки.
            <Separator />
            Телефоны нашего контакт центра:
            <Separator />
            +7 (495) 974-70-90 (Москва и весь мир)
            <Separator />8 (800) 200-70-90 (Звонок по России бесплатный)
          </Text>
        </TextWrapper>
      </Wrapper>
    </RootWrapper>
  );
};
