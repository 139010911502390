import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { AppContext } from 'src/lib/contexts/AppContext';
import { ApplicationHeader } from 'src/components/ApplicationHeader';
import { isSubmitCommentCommand } from 'src/types/AssistantCommands/SmartAppDataTypeCheckers/isSubmitCommentCommand';
import { isChangeCommentCommand } from 'src/types/AssistantCommands/SmartAppDataTypeCheckers/isChangeCommentCommand';
import { SubmitCommentCommand } from 'src/types/AssistantCommands/SubmitCommentCommand';
import { RootWrapper } from 'src/components/RootWrapper';
import voiceIcon from 'src/assets/voice.png';
import { ApplicationAddInfoText } from 'src/components/ApplicationAddInfoText';
import { ApplicationCardWithButtons } from 'src/components/ApplicationCardWithButtons';
import { AddressViewEnum } from 'src/types/enums/AddressViewEnum';
import { clientHistory } from 'src/history';
import { KeyboardInputButton } from 'src/components/KeyboardInputButton';
import { isGoToInputCommand } from 'src/types/AssistantCommands/SmartAppDataTypeCheckers/isGoToInputCommand';
import {
  AutoFocusTextField,
  Form,
  Inner,
  Separator,
  SkipButton,
  Wrapper,
} from './StyledComponents';
import { Container, Spinner } from '@salutejs/plasma-ui';
import { isSubmitInputCommand } from 'src/types/AssistantCommands/SmartAppDataTypeCheckers/isSubmitInputCommand';
import { DeviceQuery } from 'src/components/DeviceQuery';
import { DeviceQueryEnum } from 'src/enums/DeviceQueryEnum';
import { GoOnButton } from 'src/components/GoOnButton';
import { observer } from 'mobx-react';
import { useLoadingWrapper } from 'src/hooks/useLoadingWrapper';
import { isSkipCommentCommand } from 'src/types/AssistantCommands/SmartAppDataTypeCheckers/isSkipCommentCommand';

const TITLE = 'Комментарий к доставке';
const SHORT_TITLE = 'комментарий';
const TEXT = 'комментарий к адресу доставки (подъезд, этаж...)';
const SKIP = 'Пропустить';
const SUBTITLE = 'Шаг оформления 2 из 6';

export const DeliveryComment: React.FunctionComponent<RouteComponentProps> =
  observer((props) => {
    const context = React.useContext(AppContext);
    const [comment, setComment] = React.useState('');
    const [input, setInput] = React.useState('');
    const view = props.location.pathname.includes('input')
      ? AddressViewEnum.input
      : AddressViewEnum.voice;

    const useLoading = useLoadingWrapper();
    const waitForPromise = useLoading.waitForPromise;
    const isProcessing = useLoading.isLoading;
    const skipText = isProcessing ? <Spinner size={32} /> : SKIP;

    const onCommentSubmit = React.useCallback(
      (data: SubmitCommentCommand) => {
        setComment(data.smart_app_data.comment);

        context.CartService.changeComment(data.smart_app_data.comment);
      },
      [context.CartService]
    );

    const onCommentChange = React.useCallback(() => {
      setComment('');

      context.AssistantService.onGoToComment();

      context.AssistantService.openComment();
    }, [context.AssistantService]);

    const onSkipComment = React.useCallback(async () => {
      waitForPromise(context.AssistantService.onSkipComment());
    }, [context.AssistantService]);

    const onGoToTime = React.useCallback(async () => {
      context.AssistantService.onGoToTime();
    }, [context.AssistantService]);

    const onInputSubmit = React.useCallback(
      (evt?: React.SyntheticEvent) => {
        if (evt.preventDefault) {
          evt.preventDefault();
        }

        if (input.length > 0) {
          waitForPromise(context.CartService.changeComment(input));

          setComment(input);

          context.AssistantService.enterComment();
        }
      },
      [context.CartService, context.AssistantService, input]
    );

    const onInputChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
      setInput(evt.target.value);
    };

    const onViewChange = () => {
      clientHistory.push('/comment/input');

      context.AssistantService.enterTextInput('comment');
    };

    React.useEffect(() => {
      const unsubscribeFromCommentSubmit =
        context.SmartAppAssistantHelper.conditionalOnData(
          isSubmitCommentCommand,
          onCommentSubmit
        );

      const unsubscribeFromCommentChange =
        context.SmartAppAssistantHelper.conditionalOnData(
          isChangeCommentCommand,
          onCommentChange
        );

      const unsubscribeFromOnGoToInput =
        context.SmartAppAssistantHelper.conditionalOnData(
          isGoToInputCommand,
          onViewChange
        );

      const unsubscribeFromOnInputSubmit =
        context.SmartAppAssistantHelper.conditionalOnData(
          isSubmitInputCommand,
          onInputSubmit
        );

      const unsubscribeFromOnSkipComment =
        context.SmartAppAssistantHelper.conditionalOnData(
          isSkipCommentCommand,
          onSkipComment
        );

      return () => {
        unsubscribeFromCommentChange();
        unsubscribeFromCommentSubmit();
        unsubscribeFromOnGoToInput();
        unsubscribeFromOnInputSubmit();
        unsubscribeFromOnSkipComment();
      };
    }, [
      context.SmartAppAssistantHelper,
      onViewChange,
      onCommentChange,
      onCommentSubmit,
      onInputSubmit,
      onSkipComment,
    ]);

    React.useEffect(() => {
      context.CartService.changeComment('');

      setComment('');

      if (view === AddressViewEnum.voice) {
        context.AssistantService.openComment();
      }
    }, [view]);

    return (
      <>
        <ApplicationHeader title={TITLE} subtitle={SUBTITLE} isBack={true}>
          {view === AddressViewEnum.voice && (
            <KeyboardInputButton onClick={onViewChange} />
          )}
        </ApplicationHeader>

        <RootWrapper>
          <Container>
            {view === AddressViewEnum.input && comment.length === 0 && (
              <>
                <DeviceQuery on={[DeviceQueryEnum.mobile]}>
                  <Wrapper>
                    <Form onSubmit={onInputSubmit}>
                      <AutoFocusTextField
                        placeholder={TITLE}
                        onChange={onInputChange}
                        autoFocus
                      />
                      <GoOnButton
                        isDisabled={input.length === 0}
                        isProcessing={isProcessing}
                        onClick={onInputSubmit}
                      />
                      <SkipButton
                        size="m"
                        view="secondary"
                        text={skipText}
                        stretch
                        onClick={onSkipComment}
                      />
                    </Form>
                  </Wrapper>
                </DeviceQuery>
                <DeviceQuery off={[DeviceQueryEnum.mobile]}>
                  <Form onSubmit={onInputSubmit}>
                    <AutoFocusTextField
                      placeholder={TITLE}
                      onChange={onInputChange}
                      autoFocus
                    />
                  </Form>
                </DeviceQuery>
              </>
            )}

            {((view === AddressViewEnum.voice && comment.length === 0) ||
              comment.length > 0) && (
              <Wrapper>
                {view === AddressViewEnum.voice && comment.length === 0 && (
                  <Inner>
                    <ApplicationAddInfoText
                      text={TEXT}
                      icon={voiceIcon}
                      alt={TITLE}
                    />
                    <DeviceQuery on={[DeviceQueryEnum.mobile]}>
                      <Separator />

                      <SkipButton
                        size="m"
                        view="secondary"
                        text={skipText}
                        stretch
                        onClick={onSkipComment}
                      />
                    </DeviceQuery>
                    <DeviceQuery off={[DeviceQueryEnum.mobile]}>
                      <SkipButton
                        size="s"
                        view="secondary"
                        text={skipText}
                        autoFocus
                        onClick={onSkipComment}
                      />
                    </DeviceQuery>
                  </Inner>
                )}

                {comment.length > 0 && (
                  <ApplicationCardWithButtons
                    title={SHORT_TITLE}
                    text={comment}
                    onSubmit={onGoToTime}
                    onRefuse={onCommentChange}
                  />
                )}
              </Wrapper>
            )}
          </Container>
        </RootWrapper>
      </>
    );
  });
