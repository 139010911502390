import { colors } from '@salutejs/plasma-tokens';
import * as React from 'react';
import styled from 'styled-components';
import sadOstrich from 'src/assets/sadOstrich.png';
import sadOstrichM from 'src/assets/sadOstrich@2x.png';
import sadOstrichL from 'src/assets/sadOstrich@3x.png';
import sadOstrichXL from 'src/assets/sadOstrich@4x.png';
import { screenResolution } from 'src/constants';
import { Button, Footnote1, Headline2, mediaQuery } from '@salutejs/plasma-ui';

const TITLE = 'Ничего не нашлось';
const TEXT =
  'Попробуйте спросить еще раз, уточните свой запрос или поищите что-либо другое';
const BACK = 'Назад';

const Wrapper = styled.div`
  display: flex;
  padding-right: 3rem;

  ${mediaQuery('S')(`
    flex-direction: column;
    align-items: center;
    align-self: flex-start;
    padding-top: 3.25rem;
    padding-right: 0;
  `)}
`;

const Inner = styled.div`
  padding-top: 1.625rem;

  ${mediaQuery('S')(`
    padding-top: 0;
    text-align: center;
  `)}
`;

const NotFoundImage = styled.img`
  width: 10.19rem;
  min-width: 10.19rem;
  height: 11.94rem;
  margin-right: 0.75rem;

  ${mediaQuery('S')(`
    margin-right: 0;
  `)}
`;

const Title = styled(Headline2)`
  margin-bottom: 0.75rem;
`;

const Text = styled(Footnote1)`
  margin-bottom: 1.5rem;
  color: ${colors.tertiary};
`;

export const NotFound: React.FunctionComponent<{ onClick: () => void }> = (
  props
) => {
  return (
    <Wrapper>
      <NotFoundImage
        src={sadOstrich}
        srcSet={`${sadOstrich} ${screenResolution.s}w, ${sadOstrichM} ${screenResolution.m}w, ${sadOstrichL} ${screenResolution.l}w ${sadOstrichXL} ${screenResolution.xl}w`}
      />
      <Inner>
        <Title>{TITLE}</Title>
        <Text>{TEXT}</Text>
        <Button view="secondary" size="s" text={BACK} onClick={props.onClick} />
      </Inner>
    </Wrapper>
  );
};
