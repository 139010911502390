import {
  Button,
  Button2,
  Caption,
  CarouselCol,
  Footnote1,
  Footnote2,
  H2Compatible,
  H3Compatible,
  mediaQuery,
  Radiobox,
} from '@salutejs/plasma-ui';
import styled from 'styled-components';
import { colors, fonts } from '@salutejs/plasma-tokens';
import { RootWrapper } from 'src/components/RootWrapper';

export const StyledRootWrapper = styled(RootWrapper)`
  overflow-x: hidden;
`;

export const Content = styled.div`
  display: flex;
  justify-content: flex-start;
  position: relative;
  align-items: stretch;
  align-items: space-between;
  min-width: 100%;

  label.productRadiobox {
    align-items: center;
    margin-top: 0.75rem;

    &:first-of-type {
      margin-top: 0;
    }
  }

  ${mediaQuery('S')(`
    display: block;
    overflow-x: visible;
`)}
`;

export const ContentColumn = styled.div`
  width: 16rem;
  margin-right: 3.5rem;

  ${mediaQuery('S')(`
    width: calc(100vw - var(--plasma-grid-margin));
    margin-right: 0;
`)}

  ${mediaQuery('XL')(`
    width: 100%;
    margin-right: 5.5rem;
  `)}
`;

export const CountControlButton = styled(Button)`
  & > img {
    max-width: 1rem;
    max-height: 1rem;
    min-width: 1rem;
    min-height: 1rem;
  }
`;

export const CountControl = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 1rem;

  ${mediaQuery('XL')(`
    margin-right: 2rem;
  `)}
`;

export const CountLabel = styled.span`
  font-size: 1rem;
  font-family: ${fonts.Regular};
  color: ${colors.white};
  margin: 0 1rem;
`;

export const CartControlWrapper = styled.div`
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  & > * {
    margin-bottom: 1.5rem;
    ${mediaQuery('XL')(`
    margin-bottom: 2rem;
  `)}
  }

  ${mediaQuery('M')(`
    min-width: 15.5rem;
  `)}
`;

export const AdditionalInfoInner = styled.div`
  margin-bottom: 1.5rem;
`;

export const AdditionalInfoTitle = styled(Footnote2)`
  margin-bottom: 0.5rem;
`;

export const AdditionalInfoText = styled(Footnote1)`
  color: ${colors.tertiary};
`;

export const ListItem = styled.li`
  width: 100%;
`;

export const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const Price = styled(H3Compatible)`
  margin-bottom: 1.5rem;
  color: ${colors.accent};

  ${mediaQuery('S')(`
    margin-bottom: 1rem;
  `)}

  ${mediaQuery('XL')(`
    margin-bottom: 2rem;
    font-size: 1.5rem;
    line-height: 1.75rem;
  `)}
`;

export const ProductRadiobox = styled(Radiobox)`
  margin-top: 0.75rem;

  &:first-of-type {
    margin-top: 0;
  }

  & input {
    opacity: 0;
  }

  ${mediaQuery('XL')(`
    margin-top: 1rem;
  `)}
`;

export const RadioboxTitle = styled(Button2)`
  display: flex;
  align-items: baseline;
  font-size: 1rem;
  line-height: 1.375rem;

  ${mediaQuery('M')(`
    font-size: 0.875rem;
    line-height: 1rem;
  `)}
`;

export const RadioboxText = styled(Caption)`
  color: ${colors.tertiary};
  max-width: 14rem;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1rem;
  line-height: 1.375rem;
  margin-left: 0.25rem;

  ${mediaQuery('M')(`
    max-width: 10rem;
    font-size: 0.75rem;
    line-height: 1rem;
  `)}
`;

export const RadioboxesWrapper = styled.div`
  margin-bottom: 1.5rem;

  ${mediaQuery('XL')(`
    margin-bottom: 2rem;
  `)}
`;

export const Title = styled(H2Compatible)`
  margin-bottom: 0.5rem;

  ${mediaQuery('S')(`
    line-height: 1.75rem;
    margin-bottom: 0.25rem;
  `)}

  ${mediaQuery('XL')(`
    margin-bottom: 0.75rem;
  `)}
`;

export const GalleryButton = styled(Button)`
  width: 2.4375rem;
  height: 1.5rem;
  padding: 0;
  border-radius: 1rem;
  background-color: ${colors.backgroundSecondary};

  img {
    width: 0.9375rem;
    height: 0.625rem;
  }
`;

export const ButtonsWrapper = styled.div`
  position: absolute;
  bottom: 0.75rem;
  display: flex;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  width: 100%;
  box-sizing: border-box;
`;

export const CurrentPage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.625rem;
  height: 1.5rem;
  margin-left: auto;
  margin-right: auto;
  border-radius: 1rem;
  background-color: ${colors.backgroundSecondary};
`;

export const Gallery = styled.div`
  position: relative;
  display: flex;
  align-items: stretch;
  height: 100%;

  ${mediaQuery('XL')(`
    margin-left: auto;
  `)}
`;

export const CurrentImageIndex = styled(Caption)`
  font-weight: bold;
`;

export const Amount = styled(Caption)`
  font-weight: bold;
  color: ${colors.whiteSecondary};
`;

export const Image = styled.img`
  position: absolute;
  width: 100%;
  border-radius: 1.125rem;
  aspect-ratio: 1;
  object-fit: cover;
`;

export const ImageWrapper = styled.div`
  border-radius: 1.125rem;
  display: block;
  width: calc(0.85 * var(--content-height));
  height: var(--content-height);
  overflow: hidden;
`;

export const StyledCarouselCol = styled(CarouselCol)`
  padding-left: 0;
  padding-right: 1rem;
  display: flex;

  &:last-child {
    padding-right: 0;
  }

  ${mediaQuery('XL')(`
    &::before {
      display: none;
      position: absolute;
      content: '';
      height: calc(21rem - 0.75rem);
      width: calc(21rem - 0.75rem);
      border: 0.375rem solid ${colors.accent};
      border-radius: 1.5rem;
    }

    &:focus {
      &::before {
        display: block;
      }
    }
  `)}
`;

export const ProductImage = styled.img`
  border-radius: 1.5rem;
  width: 100%;
`;

export const ProductImageWrapper = styled.div`
  width: 14.375rem;
  min-width: 14.375rem;
  height: 14.375rem;
  position: relative;
  border-radius: 1.5rem;
  overflow: hidden;

  img {
    position: absolute;
    width: 100%;
    margin-top: -1rem;
  }

  ${mediaQuery('XL')(`
    width: 21rem;
    min-width: 21rem;
    height: 21rem;
  `)}
`;

export const ApplicationCarouselWrapper = styled.div`
  margin-bottom: 1rem;

  > div.carousel {
    > div {
      padding-left: 4rem;
    }
  }

  ${mediaQuery('XL')(`
    margin-bottom: 1.5rem;
  `)}
`;

export const ProductImageCarouselOutterWrapper = styled.div`
  flex: 1;
  overflow: hidden;
`;
export const ProductImageCarouselWrapper = styled.div`
  margin-bottom: 1rem;
  flex: 1;

  ${mediaQuery('XL')(`
    width: 21rem;
    min-width: 21rem;
    overflow: hidden;
  `)}
`;

export const PaginationDotsWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const OnPhotoLabelWrapper = styled.div`
  padding: 0.25rem 0.75rem;
  margin-left: 0.5rem;
  border: 0.0625rem solid ${colors.primary};
  border-radius: 42px;
  color: ${colors.primary};
  line-height: 1rem;
`;

export const SpinnerWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
`;
