import * as React from 'react';
import { Headline2, Button1, mediaQuery } from '@salutejs/plasma-ui';
import styled from 'styled-components';
import { SberCharacterEnum } from 'src/types/enums/SberCharacterEnum';
import { AssistantContext } from 'src/AssistantContext';

const NAME = 'Назовите';
const NAME_JOY = 'Назови';

const StyledText = styled(Headline2)`
  background: -webkit-linear-gradient(360deg, #1dd13b, #27c6e5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 25rem;

  ${mediaQuery('S')(`
    width: 100%;
  `)}
`;

const Image = styled.img`
  width: 8rem;
  height: 8rem;
`;

const Text = styled(Button1)`
  margin-top: -1.25rem;
  margin-bottom: 0.25rem;

  ${mediaQuery('S')(`
    margin-top: 0.75rem;
    margin-bottom: 0.5rem;
    font-weight: 500;
  `)}
`;

export const ApplicationAddInfoText: React.FunctionComponent<{
  text: string;
  icon: string;
  alt: string;
}> = (props) => {
  const assistantContext = React.useContext(AssistantContext);
  const addText =
    assistantContext.character === SberCharacterEnum.joy ? NAME_JOY : NAME;

  return (
    <Wrapper>
      <Image src={props.icon} alt={props.alt} />

      <Text>{addText}</Text>

      <StyledText>{props.text}</StyledText>
    </Wrapper>
  );
};
