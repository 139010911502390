import * as React from 'react';
import { DeviceTypeContext } from 'src/contexts/DeviceTypeContext';
import { DeviceQueryEnum } from 'src/enums/DeviceQueryEnum';

interface Props {
  on?: DeviceQueryEnum[];
  off?: DeviceQueryEnum[];
}

export const DeviceQuery: React.FunctionComponent<Props> = (props) => {
  const device = React.useContext(DeviceTypeContext);

  const isShown = React.useMemo(() => {
    if (props.off.includes(device)) {
      return false;
    }

    if (props.on.length === 0 || props.on.includes(device)) {
      return true;
    }

    return false;
  }, [props.off, props.on]);

  if (!isShown) {
    return null;
  }

  return <>{props.children}</>;
};

DeviceQuery.defaultProps = {
  on: [],
  off: [],
};
