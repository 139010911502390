import * as React from 'react';
import { IReactionDisposer, reaction } from 'mobx';
import { SberCharacterEnum, SberInsetsType } from 'src/types';
import {
  DeviceThemeProvider,
  detectDevice,
  DeviceKind,
} from '@salutejs/plasma-ui';
import { GlobalStyle } from 'src/GlobalStyle';
import { AppContext } from './lib/contexts/AppContext';
import { Root } from './Root';
import { clientHistory } from './history';
import { Router } from 'react-router-dom';
import { AssistantContext } from './AssistantContext';
import { Onboarding } from './screens/Onboarding';
import { About } from './screens/About';
import { Route, Switch } from 'react-router-dom';
import { isGoToAppCommand } from './types/AssistantCommands/SmartAppDataTypeCheckers/isGoToAppCommand';
import { isGoToCompanyInfoCommand } from './types/AssistantCommands/SmartAppDataTypeCheckers/isGoToCompanyInfoCommand';
import { DeviceTypeContext } from './contexts/DeviceTypeContext';
import { DeviceQueryEnum } from './enums/DeviceQueryEnum';
import { StyledToastContainer } from './StyledToastContainer';
import { ToastCloseButton } from './components/ToastCloseButton';

interface AppState {
  isInitialized: boolean;
  isGoToAppClicked: boolean;
  character: SberCharacterEnum;
  insets: SberInsetsType;
}

export class App extends React.PureComponent<unknown, AppState> {
  static contextType = AppContext;

  context!: React.ContextType<typeof AppContext>;
  unsubscribeFromGoToAppClick: () => void;
  unsubscribeFromCompanyInfoClick: () => void;

  state: AppState = {
    isGoToAppClicked: false,
    isInitialized: false,
    character: null,
    insets: {
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
    },
  };

  cartPositionsCountReactionDisposer: IReactionDisposer;

  async componentDidMount(): Promise<void> {
    await this.initialize();

    this.cartPositionsCountReactionDisposer = reaction(
      () => {
        return this.context.CartService.cartStore.positionsCount;
      },
      (positionsCount) => {
        this.context.AssistantService.changePositionsCount(positionsCount);
      }
    );

    this.context.CartService.checkStorageKeys();

    await this.context.UserService.getUserInfo();

    this.unsubscribeFromGoToAppClick =
      this.context.SmartAppAssistantHelper.conditionalOnData(
        isGoToAppCommand,
        this.onGoToAppClick
      );

    this.unsubscribeFromCompanyInfoClick =
      this.context.SmartAppAssistantHelper.conditionalOnData(
        isGoToCompanyInfoCommand,
        this.onGoToCompanyInfoClick
      );
  }

  componentWillUnmount(): void {
    this.unsubscribeFromGoToAppClick();
    this.unsubscribeFromCompanyInfoClick();
  }

  getDeviceKind(): DeviceKind {
    if (typeof navigator === 'undefined') {
      return 'sberBox';
    }

    return detectDevice();
  }

  async initialize(): Promise<void> {
    const unsubscribeFromCharacter = this.context.AssistantService.onCharacter(
      (character) => {
        this.setState(
          {
            character,
          },
          () => unsubscribeFromCharacter()
        );
      }
    );

    const unsubscribeFromInsets = this.context.AssistantService.onInsets(
      ({ insets }) => {
        const scaleFactor = window.devicePixelRatio;

        this.setState(
          {
            insets: {
              top: insets.top / scaleFactor,
              bottom: insets.bottom / scaleFactor,
              left: insets.left / scaleFactor,
              right: insets.right / scaleFactor,
            },
          },
          () => unsubscribeFromInsets()
        );
      }
    );

    await this.context.AppInitializationService.initialize();

    this.setState({ isInitialized: true });
  }

  onGoToAppClick = () => {
    this.setState({ isGoToAppClicked: true });
  };

  onGoToCompanyInfoClick = () => {
    clientHistory.push('/about');
  };

  render(): React.ReactElement {
    return (
      <AssistantContext.Provider
        value={{
          character: this.state.character,
        }}
      >
        <DeviceTypeContext.Provider
          value={this.getDeviceKind() as DeviceQueryEnum}
        >
          <DeviceThemeProvider detectDeviceCallback={this.getDeviceKind}>
            <GlobalStyle
              isInitialized={this.state.isInitialized}
              character={this.state.character}
              insets={this.state.insets}
              device={this.getDeviceKind()}
            />
            <Router history={clientHistory}>
              {this.state.isGoToAppClicked && (
                <Root isLoading={!this.state.isInitialized} />
              )}
              {!this.state.isGoToAppClicked && (
                <Switch>
                  <Route path="/about" component={About} />
                  <Route
                    render={() => (
                      <Onboarding
                        onGoToAppClick={this.onGoToAppClick}
                        onGoToCompanyInfoClick={this.onGoToCompanyInfoClick}
                      />
                    )}
                  />
                </Switch>
              )}
            </Router>
            <StyledToastContainer
              hideProgressBar={true}
              closeButton={ToastCloseButton}
              autoClose={10000}
            />
          </DeviceThemeProvider>
        </DeviceTypeContext.Provider>
      </AssistantContext.Provider>
    );
  }
}
