import * as React from 'react';
import { CategoryType } from 'src/types';
import { ProductFilterType } from 'src/types/ProductFilterType';
import { SortOptionType } from 'src/types/SortOptionType';
import { OptionsTitle, OptionItemsWrapper } from './StyledComponents';
import { OptionItem } from './OptionItem';
import { PriceFiltersType } from 'src/types/PriceFiltersType';

const FILTERS = 'Фильтры';

export const FiltersOptions: React.FunctionComponent<{
  option: ProductFilterType;
  isShowMore: boolean;
  isMobile: boolean;
  isTitleShown: boolean;
  items: PriceFiltersType[];
  onChange: (option: CategoryType | ProductFilterType | SortOptionType) => void;
}> = (props) => {
  return (
    <>
      {props.isTitleShown && <OptionsTitle>{FILTERS}</OptionsTitle>}
      <OptionItemsWrapper>
        {props.items.map((filter, index) => {
          return (
            <OptionItem
              key={index}
              text={filter.label}
              value={filter.value}
              isSelected={
                JSON.stringify(props.option) === JSON.stringify(filter.value)
              }
              onChange={props.onChange}
            />
          );
        })}
      </OptionItemsWrapper>
    </>
  );
};
