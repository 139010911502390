import { inject, injectable } from 'inversify';
import { CartPositionType, CartType, ProductType } from 'src/types';
import { Identifiers } from '../identifiers';
import { IDBStorageHelper } from '../helpers/IDBStorageHelper';
import { AddressType } from 'src/types/AddressType';
import { CartProviderInterface } from '../interfaces/providers/CartProviderInterface';
import { CheckedCartType } from 'src/types/CheckedCartType';
import { ApiRequestHelper } from '../helpers/ApiRequestHelper';
import { ParsedAddress } from 'src/types/ParsedAddress';
import { DeliveryIntervalType } from 'src/types/DeliveryIntervalType';
import { DeliveryType } from 'src/types/DeliveryType';
import { ExactDeliveryIntervalType } from 'src/types/ExactDeliveryIntervalType';
import { UserInfoType } from 'src/types/UserInfoType';
import { DeliveryInfoType } from 'src/types/DeliveryInfoType';
import { CartOptionsType } from 'src/types/CartOptionsType';
import { DeliveryCostType } from 'src/types/DeliveryCostType';
import * as Sentry from '@sentry/browser';
import { GetInvoiceIdError } from '../errors/GetInvoiceIdError';
import { WrongCartError } from '../errors/WrongCartError';

@injectable()
export class CartProvider implements CartProviderInterface {
  @inject(Identifiers.helpers.StorageHelper)
  private storageHelper: IDBStorageHelper;

  @inject(Identifiers.helpers.ApiRequestHelper)
  private apiRequestHelper: ApiRequestHelper;

  static cartPositionsStorageKey = 'cartPositions';
  static cartPositionsKey = 'cartPositionsKey';
  static cartPositionsNewKey = 'cartPositionsNewKey';
  static cartAddressKey = 'cartAddress';
  static cartCommentKey = 'cartCommentKey';
  static cartDeliveryKey = 'cartDeliveryKey';
  static receiverNameKey = 'receiverNameKey';
  static receiverPhoneKey = 'receiverPhoneKey';
  static cartOptionsKey = 'cartOptionsKey';

  async checkStorageKeys(): Promise<void> {
    try {
      await this.storageHelper.removeItem(CartProvider.cartPositionsStorageKey);
      await this.storageHelper.removeItem(CartProvider.cartPositionsKey);
    } catch (error) {
      console.error(error);
    }
  }

  async createPosition(
    id: number,
    item: ProductType,
    count = 1
  ): Promise<void> {
    try {
      const storedCartPositions = await this.getCartPositions();

      if (storedCartPositions.find((item) => item.id === id)) {
        return;
      }

      this.storageHelper.setItem(
        CartProvider.cartPositionsNewKey,
        JSON.stringify(storedCartPositions.concat({ id, item, count }))
      );
    } catch (error) {
      console.error(error);
    }
  }

  async checkCart(positions: CartPositionType[]): Promise<CheckedCartType> {
    try {
      const url = `${process.env.REACT_APP_API_URL}/api/v1/checkout/cartcheck`;
      const items = positions.map((position) => ({
        product_id: position.item.id,
        price_id: position.id,
        quantity: position.count,
      }));
      const response = await this.apiRequestHelper.makeAuthorizedRequest(
        url,
        {
          items,
        },
        undefined,
        {
          validateStatus: () => true,
        }
      );

      if (response.status === 403) {
        throw new WrongCartError();
      }

      if (response.status !== 200) {
        console.error(JSON.stringify(response.data));
        throw new Error('Unable to fetch check cart');
      }

      return response.data.data as CheckedCartType;
    } catch (error) {
      console.error(error);
      if (error instanceof WrongCartError) {
        throw error;
      }
      return null;
    }
  }

  async checkPaymentStatus(invoiceId: string): Promise<boolean> {
    try {
      const url = `${process.env.REACT_APP_API_URL}/api/v1/checkout/sber-statusordercheck`;
      const response = await this.apiRequestHelper.makeAuthorizedRequest(
        url,
        {
          invoice_id: invoiceId,
        },
        undefined,
        {
          validateStatus: () => true,
        }
      );

      if (response.status !== 200) {
        console.error(JSON.stringify(response.data));
        throw new Error(
          `Unable to checkPaymentStatus with status code ${invoiceId}`
        );
      }

      return response.data.success as boolean;
    } catch (error) {
      Sentry.captureException(error);
      return false;
    }
  }

  async changePositionCount(id: number, count: number): Promise<void> {
    try {
      const storedCartPositions: CartPositionType[] = JSON.parse(
        await this.storageHelper.getItem(CartProvider.cartPositionsNewKey)
      );
      const index = storedCartPositions.findIndex(
        (position) => position.id === id
      );

      if (count === 0) {
        storedCartPositions.splice(index, 1);
      } else {
        storedCartPositions[index].count = count;
      }

      console.info(storedCartPositions);
      this.storageHelper.setItem(
        CartProvider.cartPositionsNewKey,
        JSON.stringify(storedCartPositions)
      );
    } catch (error) {
      console.error(error);
    }
  }

  async getCartPositions(): Promise<CartPositionType[]> {
    const storedCartPositions: string = await this.storageHelper.getItem(
      CartProvider.cartPositionsNewKey
    );
    if (!storedCartPositions) return [];

    try {
      return JSON.parse(storedCartPositions);
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  async getAddresses(): Promise<AddressType[]> {
    const data = await import('../mockData/addressesMockData');

    return data.addresses;
  }

  async getInvoiceId(
    cart: CartType,
    user: UserInfoType
  ): Promise<{
    invoice: string;
    customerOrderId: string;
    orderId: string;
  }> {
    try {
      const url = `${process.env.REACT_APP_API_URL}/api/v1/checkout/sber-process`;

      const items = cart.positions.map((position) => ({
        product_id: position.item.id,
        price_id: position.id,
        quantity: position.count,
      }));

      const order = {
        address: cart.options.isAskAddress ? null : cart.selectedAddress.value,
        full_name: cart.receiver.name || user.name,
        phone: cart.receiver.phone || user.phone,
        email: user.email,
        sender_name: user.name,
        sender_phone: user.phone,
        comment: cart.comment,
        self_receive: cart.options.isSelfReceive ? 'yes' : 'no',
        ask_address: cart.options.isAskAddress ? 'yes' : 'no',
        give_other: cart.options.isGiveOther ? 'yes' : 'no',
        anonym: cart.options.isAnonym ? 'yes' : 'no',
        subscribe: cart.options.isSubscribed ? 'yes' : 'no',
        call_reception: cart.options.isCallReception ? 'yes' : 'no',
      };

      const delivery = {
        interval_id: cart.delivery.intervalId,
        date: cart.delivery.date,
        type: 'courier',
        additional_services: cart.delivery.additionalServices,
      };

      const data = {
        items,
        order,
        delivery,
      };

      const response = await this.apiRequestHelper.makeAuthorizedRequest(
        url,
        data,
        3,
        {
          validateStatus: () => true,
        }
      );

      if (response.status !== 200) {
        console.info(JSON.stringify(response.data));
        throw new GetInvoiceIdError(
          (response?.data?.errors as string[]) || [
            `Не удалось создать заказ (${response.status})`,
          ]
        );
      }

      return {
        invoice: response.data.invoice_id as string,
        customerOrderId: response.data.customer_order_id as string,
        orderId: response.data.order_id as string,
      };
    } catch (error: any) {
      Sentry.captureException(error);

      throw error;
    }
  }

  async getAddress(): Promise<ParsedAddress> {
    const storedAddress: string = await this.storageHelper.getItem(
      CartProvider.cartAddressKey
    );
    if (!storedAddress) return null;

    try {
      return JSON.parse(storedAddress);
    } catch (error) {
      return null;
    }
  }

  /**
   * @deprecated
   * @param positions
   * @returns
   */
  async getCartAmount(positions: CartPositionType[]): Promise<number> {
    try {
      const url = `${process.env.REACT_APP_API_URL}/api/v1/checkout/init`;

      const items = positions.map((position) => ({
        product_id: position.item.id,
        price_id: position.id,
        quantity: position.count,
      }));

      const data = {
        items,
      };

      const response = await this.apiRequestHelper.makeAuthorizedRequest(
        url,
        data
      );

      return response.data.data.full_cost;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  async getCartOptions(): Promise<CartOptionsType> {
    const storedOptions: string = await this.storageHelper.getItem(
      CartProvider.cartOptionsKey
    );

    if (!storedOptions) return null;

    try {
      return JSON.parse(storedOptions);
    } catch (error) {
      return null;
    }
  }

  async getComment(): Promise<string> {
    const storedComment: string = await this.storageHelper.getItem(
      CartProvider.cartCommentKey
    );
    if (!storedComment) return null;

    try {
      return JSON.parse(storedComment);
    } catch (error) {
      return null;
    }
  }

  async getDelivery(): Promise<DeliveryType> {
    const storedDelivery: string = await this.storageHelper.getItem(
      CartProvider.cartDeliveryKey
    );
    if (!storedDelivery) return null;

    try {
      return JSON.parse(storedDelivery);
    } catch (error) {
      return null;
    }
  }

  /**
   * @deprecated
   * @param cart
   * @returns
   */
  async getDeliveryCost(cart: CartType): Promise<DeliveryCostType> {
    try {
      const url = `${process.env.REACT_APP_API_URL}/api/v1/checkout/init`;

      const items = cart.positions.map((position) => ({
        product_id: position.item.id,
        price_id: position.id,
        quantity: position.count,
      }));

      const data = {
        items,
      };

      const response = await this.apiRequestHelper.makeAuthorizedRequest(
        url,
        data
      );

      const deliveries = response.data.data.deliveries as DeliveryInfoType[];

      const exactIntervalPrice =
        response.data.data.intervals[0].exact_delivery_time.price;

      const price =
        deliveries.find((delivery) => delivery.key_id === 'courier')
          .delivery_services?.[0]?.price || 0;

      console.info({ price, exactIntervalPrice });

      return { price, exactIntervalPrice };
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  async getReceiverName(): Promise<string> {
    const storedReceiverName: string = await this.storageHelper.getItem(
      CartProvider.receiverNameKey
    );
    if (!storedReceiverName) return null;

    try {
      return JSON.parse(storedReceiverName);
    } catch (error) {
      return null;
    }
  }

  async getReceiverPhone(): Promise<string> {
    const storedReceiverPhone: string = await this.storageHelper.getItem(
      CartProvider.receiverPhoneKey
    );
    if (!storedReceiverPhone) return null;

    try {
      return JSON.parse(storedReceiverPhone);
    } catch (error) {
      return null;
    }
  }

  /**
   * @param positions
   * @param address
   * @returns
   */
  async loadDeliveryIntervals(
    positions: CartPositionType[],
    address: ParsedAddress
  ): Promise<DeliveryIntervalType[]> {
    const url = `${process.env.REACT_APP_API_URL}/api/v1/checkout/clarify-delivery-intervals`;
    const items = positions.map((position) => ({
      product_id: position.item.id,
      price_id: position.id,
      quantity: position.count,
    }));
    const data = {
      items,
      order: {
        address: address.value,
      },
    };
    const response = await this.apiRequestHelper.makeAuthorizedRequest(
      url,
      data,
      undefined,
      {
        validateStatus: () => true,
      }
    );

    if (response.status !== 200) {
      console.error(JSON.stringify(response.data));
      Sentry.captureException(
        new Error(`Unable to get intervals with status code ${response.status}`)
      );

      if (!!response.data?.errors && Array.isArray(response.data.errors)) {
        throw new Error(`${response.data?.errors.join(', ')}`);
      }

      throw new Error('Не удалось получить интервалы доставки');
    }

    return response.data.data.intervals as DeliveryIntervalType[];
  }

  async loadExactDeliveryIntervals(
    positions: CartPositionType[],
    address: ParsedAddress,
    date: string
  ): Promise<ExactDeliveryIntervalType[]> {
    const url = `${process.env.REACT_APP_API_URL}/api/v1/checkout/exacttimeintervals`;
    const items = positions.map((position) => ({
      product_id: position.item.id,
      price_id: position.id,
      quantity: position.count,
    }));
    const data = {
      items,
      order: {
        address: address.value,
      },
      date,
    };
    const response = await this.apiRequestHelper.makeAuthorizedRequest(
      url,
      data,
      undefined,
      {
        validateStatus: () => true,
      }
    );

    if (response.status !== 200) {
      console.error(JSON.stringify(response.data));
      Sentry.captureException(
        new Error(
          `Unable to fetch exact intervals for date ${date} with status code ${response.status}`
        )
      );
      return [];
    }

    return response.data.data.intervals as ExactDeliveryIntervalType[];
  }

  async parseAddress(address: string): Promise<ParsedAddress[]> {
    if (address.length === 0) {
      return null;
    }

    try {
      const url = `${process.env.REACT_APP_API_URL}/api/v1/checkout/suggest-addresses?query=${address}`;
      const response = await this.apiRequestHelper.makeAuthorizedRequest(url);

      return response.data.data.map((address: any) => ({
        value: address.value,
        address: {
          location: {
            latitude: address.data.geo_lat,
            longitude: address.data.geo_lon,
          },
          street: address.data.street,
          number: address.house,
          building: address.block,
          room: address.flat,
        },
      })) as ParsedAddress[];
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  async resetCart(): Promise<void> {
    await this.storageHelper.setItem(
      CartProvider.cartPositionsNewKey,
      JSON.stringify([])
    );

    await this.storageHelper.setItem(
      CartProvider.cartAddressKey,
      JSON.stringify('')
    );

    await this.storageHelper.setItem(
      CartProvider.cartCommentKey,
      JSON.stringify('')
    );

    await this.storageHelper.setItem(
      CartProvider.cartDeliveryKey,
      JSON.stringify('')
    );

    await this.storageHelper.setItem(
      CartProvider.receiverNameKey,
      JSON.stringify('')
    );

    await this.storageHelper.setItem(
      CartProvider.receiverPhoneKey,
      JSON.stringify('')
    );

    await this.resetOptions();
  }

  async resetOptions(): Promise<void> {
    await this.storageHelper.setItem(CartProvider.cartOptionsKey, null);
  }

  async resetReceiverInfo(): Promise<void> {
    await this.storageHelper.setItem(CartProvider.receiverNameKey, '');
    await this.storageHelper.setItem(CartProvider.receiverPhoneKey, '');
  }

  async setAddress(address: ParsedAddress): Promise<void> {
    try {
      this.storageHelper.setItem(
        CartProvider.cartAddressKey,
        JSON.stringify(address)
      );
    } catch (error) {
      console.error(error);
    }
  }

  async setCartOption(option: { [key: string]: boolean }): Promise<void> {
    try {
      const storedOptions: CartOptionsType = await this.getCartOptions();

      this.storageHelper.setItem(
        CartProvider.cartOptionsKey,
        JSON.stringify({ ...storedOptions, ...option })
      );
    } catch (error) {
      console.error(error);
    }
  }

  async setComment(comment: string): Promise<void> {
    try {
      this.storageHelper.setItem(
        CartProvider.cartCommentKey,
        JSON.stringify(comment)
      );
    } catch (error) {
      console.error(error);
    }
  }

  async setDelivery(delivery: DeliveryType): Promise<void> {
    try {
      this.storageHelper.setItem(
        CartProvider.cartDeliveryKey,
        JSON.stringify(delivery)
      );
    } catch (error) {
      console.error(error);
    }
  }

  async setReceiverName(name: string): Promise<void> {
    try {
      this.storageHelper.setItem(
        CartProvider.receiverNameKey,
        JSON.stringify(name)
      );
    } catch (error) {
      console.error(error);
    }
  }

  async setReceiverPhone(phone: string): Promise<void> {
    try {
      this.storageHelper.setItem(
        CartProvider.receiverPhoneKey,
        JSON.stringify(phone)
      );
    } catch (error) {
      console.error(error);
    }
  }

  getCartInfo = async (
    cartPositions: CartPositionType[]
  ): Promise<{
    intervals: DeliveryIntervalType[];
    amount: number;
    error: string;
  }> => {
    if (cartPositions.length === 0) {
      return { intervals: [], amount: 0, error: null };
    }

    const url = `${process.env.REACT_APP_API_URL}/api/v1/checkout/init`;

    const items = cartPositions.map((position) => ({
      product_id: position.item.id,
      price_id: position.id,
      quantity: position.count,
    }));

    const data = {
      items,
    };

    const response = await this.apiRequestHelper.makeAuthorizedRequest(
      url,
      data,
      undefined,
      {
        validateStatus: () => true,
      }
    );

    if (response.status === 403) {
      return {
        amount: cartPositions.reduce((total, position) => {
          const positionTotal =
            (position.item.prices.find((price) => price.id === position.id)
              ?.price || 0) * position.count;
          return total + positionTotal;
        }, 0),
        intervals: [],
        error: response.data.errors.join('. '),
      };
    }

    return {
      amount: response.data.data.full_cost,
      intervals: response.data.data.intervals,
      error: null,
    };
  };

  async updateCartPositions(positions: CartPositionType[]): Promise<void> {
    try {
      this.storageHelper.setItem(
        CartProvider.cartPositionsNewKey,
        JSON.stringify(positions)
      );
    } catch (error) {
      console.error(error);
    }
  }

  cancelOrder = async (orderId: string): Promise<void> => {
    const url = `${process.env.REACT_APP_API_URL}/api/v1/me/orders/${orderId}`;

    const data = {
      action: 'cancel',
    };

    const response = await this.apiRequestHelper.makeAuthorizedRequest(
      url,
      data,
      undefined,
      {
        method: 'PUT',
        validateStatus: () => true,
      }
    );

    if (response.status !== 200) {
      console.error(JSON.stringify(response.data));
      Sentry.captureException(
        new Error(
          `Unable to cancel order ${orderId} with code code ${response.status}`
        )
      );
      return;
    }
  };
}
