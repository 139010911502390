import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { AppContext } from 'src/lib/contexts/AppContext';
import { ApplicationHeader } from 'src/components/ApplicationHeader';
import { RootWrapper } from 'src/components/RootWrapper';
import { Address } from './Address';
import { ApplicationCardWithButtons } from 'src/components/ApplicationCardWithButtons';
import { ApplicationAddInfoText } from 'src/components/ApplicationAddInfoText';
import voiceIcon from 'src/assets/voice.png';
import { isSubmitAddressCommand } from 'src/types/AssistantCommands/SmartAppDataTypeCheckers/isSubmitAddressCommand';
import { SubmitAddressCommand } from 'src/types/AssistantCommands/SubmitAddressCommand';
import { observer } from 'mobx-react';
import { clientHistory } from 'src/history';
import { AddressViewEnum } from 'src/types/enums/AddressViewEnum';
import { KeyboardInputButton } from 'src/components/KeyboardInputButton';
import flower from 'src/assets/flower.svg';
import sadFlower from 'src/assets/sadFlower.svg';
import { formatAddress } from 'src/utils/formatAddress';
import { isGoToInputCommand } from 'src/types/AssistantCommands/SmartAppDataTypeCheckers/isGoToInputCommand';
import { isChangeAddressCommand } from 'src/types/AssistantCommands/SmartAppDataTypeCheckers/isChangeAddressCommand';
import { AssistantContext } from 'src/AssistantContext';
import { SberCharacterEnum } from 'src/types';
import { isAddressNotFoundCommand } from 'src/types/AssistantCommands/SmartAppDataTypeCheckers/isAddressNotFoundCommand';
import {
  Form,
  Inner,
  Input,
  NoAddressButton,
  ParsedAddressesTitle,
  ParsedAddressesWrapper,
  Separator,
  SkipButton,
} from './StyledComponents';
import { Container, Spinner } from '@salutejs/plasma-ui';
import { DeviceQuery } from 'src/components/DeviceQuery';
import { DeviceQueryEnum } from 'src/enums/DeviceQueryEnum';
import { GoOnButton } from 'src/components/GoOnButton';
import { isSkipAddressCommand } from 'src/types/AssistantCommands/SmartAppDataTypeCheckers/isSkipAddressCommand';
import { useLoadingWrapper } from 'src/hooks/useLoadingWrapper';
import { isSubmitInputCommand } from 'src/types/AssistantCommands/SmartAppDataTypeCheckers/isSubmitInputCommand';

const TITLE = 'Адрес доставки';
const TEXT = 'улицу, дом и квартиру';
const ADDRESS = 'Адрес';
const USER_ADDRESS = 'Адрес получателя';
const SUBTITLE = 'Шаг оформления 1 из 6';
const NOT_FOUND = 'Адрес не найден';
const FLOWER = 'Цветок';
const CHANGE_ADDRESS = 'Ввести другой';
const NO_ADDRESS = 'Моего адреса нет в списке';
const SKIP = 'Узнать адрес у получателя';

export const DeliveryAddress: React.FunctionComponent<RouteComponentProps> =
  observer((props) => {
    const context = React.useContext(AppContext);
    const assistantContext = React.useContext(AssistantContext);
    const notFound =
      assistantContext.character === SberCharacterEnum.joy
        ? 'К сожалению, адрес не найден. Попробуй еще раз'
        : 'К сожалению, адрес не найден. Попробуйте еще раз';
    const chooseAddress =
      assistantContext.character === SberCharacterEnum.joy
        ? 'Уточни адрес'
        : 'Уточните адрес';
    const [address, setAddress] = React.useState('');
    const [input, setInput] = React.useState('');
    const parsedAddresses = context.CartService.cartStore.parsedAddresses;
    const view = props.location.pathname.includes('input')
      ? AddressViewEnum.input
      : AddressViewEnum.voice;

    const useLoading = useLoadingWrapper();
    const waitForPromise = useLoading.waitForPromise;
    const isProcessing = useLoading.isLoading;
    const skipText = isProcessing ? <Spinner size={32} /> : SKIP;

    const onAddressClick = (selectedAddress: string) => {
      setAddress(selectedAddress);
    };

    const onVoiceAddressSubmit = (data: SubmitAddressCommand) => {
      const address = formatAddress(data.smart_app_data.address);

      setAddress(address);
    };

    const onAddressSubmit = (address: string) => {
      const formattedAddress = formatAddress(address);

      setAddress(formattedAddress);
    };

    const onAddressChange = React.useCallback(() => {
      context.CartService.parseAddress('');

      setAddress('');

      context.AssistantService.onGoToAddress();

      context.AssistantService.openAddress();
      console.info('open address');
    }, [context.AssistantService, context.CartService]);

    const onGoToComment = React.useCallback(() => {
      context.AssistantService.onGoToComment();
    }, [context.AssistantService]);

    const onInputChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
      setInput(evt.target.value);
    };

    const onInputSubmit = React.useCallback(
      (evt: React.SyntheticEvent) => {
        if (evt.preventDefault) {
          evt.preventDefault();
        }

        if (input.length > 0) {
          waitForPromise(context.CartService.parseAddress(input));

          context.AssistantService.enterAddress();
        }
      },
      [context.AssistantService, context.CartService, input]
    );

    const onSkipAddress = React.useCallback(async () => {
      waitForPromise(context.AssistantService.onSkipAddress());
    }, [context.AssistantService]);

    const onViewChange = () => {
      clientHistory.push('/address/input');

      context.AssistantService.enterTextInput('address');
    };

    const onAddressNotFoundClick = React.useCallback(() => {
      onAddressSubmit(input);
    }, [input]);

    React.useEffect(() => {
      // on mount
      context.CartService.resetOptions();
    }, []);

    React.useEffect(() => {
      const unsubscribeFromOnVoiceAddressSubmit =
        context.SmartAppAssistantHelper.conditionalOnData(
          isSubmitAddressCommand,
          onVoiceAddressSubmit
        );

      const unsubscribeFromOnGoToInput =
        context.SmartAppAssistantHelper.conditionalOnData(
          isGoToInputCommand,
          onViewChange
        );

      const unsubscribeFromOnChangeAddress =
        context.SmartAppAssistantHelper.conditionalOnData(
          isChangeAddressCommand,
          onAddressChange
        );

      const unsubscribeFromOnAddressNotFound =
        context.SmartAppAssistantHelper.conditionalOnData(
          isAddressNotFoundCommand,
          onAddressNotFoundClick
        );

      const unsubscribeFromOnAddressSkip =
        context.SmartAppAssistantHelper.conditionalOnData(
          isSkipAddressCommand,
          onSkipAddress
        );

      const unsubscribeFromOnInputSubmit =
        context.SmartAppAssistantHelper.conditionalOnData(
          isSubmitInputCommand,
          onInputSubmit
        );

      return () => {
        unsubscribeFromOnVoiceAddressSubmit();
        unsubscribeFromOnGoToInput();
        unsubscribeFromOnChangeAddress();
        unsubscribeFromOnAddressNotFound();
        unsubscribeFromOnAddressSkip();
        unsubscribeFromOnInputSubmit();
      };
    }, [
      context.SmartAppAssistantHelper,
      onAddressNotFoundClick,
      onAddressChange,
      onViewChange,
      onVoiceAddressSubmit,
      onSkipAddress,
      onInputSubmit,
    ]);

    React.useEffect(() => {
      if (address.length > 0) {
        context.AssistantService.selectAddress(address);
      }
    }, [address]);

    React.useEffect(() => {
      context.CartService.parseAddress('');

      setAddress('');

      if (view === AddressViewEnum.voice) {
        context.AssistantService.openAddress();
      }
    }, [view]);

    React.useEffect(() => {
      if (parsedAddresses?.length === 1) {
        setAddress(parsedAddresses[0].value);
      }

      if (parsedAddresses && parsedAddresses.length === 0) {
        context.AssistantService.changeAddress();
      }

      if (parsedAddresses && parsedAddresses.length > 1) {
        context.AssistantService.parseAddresses();
      }
    }, [parsedAddresses]);

    React.useEffect(() => {
      const parsedAddress = parsedAddresses?.find(
        (currentAddress) => currentAddress.value === address
      );

      if (!address) {
        return;
      }

      context.CartService.changeCartOption({
        ['isAskAddress']: false,
      }).then(() => {
        if (parsedAddress) {
          context.CartService.changeAddress(parsedAddress);
        } else {
          context.CartService.changeAddress(address);
        }
      });
    }, [address]);

    return (
      <>
        <ApplicationHeader title={TITLE} subtitle={SUBTITLE} isBack={true}>
          {view === AddressViewEnum.voice && (
            <KeyboardInputButton onClick={onViewChange} />
          )}
        </ApplicationHeader>

        <RootWrapper>
          <Container>
            <>
              {!parsedAddresses && address.length === 0 && (
                <>
                  {view === AddressViewEnum.input && (
                    <>
                      <DeviceQuery on={[DeviceQueryEnum.mobile]}>
                        <Inner>
                          <Form onSubmit={onInputSubmit}>
                            <Input
                              placeholder={USER_ADDRESS}
                              onChange={onInputChange}
                              autoFocus
                            />
                            <GoOnButton
                              isDisabled={input.length === 0 || isProcessing}
                              isProcessing={isProcessing}
                              onClick={onInputSubmit}
                            />
                            <SkipButton
                              size="m"
                              view="secondary"
                              text={skipText}
                              stretch
                              disabled={isProcessing}
                              onClick={onSkipAddress}
                            />
                          </Form>
                        </Inner>
                      </DeviceQuery>
                      <DeviceQuery off={[DeviceQueryEnum.mobile]}>
                        <Form onSubmit={onInputSubmit}>
                          <Input
                            placeholder={USER_ADDRESS}
                            onChange={onInputChange}
                            autoFocus
                          />
                        </Form>
                      </DeviceQuery>
                    </>
                  )}

                  {view === AddressViewEnum.voice && (
                    <Inner>
                      <ApplicationAddInfoText
                        text={TEXT}
                        icon={voiceIcon}
                        alt={ADDRESS}
                      />
                      <>
                        <DeviceQuery on={[DeviceQueryEnum.mobile]}>
                          <Separator />

                          <SkipButton
                            size="m"
                            view="secondary"
                            text={skipText}
                            stretch
                            disabled={isProcessing}
                            onClick={onSkipAddress}
                          />
                        </DeviceQuery>
                        <DeviceQuery off={[DeviceQueryEnum.mobile]}>
                          <SkipButton
                            size="s"
                            autoFocus
                            view="secondary"
                            text={skipText}
                            disabled={isProcessing}
                            onClick={onSkipAddress}
                          />
                        </DeviceQuery>
                      </>
                    </Inner>
                  )}
                </>
              )}

              {parsedAddresses &&
                parsedAddresses.length > 1 &&
                address.length === 0 && (
                  <ParsedAddressesWrapper>
                    <ParsedAddressesTitle>{chooseAddress}</ParsedAddressesTitle>
                    {parsedAddresses.map((address, index) => (
                      <Address
                        address={address.value}
                        key={`${index}${address.value}`}
                        onSelect={onAddressClick}
                      />
                    ))}
                    <DeviceQuery on={[DeviceQueryEnum.mobile]}>
                      <NoAddressButton
                        size="m"
                        view="primary"
                        text={NO_ADDRESS}
                        onClick={onAddressNotFoundClick}
                      />
                    </DeviceQuery>
                    <DeviceQuery off={[DeviceQueryEnum.mobile]}>
                      <NoAddressButton
                        size="s"
                        view="primary"
                        text={NO_ADDRESS}
                        onClick={onAddressNotFoundClick}
                      />
                    </DeviceQuery>
                  </ParsedAddressesWrapper>
                )}

              {(address.length > 0 ||
                (parsedAddresses && parsedAddresses.length === 0)) && (
                <Inner>
                  {address.length > 0 && (
                    <ApplicationCardWithButtons
                      icon={flower}
                      alt={FLOWER}
                      title={USER_ADDRESS}
                      text={address}
                      onSubmit={onGoToComment}
                      onRefuse={onAddressChange}
                    />
                  )}

                  {parsedAddresses && parsedAddresses.length === 0 && (
                    <ApplicationCardWithButtons
                      icon={sadFlower}
                      alt={FLOWER}
                      extraText={notFound}
                      text={NOT_FOUND}
                      submitButtonText={CHANGE_ADDRESS}
                      onSubmit={onAddressChange}
                    />
                  )}
                </Inner>
              )}
            </>
          </Container>
        </RootWrapper>
      </>
    );
  });
