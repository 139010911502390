import {
  Button,
  mediaQuery,
  TextField,
  TextFieldProps,
} from '@salutejs/plasma-ui';
import styled from 'styled-components';
import { withAutoFocus } from '@salutejs/plasma-core/hocs';

export const AutoFocusTextField = withAutoFocus<TextFieldProps>(TextField);

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: var(--content-height);
`;

export const Form = styled.form`
  width: 100%;
  margin-top: 1.5rem;

  ${mediaQuery('S')(`
    margin-top: auto;
    margin-bottom: auto;
  `)}
`;

export const SkipButton = styled(Button)`
  margin-top: 0.75rem;
  font-size: 0.875rem;

  ${mediaQuery('S')(`
    margin-top: 1.5rem;
  `)}

  ${mediaQuery('M')(`
    min-width: 7.64rem;
  `)}

  ${mediaQuery('XL')(`
    min-width: 7.64rem;
  `)}
`;

export const Inner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Separator = styled.div`
  height: 1rem;
`;
