import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { AppContext } from 'src/lib/contexts/AppContext';
import { ApplicationHeader } from 'src/components/ApplicationHeader';
import { observer } from 'mobx-react';
import { clientHistory } from 'src/history';
import { RootWrapper } from 'src/components/RootWrapper';
import {
  CheckboxWrapper,
  Form,
  GoOnButton,
  Inner,
  Input,
  InputWrapper,
  StyledContainer,
  Text,
  Wrapper,
} from './StyledComponents';
import { ApplicationCheckbox } from 'src/components/ApplicationCheckbox';
import { isSelectFirstCheckboxCommand } from 'src/types/AssistantCommands/SmartAppDataTypeCheckers/isSelectFirstCheckboxCommand';
import { isSelectSecondCheckboxCommand } from 'src/types/AssistantCommands/SmartAppDataTypeCheckers/isSelectSecondCheckboxCommand';
import { isGoToCheckoutCommand } from 'src/types/AssistantCommands/SmartAppDataTypeCheckers/isGoToCheckoutCommand';
import { isSetUserNameCommand } from 'src/types/AssistantCommands/SmartAppDataTypeCheckers/isSetUserNameCommand';
import { isSetUserPhoneCommand } from 'src/types/AssistantCommands/SmartAppDataTypeCheckers/isSetUserPhoneCommand';
import { SetUserNameCommand } from 'src/types/AssistantCommands/SetUserNameCommand';
import { SetUserPhoneCommand } from 'src/types/AssistantCommands/SetUserPhoneCommand';
import { capitalizeFirstLetter } from 'src/utils/capitalizeFirstLetter';
import { useLoadingWrapper } from 'src/hooks/useLoadingWrapper';
import * as Yup from 'yup';
import { useFormik } from 'formik';

const TITLE = 'Ваши данные';
const SUBTITLE = 'Шаг оформления 6 из 6';
const NAME = 'Имя';
const PHONE = 'Номер телефона';
const EMAIL = 'Email';
const CALL_TO_USER = 'Свяжемся при возникновении вопросов с заказом';
const RECEIPT_INFO = 'На эту почту вышлем чек об оплате';
const SUBSCRIPTION =
  'Хочу получать персональные предложения и скидки по электронной почте';
const BUTTON_TEXT = 'Продолжить';
const REQUIRED_ERROR = 'Поле должно быть заполнено';
const FIELD_ERROR = 'Поле заполнено неверно';

export const UserInfoConfirmation: React.FunctionComponent<RouteComponentProps> =
  observer(() => {
    const context = React.useContext(AppContext);
    const positions = context.CartService.cartStore.cart.positions;
    const [isAnonymous, setIsAnonymous] = React.useState(false);
    const [isSubscribed, setIsSubscribed] = React.useState(true);

    const { waitForPromise, isLoading: isProcessing } = useLoadingWrapper();

    const inputNameRef = React.useRef<HTMLInputElement>();
    const inputPhoneRef = React.useRef<HTMLInputElement>();
    const inputEmailRef = React.useRef<HTMLInputElement>();
    const submitButtonRef = React.useRef<HTMLButtonElement>();

    const formik = useFormik({
      initialValues: {
        email: context.UserService.userStore.user.email,
        name: context.UserService.userStore.user.name,
        phone: context.UserService.userStore.user.phone,
      },
      enableReinitialize: true,
      onSubmit: async (values) => {
        onGoOnClick();
      },
      validationSchema: Yup.object({
        phone: Yup.string()
          .trim()
          .required(REQUIRED_ERROR)
          .matches(new RegExp('[0-9]{11}'), FIELD_ERROR),
        name: Yup.string().trim().required(REQUIRED_ERROR),
        email: Yup.string().trim().email(FIELD_ERROR).required(REQUIRED_ERROR),
      }),
    });

    const changeCartOptions = async () => {
      return await Promise.all([
        context.CartService.changeCartOption({
          ['isAnonym']: isAnonymous,
        }),
        context.CartService.changeCartOption({
          ['isSubscribed']: isSubscribed,
        }),
      ]);
    };

    const onIsAnonymousChange = React.useCallback(
      (evt: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = isAnonymous ? false : true;

        setIsAnonymous(newValue);
      },
      [isAnonymous]
    );

    const onIsSubscribedChange = React.useCallback(
      (evt: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = isSubscribed ? false : true;

        setIsSubscribed(newValue);
      },
      [isSubscribed]
    );

    const onSetUserName = (data: SetUserNameCommand) => {
      const name = capitalizeFirstLetter(data.smart_app_data.userName);

      formik.handleChange({
        target: {
          name: 'name',
          value: name,
        },
      });
    };

    const onSetUserPhone = (data: SetUserPhoneCommand) => {
      const phone = data.smart_app_data.userPhone;

      formik.handleChange({
        target: {
          name: 'phone',
          value: phone,
        },
      });
    };

    const onGoOnClick = React.useCallback(async () => {
      waitForPromise(changeCartOptions());

      await context.UserService.setUserInfo({
        name: formik.values.name,
        phone: formik.values.phone,
        email: formik.values.email,
      });

      context.AssistantService.onGoToCheckout();
    }, [context.AssistantService, context.UserService, formik]);

    const onKeyDown = React.useCallback(
      (event) => {
        if (event.keyCode === 13) {
          event.preventDefault();

          if (document.activeElement === inputNameRef.current) {
            inputPhoneRef.current.focus();
          } else if (document.activeElement === inputPhoneRef.current) {
            inputEmailRef.current.focus();
          } else if (document.activeElement === inputEmailRef.current) {
            submitButtonRef.current.focus();
          }
        }
      },
      [inputNameRef, inputPhoneRef, inputEmailRef, submitButtonRef]
    );

    React.useEffect(() => {
      context.AssistantService.openUserConfirmation();

      context.UserService.getUserInfo().then(() => {
        if (!context.UserService.userStore.user.name) {
          inputNameRef.current.focus();
        } else {
          submitButtonRef.current.focus();
        }
      });
    }, []);

    React.useEffect(() => {
      if (positions.length === 0) {
        clientHistory.goBack();
      }
    }, [positions]);

    React.useEffect(() => {
      const unsubscribeFromOnIsAnonimousCheckedChange =
        context.SmartAppAssistantHelper.conditionalOnData(
          isSelectFirstCheckboxCommand,
          onIsAnonymousChange
        );

      const unsubscribeFromOnIsSubscribedCheckedChange =
        context.SmartAppAssistantHelper.conditionalOnData(
          isSelectSecondCheckboxCommand,
          onIsSubscribedChange
        );

      const unsubscribeFromGoToCheckout =
        context.SmartAppAssistantHelper.conditionalOnData(
          isGoToCheckoutCommand,
          onGoOnClick
        );

      const unsubscribeFromOnSetUserName =
        context.SmartAppAssistantHelper.conditionalOnData(
          isSetUserNameCommand,
          onSetUserName
        );

      const unsubscribeFromOnSetUserPhone =
        context.SmartAppAssistantHelper.conditionalOnData(
          isSetUserPhoneCommand,
          onSetUserPhone
        );

      return () => {
        unsubscribeFromOnIsAnonimousCheckedChange();
        unsubscribeFromOnIsSubscribedCheckedChange();
        unsubscribeFromGoToCheckout();
        unsubscribeFromOnSetUserName();
        unsubscribeFromOnSetUserPhone();
      };
    }, [
      onIsAnonymousChange,
      onIsSubscribedChange,
      onGoOnClick,
      context.SmartAppAssistantHelper,
    ]);

    return (
      <>
        <ApplicationHeader title={TITLE} subtitle={SUBTITLE} isBack={true} />
        <RootWrapper>
          <StyledContainer>
            <Wrapper>
              <Form onSubmit={formik.handleSubmit}>
                <Inner>
                  <InputWrapper>
                    <Input
                      id="name"
                      helperText={
                        formik.errors.name &&
                        formik.touched.name &&
                        formik.errors.name
                      }
                      placeholder={NAME}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.name}
                      ref={inputNameRef}
                      onKeyDown={onKeyDown}
                      status={
                        formik.errors.name && formik.touched.name
                          ? 'error'
                          : undefined
                      }
                    />
                  </InputWrapper>

                  <InputWrapper>
                    <Input
                      id="phone"
                      helperText={
                        formik.errors.phone &&
                        formik.touched.phone &&
                        formik.errors.phone
                      }
                      placeholder={PHONE}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.phone}
                      ref={inputPhoneRef}
                      status={
                        formik.errors.phone && formik.touched.phone
                          ? 'error'
                          : undefined
                      }
                      onKeyDown={onKeyDown}
                    />

                    <Text>{CALL_TO_USER}</Text>
                  </InputWrapper>
                </Inner>

                <Inner>
                  <InputWrapper>
                    <Input
                      id="email"
                      helperText={
                        formik.errors.email &&
                        formik.touched.email &&
                        formik.errors.email
                      }
                      placeholder={EMAIL}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      ref={inputEmailRef}
                      status={
                        formik.errors.email && formik.touched.email
                          ? 'error'
                          : undefined
                      }
                      onKeyDown={onKeyDown}
                    />

                    <Text>{RECEIPT_INFO}</Text>
                  </InputWrapper>

                  <GoOnButton
                    type="submit"
                    size="l"
                    view="primary"
                    text={BUTTON_TEXT}
                    disabled={
                      !!formik.errors.phone ||
                      !!formik.errors.name ||
                      !!formik.errors.email ||
                      formik.isSubmitting ||
                      formik.values.phone.length === 0 ||
                      formik.values.name.length === 0 ||
                      formik.values.email.length === 0
                    }
                    ref={submitButtonRef}
                    onClick={onGoOnClick}
                  />
                </Inner>
              </Form>

              <CheckboxWrapper>
                <ApplicationCheckbox
                  width={'100%'}
                  label={SUBSCRIPTION}
                  isChecked={isSubscribed}
                  onChange={onIsSubscribedChange}
                />
              </CheckboxWrapper>
            </Wrapper>
          </StyledContainer>
        </RootWrapper>
      </>
    );
  });
