import { Button, TimePicker } from '@salutejs/plasma-ui';
import * as React from 'react';
import { DeviceQuery } from 'src/components/DeviceQuery';
import { DeviceTypeContext } from 'src/contexts/DeviceTypeContext';
import { DeviceQueryEnum } from 'src/enums/DeviceQueryEnum';
import { AppContext } from 'src/lib/contexts/AppContext';
import { SelectExactTimeCommand } from 'src/types/AssistantCommands/SelectExactTimeCommand';
import { isSelectExactTimeCommand } from 'src/types/AssistantCommands/SmartAppDataTypeCheckers/isSelectExactTimeCommand';
import { isSubmitDeliveryTimeCommand } from 'src/types/AssistantCommands/SmartAppDataTypeCheckers/isSubmitDeliveryTimeCommand';
import { Inner, TimePickerWrapper } from './StyledComponents';

const GO_ON = 'Продолжить';
const BUTTON_TEXT = 'Да, всё верно';
const TIME_PICKER_STEP = 900;
const TIME_PICKER_STEP_MINUTES = TIME_PICKER_STEP / 60;

const AutoFocusTimePicker = TimePicker;

interface Props {
  value: Date;
  onSubmit(date: string): void;
}

export const TimePickerControl = (props: Props) => {
  const context = React.useContext(AppContext);
  const deviceType = React.useContext(DeviceTypeContext);
  const isMobile = deviceType === DeviceQueryEnum.mobile;
  const buttonText = isMobile ? GO_ON : BUTTON_TEXT;

  const submitbuttonRef = React.useRef<HTMLButtonElement>();
  const exactIntervals = context.CartService.cartStore.exactDeliveryIntervals;

  const { minDate, maxDate } = React.useMemo(() => {
    const firstExactInterval = exactIntervals[0]?.date;
    const lastExactInterval = exactIntervals[exactIntervals.length - 1]?.date;

    const minDate = new Date(
      firstExactInterval?.year,
      firstExactInterval?.month - 1,
      firstExactInterval?.day,
      firstExactInterval?.hour,
      firstExactInterval?.minutes
    );
    const maxDate = new Date(
      lastExactInterval?.year,
      lastExactInterval?.month - 1,
      lastExactInterval?.day,
      lastExactInterval?.hour,
      lastExactInterval?.minutes
    );

    return { minDate, maxDate };
  }, [exactIntervals]);

  const [date, setDate] = React.useState(
    new Date(
      Math.min(
        maxDate.getTime(),
        Math.max(props.value.getTime(), minDate.getTime())
      )
    )
  );

  const onChange = React.useCallback(
    (date: Date) => {
      const tempDate = new Date(date);
      const dateMinutes = tempDate.getMinutes();
      if (dateMinutes % TIME_PICKER_STEP_MINUTES !== 0) {
        tempDate.setMinutes(Math.floor(dateMinutes / 15) * 15);
      }

      tempDate.setTime(
        Math.min(
          maxDate.getTime(),
          Math.max(tempDate.getTime(), minDate.getTime())
        )
      );

      setDate(tempDate);
    },
    [setDate, minDate, maxDate]
  );

  const onSubmit = React.useCallback(() => {
    props.onSubmit(
      `${date.getHours().toString().padStart(2, '0')}:${date
        .getMinutes()
        .toString()
        .padStart(2, '0')}`
    );
  }, [props.onSubmit, date]);

  const onKeyDown = React.useCallback(
    (event) => {
      if (event.keyCode === 13 && submitbuttonRef.current) {
        event.preventDefault();
        submitbuttonRef.current.focus();
      }

      if ([38, 40].includes(event.keyCode)) {
        event.preventDefault();
      }
    },
    [submitbuttonRef]
  );

  React.useEffect(() => {
    const unsubscribeFromExactTimeSelect =
      context.SmartAppAssistantHelper.conditionalOnData(
        isSelectExactTimeCommand,
        (data: SelectExactTimeCommand) => {
          const date = new Date(data.smart_app_data.time);
          date.setDate(props.value.getDate());
          date.setMonth(props.value.getMonth());
          date.setFullYear(props.value.getFullYear());
          onChange(date);

          context.AssistantService.enterExactTime();
        }
      );

    const unsubscribeFromDeliveryTimeSubmit =
      context.SmartAppAssistantHelper.conditionalOnData(
        isSubmitDeliveryTimeCommand,
        onSubmit
      );

    return () => {
      unsubscribeFromExactTimeSelect();
      unsubscribeFromDeliveryTimeSubmit();
    };
  }, [context.AssistantService, onSubmit]);

  return (
    <TimePickerWrapper>
      <Inner>
        <DeviceQuery on={[DeviceQueryEnum.sberPortal]}>
          <TimePicker
            options={{
              hours: true,
              minutes: true,
              seconds: false,
            }}
            step={TIME_PICKER_STEP}
            min={minDate}
            max={maxDate}
            value={date}
            visibleItems={3}
            size="s"
            infiniteScroll={false}
            onChange={onChange}
          />
        </DeviceQuery>

        <DeviceQuery off={[DeviceQueryEnum.sberPortal]}>
          <AutoFocusTimePicker
            options={{
              hours: true,
              minutes: true,
              seconds: false,
            }}
            step={TIME_PICKER_STEP}
            min={minDate}
            max={maxDate}
            value={date}
            visibleItems={3}
            size="l"
            onChange={onChange}
            onKeyDown={onKeyDown}
            infiniteScroll={false}
          />
        </DeviceQuery>
      </Inner>

      <DeviceQuery on={[DeviceQueryEnum.mobile]}>
        <Button
          text={buttonText}
          view="primary"
          size="m"
          stretch={isMobile}
          onClick={onSubmit}
          ref={submitbuttonRef}
        />
      </DeviceQuery>

      <DeviceQuery off={[DeviceQueryEnum.mobile]}>
        <Button
          text={buttonText}
          view="primary"
          size="s"
          stretch={isMobile}
          onClick={onSubmit}
          ref={submitbuttonRef}
        />
      </DeviceQuery>
    </TimePickerWrapper>
  );
};
