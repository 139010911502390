import * as React from 'react';
import { AddressText, AddressWrapper } from './StyledComponents';

export const Address: React.FunctionComponent<{
  address: string;
  onSelect: (address: string) => void;
}> = (props) => {
  const onClick = React.useCallback(() => {
    props.onSelect(props.address);
  }, [props]);

  return (
    <AddressWrapper onClick={onClick}>
      <AddressText>{props.address}</AddressText>
    </AddressWrapper>
  );
};
