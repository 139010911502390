import { inject, injectable } from 'inversify';
import { LoadingStateEnum } from 'src/types/enums/LoadingStateEnum';
import { Identifiers } from '../identifiers';
import { CategoriesProvider } from '../providers/CategoriesProvider';
import { CategoriesStore } from '../stores/CategoriesStore';
import { CategoriesServiceInterface } from '../interfaces/services/CategoriesServiceInterface';
import { ProductSortTypeEnum } from 'src/types/ProductSortTypeEnum';
import { ProductFilterType } from 'src/types/ProductFilterType';

@injectable()
export class CategoriesService implements CategoriesServiceInterface {
  @inject(Identifiers.stores.CategoriesStore)
  public categoriesStore: CategoriesStore;

  @inject(Identifiers.providers.CategoriesProvider)
  private categoriesProvider: CategoriesProvider;

  async setCategories(): Promise<void> {
    this.categoriesStore.updateState({
      loadingState: LoadingStateEnum.processing,
      categories: [],
    });

    try {
      const categories = await this.categoriesProvider.loadCategories();

      this.categoriesStore.updateState({
        loadingState: LoadingStateEnum.success,
        categories,
      });
    } catch (error) {
      console.error(error);
      this.categoriesStore.updateState({
        loadingState: LoadingStateEnum.fail,
      });
    }
  }

  async setCurrentCategory(categoryId: number): Promise<void> {
    this.categoriesStore.updateState({
      loadingState: LoadingStateEnum.processing,
      currentCategory: null,
    });

    try {
      const currentCategory = await this.categoriesProvider.loadCategory(
        categoryId
      );

      this.categoriesStore.updateState({
        currentCategory,
        loadingState: LoadingStateEnum.success,
      });
    } catch (error) {
      console.error(error);
      this.categoriesStore.updateState({
        loadingState: LoadingStateEnum.fail,
      });
    }
  }

  async setCurrentProduct(productId: number): Promise<void> {
    this.categoriesStore.updateState({
      loadingState: LoadingStateEnum.processing,
      currentProduct: null,
    });

    try {
      const currentProduct = await this.categoriesProvider.loadProduct(
        productId
      );

      this.categoriesStore.updateState({
        loadingState: LoadingStateEnum.success,
        currentProduct,
      });
    } catch (error) {
      console.error(error);
      this.categoriesStore.updateState({
        loadingState: LoadingStateEnum.fail,
      });
    }
  }

  async setCurrentProducts(
    categoryId: number,
    sortType: ProductSortTypeEnum,
    filterType: ProductFilterType
  ): Promise<void> {
    this.categoriesStore.updateState({
      loadingState: LoadingStateEnum.processing,
      currentProducts: [],
      hasMoreProducts: false,
      currentPage: 1,
      sortType,
      filterType,
      currentCategoryId: categoryId,
    });

    try {
      const { products, hasMore } = await this.categoriesProvider.loadProducts(
        categoryId,
        sortType,
        1,
        filterType
      );

      this.categoriesStore.updateState({
        loadingState: LoadingStateEnum.success,
        currentProducts: products,
        hasMoreProducts: hasMore,
      });
    } catch (error) {
      console.error(error);
      this.categoriesStore.updateState({
        loadingState: LoadingStateEnum.fail,
      });
    }
  }

  loadMoreProducts = async (): Promise<void> => {
    if (
      !this.categoriesStore.hasMoreProducts ||
      this.categoriesStore.loadMoreState === LoadingStateEnum.processing
    ) {
      return;
    }

    this.categoriesStore.updateState({
      loadMoreState: LoadingStateEnum.processing,
    });

    try {
      const nextPage = this.categoriesStore.currentPage + 1;
      const { products, hasMore } = await this.categoriesProvider.loadProducts(
        this.categoriesStore.currentCategoryId,
        this.categoriesStore.sortType,
        nextPage,
        this.categoriesStore.filterType
      );

      this.categoriesStore.updateState({
        loadMoreState: LoadingStateEnum.success,
        currentProducts: [...this.categoriesStore.currentProducts, ...products],
        hasMoreProducts: hasMore,
        currentPage: nextPage,
      });
    } catch (error) {
      this.categoriesStore.updateState({
        loadMoreState: LoadingStateEnum.fail,
      });
    }
  };

  async setFoundProducts(searchText: string): Promise<void> {
    this.categoriesStore.updateState({
      loadingState: LoadingStateEnum.processing,
      foundProducts: [],
    });

    if (searchText.length === 0) {
      return;
    }

    try {
      const foundProducts = await this.categoriesProvider.loadFoundProducts(
        searchText
      );

      this.categoriesStore.updateState({
        loadingState: LoadingStateEnum.success,
        foundProducts,
      });
    } catch (error) {
      console.error(error);
      this.categoriesStore.updateState({
        loadingState: LoadingStateEnum.fail,
      });
    }
  }

  resetSearch = async (): Promise<void> => {
    this.categoriesStore.updateState({
      loadingState: LoadingStateEnum.initial,
      foundProducts: [],
    });
  };
}
