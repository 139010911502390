import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { AppContext } from 'src/lib/contexts/AppContext';
import { ApplicationHeader } from 'src/components/ApplicationHeader';
import { isSubmitUserPhoneCommand } from 'src/types/AssistantCommands/SmartAppDataTypeCheckers/isSubmitUserPhoneCommand';
import { isChangeUserPhoneCommand } from 'src/types/AssistantCommands/SmartAppDataTypeCheckers/isChangeUserPhoneCommand';
import { SubmitUserPhoneCommand } from 'src/types/AssistantCommands/SubmitUserPhoneCommand';
import { RootWrapper } from 'src/components/RootWrapper';
import voiceIcon from 'src/assets/voice.png';
import { ApplicationAddInfoText } from 'src/components/ApplicationAddInfoText';
import { ApplicationCardWithButtons } from 'src/components/ApplicationCardWithButtons';
import { AddressViewEnum } from 'src/types/enums/AddressViewEnum';
import { clientHistory } from 'src/history';
import { KeyboardInputButton } from 'src/components/KeyboardInputButton';
import { isGoToUserInfoCommand } from 'src/types/AssistantCommands/SmartAppDataTypeCheckers/isGoToUserInfoCommand';
import { ApplicationCheckbox } from 'src/components/ApplicationCheckbox';
import { isGoToInputCommand } from 'src/types/AssistantCommands/SmartAppDataTypeCheckers/isGoToInputCommand';
import { isSelectCheckboxCommand } from 'src/types/AssistantCommands/SmartAppDataTypeCheckers/isSelectCheckboxCommand';
import { isSubmitInputCommand } from 'src/types/AssistantCommands/SmartAppDataTypeCheckers/isSubmitInputCommand';
import {
  AutoFocusTextField,
  CheckboxWrapper,
  Form,
  Inner,
} from './StyledComponents';
import { Container } from '@salutejs/plasma-ui';
import { DeviceQuery } from 'src/components/DeviceQuery';
import { DeviceQueryEnum } from 'src/enums/DeviceQueryEnum';
import { GoOnButton } from 'src/components/GoOnButton';
import { formatPhone } from 'src/utils/formatPhone';
import { useLoadingWrapper } from 'src/hooks/useLoadingWrapper';

const TITLE = 'Телефон получателя';
const SURPRISE = 'Это сюрприз — звонить получателю только при получении';
const SUBTITLE = 'Шаг оформления 5 из 6';

export const UserPhone: React.FunctionComponent<RouteComponentProps> = (
  props
) => {
  const [userPhone, setUserPhone] = React.useState('');
  const [isChecked, setIsChecked] = React.useState(false);
  const [input, setInput] = React.useState('');
  const view = props.location.pathname.includes('input')
    ? AddressViewEnum.input
    : AddressViewEnum.voice;
  const context = React.useContext(AppContext);

  const useLoading = useLoadingWrapper();
  const waitForPromise = useLoading.waitForPromise;
  const isProcessing = useLoading.isLoading;

  const changeCartOptions = async () => {
    return await Promise.all([
      await context.CartService.changeReceiverPhone(userPhone),

      await context.CartService.changeCartOption({
        ['isCallReception']: isChecked,
      }),
    ]);
  };

  const onUserPhoneSubmit = React.useCallback(
    (data: SubmitUserPhoneCommand) => {
      setUserPhone(data.smart_app_data.userPhone);

      context.CartService.changeReceiverPhone(data.smart_app_data.userPhone);
    },
    [context.CartService]
  );

  const onUserPhoneChange = React.useCallback(() => {
    setUserPhone('');

    setIsChecked(false);

    context.AssistantService.onGoToUserPhone();

    context.AssistantService.openUserPhone();
  }, [context.AssistantService]);

  const onGoToUserInfo = React.useCallback(async () => {
    waitForPromise(changeCartOptions());

    context.AssistantService.onGoToUserInfo();
  }, [context.AssistantService, context.CartService, userPhone, isChecked]);

  const onInputSubmit = React.useCallback(
    (evt?: React.SyntheticEvent) => {
      if (evt.preventDefault) {
        evt.preventDefault();
      }

      if (input.length > 0) {
        waitForPromise(context.CartService.changeReceiverPhone(input));

        setUserPhone(input);

        context.AssistantService.enterUserPhone(input);
      }
    },
    [input, context.CartService, context.AssistantService]
  );

  const onInputChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setInput(formatPhone(evt.target.value));
  };

  const onViewChange = () => {
    clientHistory.push('/phone/input');

    context.AssistantService.enterTextInput();
  };

  const onIsCheckedChange = React.useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = isChecked ? false : true;

      setIsChecked(newValue);

      context.AssistantService.enterUserPhone(userPhone);
    },
    [context.AssistantService, isChecked, userPhone]
  );

  React.useEffect(() => {
    const unsubscribeFromUserPhoneSubmit =
      context.SmartAppAssistantHelper.conditionalOnData(
        isSubmitUserPhoneCommand,
        onUserPhoneSubmit
      );

    const unsubscribeFromUserPhoneChange =
      context.SmartAppAssistantHelper.conditionalOnData(
        isChangeUserPhoneCommand,
        onUserPhoneChange
      );

    const unsubscribeFromGoToUserInfo =
      context.SmartAppAssistantHelper.conditionalOnData(
        isGoToUserInfoCommand,
        onGoToUserInfo
      );

    const unsubscribeFromOnGoToInput =
      context.SmartAppAssistantHelper.conditionalOnData(
        isGoToInputCommand,
        onViewChange
      );

    const unsubscribeFromOnIsCheckedChange =
      context.SmartAppAssistantHelper.conditionalOnData(
        isSelectCheckboxCommand,
        onIsCheckedChange
      );

    const unsubscribeFromOnInputSubmit =
      context.SmartAppAssistantHelper.conditionalOnData(
        isSubmitInputCommand,
        onInputSubmit
      );

    return () => {
      unsubscribeFromUserPhoneChange();
      unsubscribeFromUserPhoneSubmit();
      unsubscribeFromGoToUserInfo();
      unsubscribeFromOnGoToInput();
      unsubscribeFromOnIsCheckedChange();
      unsubscribeFromOnInputSubmit();
    };
  }, [
    context.SmartAppAssistantHelper,
    onIsCheckedChange,
    onViewChange,
    onGoToUserInfo,
    onUserPhoneChange,
    onUserPhoneSubmit,
    onInputSubmit,
  ]);

  React.useEffect(() => {
    context.CartService.changeReceiverPhone('');

    setUserPhone('');

    if (view === AddressViewEnum.voice) {
      context.AssistantService.openUserPhone();
    }
  }, [view]);

  return (
    <>
      <ApplicationHeader title={TITLE} subtitle={SUBTITLE} isBack={true}>
        {view === AddressViewEnum.voice && (
          <KeyboardInputButton onClick={onViewChange} />
        )}
      </ApplicationHeader>

      <RootWrapper>
        <Container>
          {view === AddressViewEnum.input && userPhone.length === 0 && (
            <>
              <DeviceQuery on={[DeviceQueryEnum.mobile]}>
                <Inner>
                  <Form onSubmit={onInputSubmit}>
                    <AutoFocusTextField
                      placeholder={TITLE}
                      value={input}
                      autoFocus
                      onChange={onInputChange}
                    />
                    <GoOnButton
                      isDisabled={input.length === 0}
                      isProcessing={isProcessing}
                      onClick={onInputSubmit}
                    />
                  </Form>
                </Inner>
              </DeviceQuery>
              <DeviceQuery off={[DeviceQueryEnum.mobile]}>
                <Form onSubmit={onInputSubmit}>
                  <AutoFocusTextField
                    placeholder={TITLE}
                    value={input}
                    autoFocus
                    onChange={onInputChange}
                  />
                </Form>
              </DeviceQuery>
            </>
          )}

          {((view === AddressViewEnum.voice && userPhone.length === 0) ||
            userPhone.length > 0) && (
            <Inner>
              {view === AddressViewEnum.voice && userPhone.length === 0 && (
                <ApplicationAddInfoText
                  text={TITLE.toLowerCase()}
                  icon={voiceIcon}
                  alt={TITLE}
                />
              )}

              {userPhone.length > 0 && (
                <>
                  <ApplicationCardWithButtons
                    title={TITLE}
                    text={userPhone}
                    isProcessing={isProcessing}
                    onSubmit={onGoToUserInfo}
                    onRefuse={onUserPhoneChange}
                  />
                  <CheckboxWrapper>
                    <ApplicationCheckbox
                      width={20}
                      label={SURPRISE}
                      isChecked={isChecked}
                      onChange={onIsCheckedChange}
                    />
                  </CheckboxWrapper>
                </>
              )}
            </Inner>
          )}
        </Container>
      </RootWrapper>
    </>
  );
};
