import { Spinner } from '@salutejs/plasma-ui/components/Spinner';
import * as React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  height: var(--content-height);
`;

export const FullScreenLoading: React.FunctionComponent = () => {
  return (
    <Wrapper>
      <Spinner />
    </Wrapper>
  );
};
