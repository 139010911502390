import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  Caption,
  HeaderSubtitle,
  HeaderTitle,
  HeaderTitleWrapper,
} from '@salutejs/plasma-ui';
import { IconCart, IconChevronLeft, IconSearch } from '@salutejs/plasma-icons';
import { clientHistory } from 'src/history';
import logo from 'src/assets/logo.png';
import list from 'src/assets/list.svg';
import { AppContext } from 'src/lib/contexts/AppContext';
import { observer } from 'mobx-react';
import {
  Badge,
  BackButton,
  CartButton,
  ContentWrapper,
  IconList,
  IconChevronDown,
  Logo,
  MobileBadge,
  MobileCartButton,
  OrdersButton,
  Wrapper,
} from './StyledComponents';
import { DeviceQuery } from '../DeviceQuery';
import { DeviceQueryEnum } from 'src/enums/DeviceQueryEnum';
import { formatPrice } from 'src/utils/formatPrice';

interface Props {
  title?: string;
  subtitle?: string;
  isBack: boolean;
  isCart?: boolean;
  isOrders?: boolean;
  isLogo?: boolean;
  isSearch?: boolean;
  isReversed?: boolean;
}

export const ApplicationHeaderComponent: React.FunctionComponent<
  Props & RouteComponentProps
> = (props) => {
  const context = React.useContext(AppContext);
  const positionsCount = context.CartService.cartStore.positionsCount;
  const cartAmount = context.CartService.cartStore.amount;

  const onGoBack = () => {
    clientHistory.goBack();
  };

  const onCartClick = () => {
    if (positionsCount > 0) {
      clientHistory.push('/cart');
    }
  };

  const onOrdersClick = () => {
    clientHistory.push('/orders');
  };

  const onSearchClick = () => {
    context.CategoriesService.setFoundProducts('');
    context.AssistantService.openSearch();
    clientHistory.push('/search');
  };

  const onClose = () => {
    context.AssistantService.close();
  };

  return (
    <>
      <Wrapper gradientColor={'#080808'}>
        {props.isBack && (
          <BackButton view="clear" outlined shiftLeft onClick={onGoBack}>
            <IconChevronLeft />
          </BackButton>
        )}

        <DeviceQuery on={[DeviceQueryEnum.mobile]}>
          {!props.isBack && (
            <BackButton view="clear" shiftLeft onClick={onClose}>
              <IconChevronDown />
            </BackButton>
          )}
        </DeviceQuery>

        {props.isLogo && <Logo src={logo} height={72} alt="Logo" />}

        <HeaderTitleWrapper>
          {props.isReversed && (
            <HeaderSubtitle>{props.subtitle}</HeaderSubtitle>
          )}
          {props.title && <HeaderTitle>{props.title}</HeaderTitle>}
          {!props.isReversed && (
            <HeaderSubtitle>{props.subtitle}</HeaderSubtitle>
          )}
        </HeaderTitleWrapper>

        <ContentWrapper>
          {props.isSearch && (
            <>
              <DeviceQuery off={[DeviceQueryEnum.sberBox]}>
                <OrdersButton
                  view="clear"
                  outlined
                  shiftRight
                  onClick={onSearchClick}
                >
                  <IconSearch size="s" />
                </OrdersButton>
              </DeviceQuery>

              <DeviceQuery on={[DeviceQueryEnum.sberBox]}>
                <OrdersButton
                  text="Поиск"
                  view="clear"
                  size="s"
                  outlined
                  contentLeft={<IconSearch size="s" />}
                  onClick={onSearchClick}
                />
              </DeviceQuery>
            </>
          )}

          {props.isOrders && (
            <>
              <DeviceQuery off={[DeviceQueryEnum.sberBox]}>
                <OrdersButton
                  view="clear"
                  outlined
                  shiftRight
                  onClick={onOrdersClick}
                >
                  <IconList src={list} />
                </OrdersButton>
              </DeviceQuery>

              <DeviceQuery on={[DeviceQueryEnum.sberBox]}>
                <OrdersButton
                  text="Заказы"
                  view="clear"
                  outlined
                  size="s"
                  contentLeft={<IconList src={list} />}
                  onClick={onOrdersClick}
                />
              </DeviceQuery>
            </>
          )}

          {props.isCart && cartAmount > 0 && (
            <>
              <DeviceQuery on={[DeviceQueryEnum.sberPortal]}>
                <CartButton
                  view="clear"
                  outlined
                  shiftRight
                  onClick={onCartClick}
                >
                  <IconCart size="s" />
                  {positionsCount > 0 && (
                    <Badge>
                      <Caption>{positionsCount}</Caption>
                    </Badge>
                  )}
                </CartButton>
              </DeviceQuery>

              <DeviceQuery on={[DeviceQueryEnum.sberBox]}>
                <CartButton
                  text="Корзина"
                  view="clear"
                  size="s"
                  outlined
                  contentLeft={
                    <>
                      <IconCart size="s" />
                      {positionsCount > 0 && (
                        <Badge>
                          <Caption>{positionsCount}</Caption>
                        </Badge>
                      )}
                    </>
                  }
                  onClick={onCartClick}
                />
              </DeviceQuery>
            </>
          )}

          {props.children}
        </ContentWrapper>
      </Wrapper>

      {props.isCart && cartAmount > 0 && (
        <DeviceQuery on={[DeviceQueryEnum.mobile]}>
          <MobileCartButton
            view="primary"
            size="m"
            text={'\u00A0\u00A0' + formatPrice(cartAmount)}
            contentLeft={<span>Корзина</span>}
            onClick={onCartClick}
          />
        </DeviceQuery>
      )}
    </>
  );
};

export const ApplicationHeader = withRouter(
  observer(ApplicationHeaderComponent)
);
