import * as React from 'react';
import { Caption, mediaQuery } from '@salutejs/plasma-ui';
import styled from 'styled-components';
import { OrderStatus as Status } from 'src/constants';

export const Wrapper = styled(Caption)<{ color: string }>`
  padding: 0.25rem 0.5rem;
  background-color: ${(props) => props.color};
  width: fit-content;
  border-radius: 1rem;

  ${mediaQuery('S')(`
    border-radius: 0.4rem;
  `)}

  ${mediaQuery('XL')(`
    padding: 0.25rem 0.625rem;
  `)}
`;

export const OrderStatus: React.FunctionComponent<{ id: number }> = (props) => {
  const text = Object.values(Status).find((key) => key.id === props.id)?.name;

  const color = Object.values(Status).find((key) => key.id === props.id)?.color;

  return <Wrapper color={color}>{text}</Wrapper>;
};
