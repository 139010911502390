import styled from 'styled-components';
import * as React from 'react';
import { colors, fonts } from '@salutejs/plasma-tokens';
import { Checkbox, mediaQuery } from '@salutejs/plasma-ui';

interface Props {
  width: number | string;
  label: string;
  isChecked: boolean;
  onChange?: (evt: React.ChangeEvent<HTMLInputElement>) => void;
}

const CheckboxWrapper = styled.div<{ width: string | number }>`
  display: flex;
  width: ${(props) =>
    typeof props.width === 'number'
      ? props.width.toString() + 'rem'
      : props.width};

  span {
    font-size: 0.75rem;
    line-height: 1rem;
    font-family: ${fonts.Bold};
    white-space: normal;
    color: ${colors.secondary};

    ${mediaQuery('S')(`
      padding-right: 2rem;
      font-size: 0.875rem;
      line-height: 1.125rem;
      font-family: ${fonts.Medium};
    `)}
  }

  label {
    align-items: center;

    div {
      min-width: 1.25rem;
    }

    ${mediaQuery('S')(`
      align-items: flex-start;
    `)}
  }
`;

export const ApplicationCheckbox: React.FunctionComponent<Props> = (props) => {
  return (
    <CheckboxWrapper width={props.width}>
      {props.onChange ? (
        <>
          <Checkbox
            label={props.label}
            checked={props.isChecked}
            tabIndex={0}
            onChange={props.onChange}
          />
        </>
      ) : (
        <Checkbox label={props.label} tabIndex={0} checked={props.isChecked} />
      )}
    </CheckboxWrapper>
  );
};
