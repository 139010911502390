import * as React from 'react';
import {
  Button,
  Footnote1,
  Headline2,
  Image,
  mediaQuery,
} from '@salutejs/plasma-ui';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;

  ${mediaQuery('S')(`
    width: 100%;
  `)}
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${mediaQuery('S')(`
    width: 100%;
  `)}
`;

export const Text = styled(Headline2)`
  text-align: center;
`;

export const Title = styled(Footnote1)`
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 0.5rem;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1.5rem;

  ${mediaQuery('S')(`
    margin-bottom: 2rem;
  `)}
`;

export const ExtraText = styled(Footnote1)`
  margin-top: 0.75rem;
  text-align: center;
`;

export const SubmitButton = styled(Button)`
  margin-right: 1rem;

  &:last-of-type {
    margin-right: 0;
  }

  ${mediaQuery('S')(`
    margin-right: 0.5rem;
    font-size: 0.875rem;
  `)}

  ${mediaQuery('M')(`
    min-width: 8.5rem;
  `)}

  ${mediaQuery('XL')(`
    min-width: 8.5rem;
  `)}
`;

export const RefuseButton = styled(Button)`
  ${mediaQuery('S')(`
    font-size: 0.875rem;
  `)}
`;

export const CardImage = styled(Image)`
  height: 3.875rem;
  width: auto;
  margin-bottom: 1rem;

  img {
    height: 100%;
  }

  ${mediaQuery('S')(`
    margin-bottom: 3rem;
  `)}
`;
