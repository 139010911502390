import * as React from 'react';
import styled from 'styled-components';
import { Button, Headline2, Footnote1, mediaQuery } from '@salutejs/plasma-ui';

const Wrapper = styled(Button)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: auto;
  margin-bottom: 0.5rem;
  padding: 1rem 1rem;
  min-width: 5.875rem;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    transform: none;
  }

  ${mediaQuery('M')(`
    align-items: center;
    padding: 0.75rem 0.5rem;
  `)}

  ${mediaQuery('XL')(`
    align-items: center;
    padding: 0.75rem 0.5rem;
  `)}
`;

const Date = styled(Headline2)`
  font-weight: bold;

  ${mediaQuery('M')(`
    font-size: 1rem;
    line-height: 1.25rem;
  `)}
`;

const Day = styled(Footnote1)`
  font-weight: 500;
`;

export const DateItem: React.FunctionComponent<{
  date: Date;
  isToday: boolean;
  isActive: boolean;
  onClick: (date: Date) => void;
}> = (props) => {
  const onClick = () => {
    props.onClick(props.date);
  };

  const isActive = props.isActive;

  const buttonRef = React.useRef<HTMLButtonElement>();

  const scrollIntoView = React.useCallback(() => {
    if (!buttonRef.current) return;

    buttonRef.current.scrollIntoView({
      behavior: 'smooth',
      inline: 'center',
      block: 'center',
    });
  }, []);

  React.useEffect(() => {
    if (!isActive) return;

    setTimeout(scrollIntoView, 500);
  }, [scrollIntoView, isActive]);

  return (
    <Wrapper
      ref={buttonRef}
      onFocus={scrollIntoView}
      onClick={onClick}
      view={props.isActive ? 'checked' : 'clear'}
    >
      <Date>
        {props.date.getDate()}
        {'\u00A0'}
        {props.date.toLocaleDateString('ru-RU', { month: 'short' }).slice(0, 3)}
      </Date>
      <Day>
        {props.isToday
          ? 'сегодня'
          : props.date.toLocaleDateString('ru-RU', { weekday: 'long' })}
      </Day>
    </Wrapper>
  );
};
