import { injectable } from 'inversify';
import { computed, makeObservable, observable } from 'mobx';
import { LoadingStateEnum } from 'src/types/enums/LoadingStateEnum';
import { OrderType } from 'src/types/OrderType';

@injectable()
export class OrdersStore {
  public orders: OrderType[] = [];
  public order: OrderType = null;
  public loadingState: LoadingStateEnum = LoadingStateEnum.initial;

  get orderByIdMap(): Map<string, OrderType> {
    const orderByIdMap = new Map();

    if (!this.orders) return orderByIdMap;

    this.orders.forEach((order) => {
      orderByIdMap.set(order.id, order);
    });

    return orderByIdMap;
  }

  constructor() {
    makeObservable(this, {
      order: observable,
      orders: observable,
      loadingState: observable,
      orderByIdMap: computed,
    });
  }
}
