import { inject, injectable } from 'inversify';
import { runInAction } from 'mobx';
import { LoadingStateEnum } from 'src/types/enums/LoadingStateEnum';
import { Identifiers } from '../identifiers';
import { OrdersProvider } from '../providers/OrdersProvider';
import { OrdersStore } from '../stores/OrdersStore';
import { OrdersServiceInterface } from '../interfaces/services/OrdersServiceInterface';

@injectable()
export class OrdersService implements OrdersServiceInterface {
  @inject(Identifiers.stores.OrdersStore) public ordersStore: OrdersStore;

  @inject(Identifiers.providers.OrdersProvider)
  private ordersProvider: OrdersProvider;

  getOrder = async (id: string) => {
    runInAction(() => {
      this.ordersStore.loadingState = LoadingStateEnum.processing;
    });

    try {
      const order = await this.ordersProvider.getOrder(id);

      runInAction(() => {
        this.ordersStore.loadingState = LoadingStateEnum.success;
        this.ordersStore.order = order;
      });
    } catch (error) {
      runInAction(() => {
        this.ordersStore.loadingState = LoadingStateEnum.fail;
      });

      throw error;
    }
  };

  getOrders = async () => {
    runInAction(() => {
      this.ordersStore.loadingState = LoadingStateEnum.processing;
    });

    try {
      const orders = await this.ordersProvider.getOrders();

      runInAction(() => {
        this.ordersStore.loadingState = LoadingStateEnum.success;
        this.ordersStore.orders = orders;
      });
    } catch (error) {
      runInAction(() => {
        this.ordersStore.loadingState = LoadingStateEnum.fail;
      });

      throw error;
    }
  };

  repeatOrder = async (orderId: string) => {
    //TODO: repeat last order
  };

  resetOrders = () => {
    runInAction(() => {
      this.ordersStore.orders = null;
      this.ordersStore.loadingState = LoadingStateEnum.initial;
    });
  };
}
