import { toTitleCase } from './toTitleCase';

export const formatAddress = (address: string): string => {
  const formattedAddress =
    'г Москва, ' +
    toTitleCase(address)
      .replace(' Улица ', 'ул ')
      .replace(' Дом ', ', д ')
      .replace('Город', '')
      .replace('Москва', '')
      .replace(' Владение ', ', вл ')
      .replace(' Строение ', ', стр ')
      .replace(' Корпус ', ', корп ')
      .replace(' Квартира ', ', кв ')
      .replace('Проспект', 'проспект')
      .replace('Шоссе', 'шоссе')
      .replace('Переулок', 'переулок')
      .replace('Проезд', 'проезд');

  return formattedAddress.replace(',  ,', ',').replace(', ,', ',');
};
