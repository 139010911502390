import styled from 'styled-components';
import {
  Button as PlasmaButton,
  Container,
  Footnote1,
  Headline2,
  mediaQuery,
} from '@salutejs/plasma-ui';
import { colors } from '@salutejs/plasma-tokens';
import { RootWrapper } from 'src/components/RootWrapper';

const imageWidth = {
  processing: 16.69,
  fail: 12.75,
  success: 13.31,
};

export const Wrapper = styled(RootWrapper)`
  position: relative;
  height: var(--content-height);
`;

export const StyledContainer = styled(Container)`
  height: var(--content-height);
  justify-content: center;

  ${mediaQuery('S')(`
    justify-content: flex-start;
    align-items: center;
  `)}

  ${mediaQuery('XL')(`
    align-items: center;
  `)}
`;

export const Title = styled(Headline2)<{ isShort?: boolean; isDone?: boolean }>`
  width: ${(props) => (props.isShort ? '10rem' : '16.5rem')};
  margin-bottom: 0.75rem;

  ${mediaQuery('S')(`
    width: 100%;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    font-size: 2rem;
    line-height: 2.25rem;
  `)}
`;

export const Text = styled(Footnote1)`
  width: 16.5rem;
  color: ${colors.secondary};

  ${mediaQuery('S')(`
    width: 100%;
    font-size: 1rem;
    line-height: 1.25rem;
    font-weight: 500;
  `)}
`;

export const ImageWrapper = styled.div<{
  type: 'processing' | 'fail' | 'success';
}>`
  width: ${(props) => imageWidth[props.type]}rem;
  margin-left: 3.25rem;

  ${mediaQuery('S')(`
    margin-left: auto;
    margin-right: auto;
    flex-grow: 1;
    display: flex;
    align-items: center;
  `)}
`;

export const Image = styled.img`
  width: 100%;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 15rem;
  margin-top: 1.5rem;

  ${mediaQuery('S')(`
    width: 100%;
  `)}
`;

export const Button = styled(PlasmaButton)<{ isLong?: boolean }>`
  font-size: 0.875rem;

  ${(props) =>
    props.isLong
      ? mediaQuery('S')('width: 100%')
      : mediaQuery('S')('width: calc(50% - 0.25rem)')}
`;

export const SpinnerWrapper = styled(RootWrapper)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: var(--content-height);
`;

export const SmallSpinnerWrapper = styled.div`
  margin-top: 0.75rem;

  ${mediaQuery('S')(`
    margin-top: 0;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  `)}
`;

export const Inner = styled.div`
  display: flex;
  align-item: center;
  justify-content: flex-stert;

  ${mediaQuery('S')(`
    flex-direction: column;
    flex-grow: 1;
  `)}
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${mediaQuery('S')(`
    flex-grow: 1;
  `)}

  ${mediaQuery('XL')(`
    flex-direction: column;
    justify-content: center;
  `)}

  ${mediaQuery('M')(`
    flex-direction: column;
    justify-content: center;
  `)}
`;

export const ToastTitle = styled.div`
  margin-right: 1rem;
`;
