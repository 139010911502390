import { observer } from 'mobx-react';
import * as React from 'react';
import { ApplicationProduct } from 'src/components/ApplicationProduct';
import { SberboxLisingShowMore } from 'src/components/SberboxListingShowMore';
import { ProductType } from 'src/types';
import { LoadingStateEnum } from 'src/types/enums/LoadingStateEnum';
import styled from 'styled-components';
import { NoProductsWrapper, StyledCarouselCol } from './StyledComponents';

interface Props {
  products: ProductType[];
  getCount(product: ProductType): number;
  hasMoreProducts: boolean;
  loadMoreProducts(): Promise<void>;
  loadMoreState: LoadingStateEnum;
}

const NO_PRODUCTS = 'Нет товаров';

const ApplicationCarousel = styled.div`
  width: 100%;
  flex: 1;
  box-sizing: border-box;
  overflow: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  height: var(--content-height);

  & > * {
    margin: 0 1rem;

    &:first-child {
      margin-left: 0;
    }
  }
`;

enum KeyboardKeys {
  ArrowRight = 'ArrowRight',
  ArrowLeft = 'ArrowLeft',
}

const allowedEvents = [KeyboardKeys.ArrowLeft, KeyboardKeys.ArrowRight];

export const SberPortalProductsList = observer((props: Props) => {
  const {
    products,
    getCount,
    hasMoreProducts,
    loadMoreProducts,
    loadMoreState,
  } = props;

  const [activeIndex, setActiveIndex] = React.useState(0);

  const carouselItems = React.useRef([]);

  const onLoadMore = React.useCallback(() => {
    loadMoreProducts();

    if (carouselItems.current[carouselItems.current.length - 1]) {
      carouselItems.current[carouselItems.current.length - 1].focus();
    }
  }, [loadMoreProducts]);

  return (
    <ApplicationCarousel>
      {products.length > 0 ? (
        <>
          {products.map((product, index) => (
            <ApplicationProduct
              key={product.id}
              product={product}
              count={getCount(product)}
              ref={(element) => (carouselItems.current[index] = element)}
              onFocus={(event) => {
                event.target.scrollIntoView({
                  behavior: 'smooth',
                  inline: 'center',
                  block: 'center',
                });
              }}
            />
          ))}
          {hasMoreProducts && (
            <SberboxLisingShowMore
              onClick={onLoadMore}
              isLoading={loadMoreState === LoadingStateEnum.processing}
              onFocus={(event) => {
                event.target.scrollIntoView({
                  behavior: 'smooth',
                  inline: 'center',
                  block: 'center',
                });
              }}
            />
          )}
        </>
      ) : (
        <NoProductsWrapper>{NO_PRODUCTS}</NoProductsWrapper>
      )}
    </ApplicationCarousel>
  );
});
