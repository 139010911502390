import { GenericSmartAppDataCommand } from '../GenericSmartAppDataCommand';
import { MakeSearchCommand } from '../MakeSearchCommand';

export function isMakeSearchCommand(
  command: GenericSmartAppDataCommand
): command is MakeSearchCommand {
  console.info(
    command,
    command?.smart_app_data?.action === 'makeSearch' &&
      !!command?.smart_app_data?.searchText
  );
  return (
    command?.smart_app_data?.action === 'makeSearch' &&
    !!command?.smart_app_data?.searchText
  );
}
