import { Button, ButtonProps } from '@salutejs/plasma-ui';
import * as React from 'react';
import { screenResolution } from 'src/constants';
import { DeviceTypeContext } from 'src/contexts/DeviceTypeContext';
import { DeviceQueryEnum } from 'src/enums/DeviceQueryEnum';
import flowersImage from './onboardingImage.png';
import flowersImageM from './onboardingImage.png';
import flowersImageL from './onboardingImage@2x.png';
import flowersImageXL from './onboardingImage@2x.png';
import flowersImageMobile from './onboardingImageMobile.png';
import flowersImageMobileM from './onboardingImageMobile.png';
import flowersImageMobileL from './onboardingImageMobile@2x.png';
import flowersImageMobileXL from './onboardingImageMobile@2x.png';
import {
  ButtonsWrapper,
  Image,
  ButtonsWrapperMobile,
  Separator,
  Title,
  TitleMobile,
  Text,
  TextMobile,
  Wrapper,
} from './StyledComponents';
import { withAutoFocus } from '@salutejs/plasma-core/hocs';
import { DeviceQuery } from 'src/components/DeviceQuery';

const AutoFocusButton = withAutoFocus(Button);

const TITLE = 'Доставка цветов AMF';
const TEXT =
  'Каким бы ни был повод, можно смело обратиться в международную сеть AMF. Мы предлагаем нашим клиентам:';
const TEXT_MOBILE =
  'Мы предлагаем нашим клиентам: фирменный каталог продукции премиум-класса; создание, оформление букетов; бонусы в виде накопительных баллов и выгодные спецпредложения.';
const items = [
  '• фирменный каталог продукции премиум-класса;',
  '• создание, оформление букетов;',
  '• бонусы в виде накопительных баллов и выгодные спецпредложения.',
];
const GO_TO_APP = 'Хочу заказать';
const ABOUT = 'Где мы работаем';
const ALT = 'Цветы';

export const Onboarding: React.FunctionComponent<{
  onGoToAppClick: () => void;
  onGoToCompanyInfoClick: () => void;
}> = (props) => {
  const deviceType = React.useContext(DeviceTypeContext);
  const isMobile = deviceType === DeviceQueryEnum.mobile;

  return (
    <Wrapper>
      <DeviceQuery on={[DeviceQueryEnum.mobile]}>
        <Image
          src={flowersImageMobile}
          srcSet={`${flowersImageMobile} ${screenResolution.s}w, ${flowersImageMobileM} ${screenResolution.m}w, ${flowersImageMobileL} ${screenResolution.l}w, ${flowersImageMobileXL} ${screenResolution.xl}w`}
          alt={ALT}
        />
      </DeviceQuery>

      <DeviceQuery on={[DeviceQueryEnum.mobile]}>
        <TitleMobile>{TITLE}</TitleMobile>
      </DeviceQuery>

      <DeviceQuery on={[DeviceQueryEnum.mobile]}>
        <TextMobile>{TEXT_MOBILE}</TextMobile>
      </DeviceQuery>

      <DeviceQuery off={[DeviceQueryEnum.mobile]}>
        <Title>{TITLE}</Title>
      </DeviceQuery>

      <DeviceQuery off={[DeviceQueryEnum.mobile]}>
        <Text>{TEXT}</Text>
      </DeviceQuery>

      <DeviceQuery off={[DeviceQueryEnum.mobile]}>
        {items.map((item, index) => (
          <Text key={index}>{item}</Text>
        ))}
      </DeviceQuery>

      <DeviceQuery on={[DeviceQueryEnum.mobile]}>
        <ButtonsWrapperMobile>
          <AutoFocusButton
            size="m"
            view="primary"
            text={GO_TO_APP}
            stretch
            onClick={props.onGoToAppClick}
          />

          <Separator />

          <Button
            size="m"
            view="secondary"
            text={ABOUT}
            stretch
            onClick={props.onGoToCompanyInfoClick}
          />
        </ButtonsWrapperMobile>
      </DeviceQuery>

      <DeviceQuery off={[DeviceQueryEnum.mobile]}>
        <ButtonsWrapper>
          <AutoFocusButton
            size="s"
            view="primary"
            text={GO_TO_APP}
            tabIndex={0}
            autoFocus
            onClick={props.onGoToAppClick}
          />

          <Separator />

          <Button
            size="s"
            view="secondary"
            text={ABOUT}
            tabIndex={1}
            onClick={props.onGoToCompanyInfoClick}
          />
        </ButtonsWrapper>
      </DeviceQuery>

      <DeviceQuery off={[DeviceQueryEnum.mobile]}>
        <Image
          src={flowersImage}
          srcSet={`${flowersImage} ${screenResolution.s}w, ${flowersImageM} ${screenResolution.m}w, ${flowersImageL} ${screenResolution.l}w, ${flowersImageXL} ${screenResolution.xl}w`}
          alt={ALT}
        />
      </DeviceQuery>
    </Wrapper>
  );
};
