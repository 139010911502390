import { inject, injectable } from 'inversify';
import { Identifiers } from '../identifiers';
import { UserStore } from '../stores/UserStore';
import axios, { AxiosRequestConfig } from 'axios';
import { AxiosResponse } from 'axios';
import { IDBStorageHelper } from '../helpers/IDBStorageHelper';
import * as Sentry from '@sentry/browser';
import { v4 as createUuid } from 'uuid';
@injectable()
export class ApiRequestHelper {
  @inject(Identifiers.stores.UserStore)
  private userStore: UserStore;

  @inject(Identifiers.helpers.StorageHelper)
  private storageHelper: IDBStorageHelper;

  static uuidStorageKey = 'userUuid';
  private readonly defaultRetryCount = 3;

  makeAuthorizedRequest = async (
    url: string,
    data?: any,
    retry = this.defaultRetryCount,
    axiosConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> => {
    const token = this.userStore.token;

    const apiKey = process.env.REACT_APP_API_KEY;
    const sub = this.userStore.id;
    const headers = {
      'X-Client-Type': process.env.REACT_APP_USER_AGENT,
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'X-Catalog-Region': '187',
      'Accept-language': 'ru',
      'X-Device-Id': sub,
      'Api-key': apiKey,
    };

    let response;
    try {
      response = await axios({
        url,
        method: data ? 'post' : 'get',
        data,
        headers,
        ...(axiosConfig || {}),
      });
    } catch (error: any) {
      console.error(error);
      const err = new Error(
        `${this.constructor.name} authorized request to ${url} failed with "${error.message}"`
      );
      Sentry.captureException(err);
      throw err;
    }

    if (response.status !== 401) {
      return response;
    }

    if (retry === 0) {
      console.warn('Too many UNAUTHORIZED requests');

      return response;
    }

    return this.makeAuthorizedRequest(url, data, retry - 1);
  };

  private async getUserUuid(): Promise<string> {
    let uuid = await this.getUserUuidFromStorege();

    if (uuid.length === 0) {
      uuid = createUuid();
      this.setUserUuidToStorage(uuid);
    }

    return uuid;
  }

  private async getUserUuidFromStorege(): Promise<string> {
    const storedUserUuid: string = await this.storageHelper.getItem(
      ApiRequestHelper.uuidStorageKey
    );
    if (!storedUserUuid) return '';

    try {
      return JSON.parse(storedUserUuid);
    } catch (error) {
      return '';
    }
  }

  private async setUserUuidToStorage(uuid: string): Promise<void> {
    try {
      this.storageHelper.setItem(
        ApiRequestHelper.uuidStorageKey,
        JSON.stringify(uuid)
      );
    } catch (error) {
      console.error(error);
    }
  }
}
