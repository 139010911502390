import * as React from 'react';
import { colors } from '@salutejs/plasma-tokens';
import { ProductFilterType } from 'src/types/ProductFilterType';
import { SortOptionType } from 'src/types/SortOptionType';
import { ProductSortTypeEnum } from 'src/types/ProductSortTypeEnum';
import { IconArrowUp, IconArrowDown } from '@salutejs/plasma-icons';
import {
  OptionItemInner,
  OptionItemWrapper,
  OptionItemText,
  OptionItemSeparator,
} from './StyledComponents';
import { CategoryType } from 'src/types';
import { DeviceQueryEnum } from 'src/enums/DeviceQueryEnum';
import { DeviceTypeContext } from 'src/contexts/DeviceTypeContext';

const PRICE = 'по цене';

export const OptionItem: React.FunctionComponent<{
  text: string;
  value: ProductFilterType | SortOptionType | CategoryType;
  isSelected: boolean;
  onChange: (value: ProductFilterType | SortOptionType | CategoryType) => void;
}> = (props) => {
  const deviceType = React.useContext(DeviceTypeContext);
  const isMobile = deviceType === DeviceQueryEnum.mobile;

  const onChange = () => {
    props.onChange(props.value);
  };

  const text = () => {
    if ('value' in props.value) {
      if (
        [ProductSortTypeEnum.priceUp, ProductSortTypeEnum.time].includes(
          props.value.value
        )
      ) {
        return (
          <OptionItemInner>
            {props.text}
            <OptionItemSeparator />
            <IconArrowUp
              size="xs"
              color={props.isSelected ? 'black' : colors.buttonWhite}
            />
          </OptionItemInner>
        );
      } else if (
        [
          ProductSortTypeEnum.priceDown,
          ProductSortTypeEnum.timeReverse,
        ].includes(props.value.value)
      ) {
        return (
          <OptionItemInner>
            {props.text}
            <OptionItemSeparator />
            <IconArrowDown
              size="xs"
              color={props.isSelected ? 'black' : colors.buttonWhite}
            />
          </OptionItemInner>
        );
      } else {
        return props.text;
      }
    } else {
      return props.text;
    }
  };

  return (
    <OptionItemWrapper
      isSelected={props.isSelected}
      isMobile={isMobile}
      onClick={onChange}
    >
      <OptionItemText isSelected={props.isSelected}>{text()}</OptionItemText>
    </OptionItemWrapper>
  );
};
