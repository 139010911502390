import { Container } from 'inversify';
import { PubsubHelper } from './helpers/PubsubHelper';
import { IDBStorageHelper } from './helpers/IDBStorageHelper';
import { SmartAppAssistantHelper } from './helpers/SmartAppAssistantHelper';
import { Identifiers } from './identifiers';
import { AppInitializationService } from './services/AppInitializationService';
import { AssistantService } from './services/AssistantService';
import { CategoriesService } from './services/CategoriesService';
import { CategoriesStore } from './stores/CategoriesStore';
import { CategoriesProvider } from './providers/CategoriesProvider';
import { CartService } from './services/CartService';
import { CartStore } from './stores/CartStore';
import { CartProvider } from './providers/CartProvider';
import { OrdersService } from './services/OrdersService';
import { OrdersStore } from './stores/OrdersStore';
import { OrdersProvider } from './providers/OrdersProvider';
import { UserService } from './services/UserService';
import { UserStore } from './stores/UserStore';
import { ApiRequestHelper } from './helpers/ApiRequestHelper';
import { UserServiceInterface } from './interfaces/services/UserServiceInterface';
import { OrdersProviderInterface } from './interfaces/providers/OrdersProviderInterface';
import { OrdersServiceInterface } from './interfaces/services/OrdersServiceInterface';
import { CartProviderInterface } from './interfaces/providers/CartProviderInterface';
import { CartServiceInterface } from './interfaces/services/CartServiceInterface';
import { CategoriesProviderInterface } from './interfaces/providers/CategoriesProviderInterface';
import { CategoriesServiceInterface } from './interfaces/services/CategoriesServiceInterface';
import { AssistantServiceInterface } from './interfaces/services/AssistantServiceInterface';
import { AppInitializationServiceInterface } from './interfaces/services/AppInitializationServiceInterface';

export const createContainer = (): Container => {
  const container = new Container();

  container
    .bind<PubsubHelper>(Identifiers.helpers.PubsubHelper)
    .to(PubsubHelper)
    .inSingletonScope();

  container
    .bind<SmartAppAssistantHelper>(Identifiers.helpers.SmartAppAssistantHelper)
    .to(SmartAppAssistantHelper)
    .inSingletonScope();

  container
    .bind<IDBStorageHelper>(Identifiers.helpers.StorageHelper)
    .to(IDBStorageHelper)
    .inSingletonScope();

  container
    .bind<ApiRequestHelper>(Identifiers.helpers.ApiRequestHelper)
    .to(ApiRequestHelper)
    .inSingletonScope();

  container
    .bind<AppInitializationServiceInterface>(
      Identifiers.services.AppInitializationService
    )
    .to(AppInitializationService)
    .inSingletonScope();

  container
    .bind<AssistantServiceInterface>(Identifiers.services.AssistantService)
    .to(AssistantService)
    .inSingletonScope();

  container
    .bind<CategoriesServiceInterface>(Identifiers.services.CategoriesService)
    .to(CategoriesService)
    .inSingletonScope();

  container
    .bind<CategoriesStore>(Identifiers.stores.CategoriesStore)
    .to(CategoriesStore);

  container
    .bind<CategoriesProviderInterface>(Identifiers.providers.CategoriesProvider)
    .to(CategoriesProvider)
    .inSingletonScope();

  container
    .bind<CartServiceInterface>(Identifiers.services.CartService)
    .to(CartService)
    .inSingletonScope();

  container.bind<CartStore>(Identifiers.stores.CartStore).to(CartStore);

  container
    .bind<CartProviderInterface>(Identifiers.providers.CartProvider)
    .to(CartProvider)
    .inSingletonScope();

  container
    .bind<OrdersServiceInterface>(Identifiers.services.OrdersService)
    .to(OrdersService)
    .inSingletonScope();

  container.bind<OrdersStore>(Identifiers.stores.OrdersStore).to(OrdersStore);

  container
    .bind<OrdersProviderInterface>(Identifiers.providers.OrdersProvider)
    .to(OrdersProvider)
    .inSingletonScope();

  container
    .bind<UserServiceInterface>(Identifiers.services.UserService)
    .to(UserService)
    .inSingletonScope();

  container
    .bind<UserStore>(Identifiers.stores.UserStore)
    .to(UserStore)
    .inSingletonScope();

  return container;
};
