import { inject, injectable } from 'inversify';
import { ProductType } from 'src/types';
import { CategoryType } from 'src/types/CategoryType';
import { Identifiers } from '../identifiers';
import { CategoriesProviderInterface } from '../interfaces/providers/CategoriesProviderInterface';
import { ApiRequestHelper } from '../helpers/ApiRequestHelper';
import { ProductSortTypeEnum } from 'src/types/ProductSortTypeEnum';
import { ProductFilterType } from 'src/types/ProductFilterType';

const perPageLimit = 20;
@injectable()
export class CategoriesProvider implements CategoriesProviderInterface {
  @inject(Identifiers.helpers.ApiRequestHelper)
  private apiRequestHelper: ApiRequestHelper;

  async loadCategories(): Promise<CategoryType[]> {
    try {
      const url = `${process.env.REACT_APP_API_URL}/api/v1/catalog/sber-categories`;
      const response = await this.apiRequestHelper.makeAuthorizedRequest(url);

      return response.data.data as CategoryType[];
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  async loadCategory(categoryId: number): Promise<CategoryType> {
    try {
      const url = `${process.env.REACT_APP_API_URL}/api/v1/catalog/sber-categories/${categoryId}`;
      const response = await this.apiRequestHelper.makeAuthorizedRequest(url);

      return response.data.data as CategoryType;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  async loadProducts(
    categoryId: number,
    sortType: ProductSortTypeEnum = ProductSortTypeEnum.default,
    page: number,
    filterType?: ProductFilterType
  ): Promise<{ products: ProductType[]; hasMore: boolean }> {
    try {
      let sort;

      if (
        sortType === ProductSortTypeEnum.time ||
        sortType === ProductSortTypeEnum.timeReverse
      ) {
        sort =
          sortType === ProductSortTypeEnum.time
            ? 'time_create'
            : '-time_create';
      } else if (
        sortType === ProductSortTypeEnum.priceDown ||
        sortType === ProductSortTypeEnum.priceUp
      ) {
        sort = sortType === ProductSortTypeEnum.priceDown ? 'price' : '-price';
      } else {
        sort = sortType;
      }
      console.info({ sortType, sort });

      const filterPath = filterType
        ? filterType.highest
          ? `&lowest_price=${filterType.lowest}&highest_price=${filterType.highest}`
          : `&lowest_price=${filterType.lowest}`
        : '';
      const url = `${process.env.REACT_APP_API_URL}/api/v1/catalog/categories/${categoryId}/products?limit=${perPageLimit}&page=${page}&sort=${sort}${filterPath}`;
      const response = await this.apiRequestHelper.makeAuthorizedRequest(url);

      console.info(url);

      const products = response.data.data.items as ProductType[];

      return {
        products,
        hasMore: !!response.data.data.pagination.next_page,
      };
    } catch (error) {
      console.error(error);
      return {
        products: [],
        hasMore: false,
      };
    }
  }

  async loadFoundProducts(searchText: string): Promise<ProductType[]> {
    try {
      const url = `${process.env.REACT_APP_API_URL}/api/v1/catalog/sber-search?query=${searchText}&limit=10000&page=1&sort=default`;
      console.info(url);
      const response = await this.apiRequestHelper.makeAuthorizedRequest(url);

      return response.data.data.items as ProductType[];
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  async loadProduct(productId: number): Promise<ProductType> {
    try {
      const url = `${process.env.REACT_APP_API_URL}/api/v1/catalog/products/${productId}`;
      const response = await this.apiRequestHelper.makeAuthorizedRequest(url);

      console.info(url);

      return response.data.data as ProductType;
    } catch (error) {
      console.error(error);
      return null;
    }
  }
}
