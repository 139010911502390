import * as React from 'react';
import { SberCharacterEnum } from 'src/types/enums/SberCharacterEnum';

export interface AssistantContextType {
  character: SberCharacterEnum;
}

export const AssistantContext = React.createContext<AssistantContextType>({
  character: null,
});
