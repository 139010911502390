import {
  Button,
  Container,
  mediaQuery,
  TextField,
  TextFieldProps,
} from '@salutejs/plasma-ui';
import styled from 'styled-components';
import { withAutoFocus } from '@salutejs/plasma-core/hocs';

export const AutoFocusTextField = withAutoFocus<TextFieldProps>(TextField);

export const FlexContainer = styled(Container)`
  flex-grow: 1;
`;

export const Inner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: var(--content-height);
`;

export const Form = styled.form`
  width: 100%;
  margin-top: 1.5rem;

  ${mediaQuery('S')(`
    margin-top: auto;
    margin-bottom: auto;
  `)}
`;

export const CheckboxWrapper = styled.div`
  margin-top: 1.5rem;

  ${mediaQuery('S')(`
    margin-top: 2rem;
  `)}
`;
