import { injectable } from 'inversify';
import { action, makeObservable, observable } from 'mobx';
import { LoadingStateEnum } from 'src/types/enums/LoadingStateEnum';
import { UserInfoType } from 'src/types/UserInfoType';
import { AbstractStore } from './AbstractStore';

@injectable()
export class UserStore extends AbstractStore {
  public token = '';
  public id = '';
  public loadingState: LoadingStateEnum = LoadingStateEnum.initial;
  public user: UserInfoType = { name: '', phone: '', email: '' };

  constructor() {
    super();
    makeObservable(this, {
      token: observable,
      loadingState: observable,
      updateState: action,
      user: observable,
      id: observable,
    });
  }
}
