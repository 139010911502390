import * as React from 'react';
import { OrderType } from 'src/types/OrderType';
import {
  Address,
  Delivery,
  Link,
  OrderImage,
  OrderNumber,
  OrderWrapper,
  OrderPrice,
  OrderInnerRow,
  OrderTitleCol,
  OrderPriceMobile,
  OrderNumberMobile,
  OrderMobileText,
  OrderLink,
} from './StyledComponents';
import { Body1, Price } from '@salutejs/plasma-ui';
import { OrderStatus } from 'src/components/OrderStatus';
import { DeviceQuery } from 'src/components/DeviceQuery';
import { DeviceQueryEnum } from 'src/enums/DeviceQueryEnum';
import { ASK_ADDRESS } from 'src/constants';
import placeholder from '../../assets/empty.svg';
import { useScrollIntoView } from 'src/hooks/useScrollintoView';

const ORDER = 'Заказ № ';

export const Order: React.FunctionComponent<{ order: OrderType }> = (props) => {
  const positions = props.order.items;
  const order = props.order;
  const address = order.address ? order.address : ASK_ADDRESS;
  const onFocus = useScrollIntoView();

  return (
    <OrderLink to={`/order/${order.id}`} key={order.id} onFocus={onFocus}>
      <OrderWrapper>
        <OrderInnerRow>
          <OrderImage
            src={positions[0].images2[0].lowres_url || placeholder}
            alt={positions[0].title}
          />
          <OrderTitleCol>
            <DeviceQuery off={[DeviceQueryEnum.mobile]}>
              <OrderNumber>
                {ORDER}
                {props.order.title}
              </OrderNumber>

              <OrderPrice>
                <Price>{props.order.sum}</Price>
              </OrderPrice>
            </DeviceQuery>

            <DeviceQuery on={[DeviceQueryEnum.mobile]}>
              <OrderNumberMobile>
                {ORDER}
                {props.order.title}
              </OrderNumberMobile>

              <OrderPriceMobile>
                <Price>{props.order.sum}</Price>
              </OrderPriceMobile>
            </DeviceQuery>
          </OrderTitleCol>
        </OrderInnerRow>

        <DeviceQuery off={[DeviceQueryEnum.mobile]}>
          <Address>{address}</Address>
          <Delivery>{order.delivery_datetime}</Delivery>
        </DeviceQuery>

        <DeviceQuery on={[DeviceQueryEnum.mobile]}>
          <OrderMobileText>{address}</OrderMobileText>
          <OrderMobileText>{order.delivery_datetime}</OrderMobileText>
        </DeviceQuery>

        <OrderStatus id={props.order.status_code} />
      </OrderWrapper>
    </OrderLink>
  );
};
