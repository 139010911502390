import * as React from 'react';
import { CategoryType } from 'src/types';
import { ProductFilterType } from 'src/types/ProductFilterType';
import { SortOptionType } from 'src/types/SortOptionType';
import { OptionsTitle, OptionItemsWrapper } from './StyledComponents';
import { OptionItem } from './OptionItem';
import { OptionsButton } from './OptionsButton';

const SORTING = 'Сортировка';

export const SortingOptions: React.FunctionComponent<{
  option: SortOptionType;
  isShowMore: boolean;
  isMobile: boolean;
  isTitleShown: boolean;
  items: SortOptionType[];
  onChange: (option: CategoryType | ProductFilterType | SortOptionType) => void;
  onShowMoreClick: () => void;
}> = (props) => {
  return (
    <>
      {props.isTitleShown && <OptionsTitle>{SORTING}</OptionsTitle>}
      <OptionItemsWrapper>
        {props.items.map((option, index) => {
          return (
            <OptionItem
              key={index}
              text={option.label}
              value={option}
              isSelected={
                JSON.stringify(props.option) === JSON.stringify(option)
              }
              onChange={props.onChange}
            />
          );
        })}
        {props.isShowMore && props.isMobile && (
          <OptionsButton
            isUp={props.isShowMore}
            onClick={props.onShowMoreClick}
          />
        )}
      </OptionItemsWrapper>
    </>
  );
};
