import { injectable } from 'inversify';
import { computed, makeObservable, observable } from 'mobx';
import { LoadingStateEnum } from 'src/types/enums/LoadingStateEnum';
import { CartType } from 'src/types/CartType';
import { ParsedAddress } from 'src/types/ParsedAddress';
import { DeliveryIntervalType } from 'src/types/DeliveryIntervalType';
import { EnabledExactDeliveryIntervalType } from 'src/types/EnabledExactDeliveryIntervalType';
import { ProductType } from 'src/types';

@injectable()
export class CartStore {
  public cart: CartType = null;
  public cartValidationError: string = null;
  public deliveryCost: number = null;
  public exactDeliveryCost: number = null;
  public loadingState: LoadingStateEnum = LoadingStateEnum.initial;
  public currentProccessingOrderId: string = null;
  public currentProccessingClientOrderId: string = null;
  public processingCartPositions: Map<number, true> = new Map();
  public parsedAddresses: ParsedAddress[] = null;
  public deliveryIntervals: DeliveryIntervalType[] = [];
  public exactDeliveryIntervals: EnabledExactDeliveryIntervalType[] = [];
  public deliberyIntervalsError: string;
  public shouldNavigateToCheckout = false;

  get positionsCount(): number {
    if (!this.cart) return 0;

    let count = 0;

    this.cart.positions.forEach((position) => {
      count += position.count;
    });

    return count;
  }

  get countByPositionIdMap(): Map<number, number> {
    const countByMenuIdMap = new Map();

    if (!this.cart) return countByMenuIdMap;

    this.cart.positions.forEach((position) => {
      const storedCount = countByMenuIdMap.get(position.id) || 0;

      countByMenuIdMap.set(position.id, storedCount + position.count);
    });

    return countByMenuIdMap;
  }

  get intervalDescriptionByIdMap(): Map<string, string> {
    const intervalDescriptionByIdMap = new Map();

    if (!this.deliveryIntervals) return intervalDescriptionByIdMap;

    this.deliveryIntervals.forEach((intervals) => {
      intervals.intervals.forEach((interval) => {
        intervalDescriptionByIdMap.set(
          interval.id,
          interval.description.replace('Доставка с ', '').replace(' до ', '-')
        );
      });
    });

    return intervalDescriptionByIdMap;
  }

  get productByMenuIdMap(): Map<number, ProductType> {
    const productByMenuIdMap = new Map();

    if (!this.cart) return productByMenuIdMap;

    this.cart.positions.forEach((position) => {
      productByMenuIdMap.set(position.id, position.item);
    });

    return productByMenuIdMap;
  }

  get deliveryIntervalByDateMap(): Map<string, DeliveryIntervalType> {
    const deliveryIntervalByDateMap = new Map();

    if (!this.cart) return deliveryIntervalByDateMap;

    this.deliveryIntervals.forEach((interval) => {
      deliveryIntervalByDateMap.set(interval.date, interval);
    });

    return deliveryIntervalByDateMap;
  }

  get deliveryCostCalculated(): {
    deliveryCost: number;
    intervalAdditionalCost: number;
    exactIntervalAdditionalCost: number;
  } {
    const zeroResponse = {
      deliveryCost: 0,
      intervalAdditionalCost: 0,
      exactIntervalAdditionalCost: 0,
    };

    if (this.deliveryIntervals.length === 0) {
      return zeroResponse;
    }

    let date: string;
    let intervalAdditionalCost = 0;
    let isExactInterval = false;

    if (!this.cart.delivery) {
      date = this.deliveryIntervals[0].date;
    } else if (!this.cart.delivery.intervalId) {
      // exact date interval "12.12.2021 12:12" split into two parts ang take first
      const dates = this.cart.delivery.date.split(' ');
      isExactInterval = true;
      date = dates[0];
    } else {
      const existingDateIndex = this.deliveryIntervals.findIndex(
        (interval) => interval.date === this.cart.delivery.date
      );
      if (existingDateIndex !== -1) {
        date = this.cart.delivery.date;
        const interval = this.deliveryIntervals[existingDateIndex];
        const foundInterval = interval.intervals.find(
          (interval) => parseInt(interval.id) === this.cart.delivery.intervalId
        );

        if (foundInterval) {
          intervalAdditionalCost = foundInterval.price;
        }
      } else {
        date = this.deliveryIntervals[0].date;
      }
    }

    const foundIntervalDate = this.deliveryIntervals.find(
      (interval) => interval.date === date
    );

    if (!foundIntervalDate) return zeroResponse;

    const courierCost =
      foundIntervalDate.deliveries.find(
        (delivery) => delivery.key_id === 'courier'
      ).delivery_services?.[0]?.price || 0;

    let extraCost = 0;

    if (isExactInterval) {
      extraCost = foundIntervalDate.exact_delivery_time.price;
    }

    return {
      deliveryCost: courierCost,
      intervalAdditionalCost,
      exactIntervalAdditionalCost: extraCost,
    };
  }

  get amount(): number {
    return (this.cart?.positions || []).reduce((total, position) => {
      const positionTotal =
        (position.item.prices.find((price) => price.id === position.id)
          ?.price || 0) * position.count;
      return total + positionTotal;
    }, 0);
  }

  constructor() {
    makeObservable(this, {
      cart: observable,
      cartValidationError: observable,
      deliveryCost: observable,
      exactDeliveryCost: observable,
      deliveryIntervals: observable,
      exactDeliveryIntervals: observable,
      loadingState: observable,
      parsedAddresses: observable,
      currentProccessingOrderId: observable,
      currentProccessingClientOrderId: observable,
      processingCartPositions: observable,
      deliberyIntervalsError: observable,
      shouldNavigateToCheckout: observable,
      positionsCount: computed,
      countByPositionIdMap: computed,
      intervalDescriptionByIdMap: computed,
      deliveryIntervalByDateMap: computed,
      productByMenuIdMap: computed,
      deliveryCostCalculated: computed,
      amount: computed,
    });
  }
}
