import * as React from 'react';
import { OptionsButtonWrapper } from './StyledComponents';
import { IconDisclosureUp, IconDisclosureDown } from '@salutejs/plasma-icons';

export const OptionsButton: React.FunctionComponent<{
  isUp: boolean;
  onClick: () => void;
}> = (props) => {
  return (
    <OptionsButtonWrapper view="secondary" size="s" onClick={props.onClick}>
      {props.isUp ? <IconDisclosureUp /> : <IconDisclosureDown />}
    </OptionsButtonWrapper>
  );
};
