import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { FullScreenLoading } from './components/FullScreenLoading';
import { Cart } from './screens/Cart';
import { Category } from './screens/Category';
import { DeliveryAddress } from './screens/DeliveryAddress';
import { DeliveryTime } from './screens/DeliveryTime';
import { UserInfoConfirmation } from './screens/UserInfoConfirmation';
import { Main } from './screens/Main';
import { NotFound } from './screens/NotFound';
import { Order } from './screens/Order';
import { Orders } from './screens/Orders';
import { Product } from './screens/Product';
import { Search } from './screens/Search';
import { UserName } from './screens/UserName';
import { UserPhone } from './screens/UserPhone';
import { DeliveryComment } from './screens/DeliveryComment';
import { TermsOfService } from './screens/TermsOfService';
import { Checkout } from './screens/Checkout';
import { Payment } from './screens/Payment';
import { About } from './screens/About';
import { PrivacyPolicy } from './screens/PrivacyPolicy';
import { TestScreen } from './screens/TestScreen';

export const Root: React.FunctionComponent<{ isLoading: boolean }> = (
  props
) => {
  if (props.isLoading) {
    return <FullScreenLoading />;
  }

  return (
    <Switch>
      <Route path="/" exact component={Main} />
      <Route path="/catalog/:categoryId" exact component={Category} />
      <Route path="/cart" exact component={Cart} />
      <Route path="/address" component={DeliveryAddress} />
      <Route path="/time" exact component={DeliveryTime} />
      <Route path="/user" exact component={UserInfoConfirmation} />
      <Route path="/name" component={UserName} />
      <Route path="/phone" component={UserPhone} />
      <Route path="/comment" component={DeliveryComment} />
      <Route path="/order/:orderId" exact component={Order} />
      <Route path="/orders" exact component={Orders} />
      <Route path="/search" exact component={Search} />
      <Route path="/catalog/products/:productId" component={Product} />
      <Route path="/agreement" exact component={TermsOfService} />
      <Route path="/checkout" exact component={Checkout} />
      <Route path="/payment" exact component={Payment} />
      <Route path="/privacy" exact component={PrivacyPolicy} />
      <Route path="/about" exact component={About} />
      <Route path="/testScreen" component={TestScreen} />
      <Route component={NotFound} />
    </Switch>
  );
};
