export const Identifiers = {
  helpers: {
    SmartAppAssistantHelper: Symbol.for('SmartAppAssistantHelper'),
    PubsubHelper: Symbol.for('PubsubHelper'),
    StorageHelper: Symbol.for('StorageHelper'),
    ApiRequestHelper: Symbol.for('ApiRequestHelper'),
  },
  providers: {
    CategoriesProvider: Symbol.for('CategoriesProvider'),
    CartProvider: Symbol.for('CartProvider'),
    OrdersProvider: Symbol.for('OrdersProvider'),
    UserProvider: Symbol.for('UserProvider'),
  },
  services: {
    AppInitializationService: Symbol.for('AppInitializationService'),
    AssistantService: Symbol.for('AssistantService'),
    CartService: Symbol.for('CartService'),
    CategoriesService: Symbol.for('CategoriesService'),
    OrdersService: Symbol.for('OrdersService'),
    UserService: Symbol.for('UserService'),
  },
  stores: {
    CartStore: Symbol.for('CartStore'),
    CategoriesStore: Symbol.for('CategoriesStore'),
    OrdersStore: Symbol.for('OrdersStore'),
    UserStore: Symbol.for('UserStore'),
  },
  constants: {},
};
