import { injectable } from 'inversify';
import { action, computed, makeObservable, observable } from 'mobx';
import { ProductType } from 'src/types';
import { CategoryType } from 'src/types/CategoryType';
import { LoadingStateEnum } from 'src/types/enums/LoadingStateEnum';
import { ProductFilterType } from 'src/types/ProductFilterType';
import { ProductSortTypeEnum } from 'src/types/ProductSortTypeEnum';
import { AbstractStore } from './AbstractStore';

@injectable()
export class CategoriesStore extends AbstractStore {
  public categories: CategoryType[] = [];
  public loadingState: LoadingStateEnum = LoadingStateEnum.initial;
  public loadMoreState: LoadingStateEnum = LoadingStateEnum.initial;
  public currentCategory: CategoryType = null;
  public currentProduct: ProductType = null;
  public currentProducts: ProductType[] = [];
  public foundProducts: ProductType[] = [];
  public hasMoreProducts: boolean = null;
  public currentPage = 1;
  public currentCategoryId: number = null;
  public sortType: ProductSortTypeEnum = null;
  public filterType: ProductFilterType = null;

  get categoriesByNameMap(): Map<string, CategoryType> {
    const map = new Map();

    const addItemsToMap = (categories: CategoryType[]) => {
      categories.forEach((category) => {
        map.set(category.title, category);

        if (category.children.length > 0) {
          addItemsToMap(category.children);
        }
      });
    };

    addItemsToMap(this.categories);

    return map;
  }

  get productByIdMap(): Map<number, ProductType> {
    const map = new Map();

    this.currentProducts.forEach((product) => {
      map.set(product.id, product);
    });

    return map;
  }

  constructor() {
    super();
    makeObservable(this, {
      categories: observable,
      currentCategory: observable,
      currentProduct: observable,
      currentProducts: observable,
      foundProducts: observable,
      loadingState: observable,
      updateState: action,
      categoriesByNameMap: computed,
      loadMoreState: observable,
      hasMoreProducts: observable,
      currentPage: observable,
      sortType: observable,
      filterType: observable,
      currentCategoryId: observable,
    });
  }
}
