import Intl from 'intl';
import 'intl/locale-data/jsonp/ru-RU';

let priceFormatter: Intl.NumberFormat;

export function formatPrice(price = 0): string {
  if (!priceFormatter) {
    priceFormatter = new Intl.NumberFormat('ru-RU', {
      style: 'currency',
      currency: 'RUB',
      currencyDisplay: 'symbol',
      minimumFractionDigits: 0,
    });
  }

  return priceFormatter.format(price);
}
