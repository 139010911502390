import { EventEmitter } from 'eventemitter3';
import { injectable } from 'inversify';
import { UnsubscribeFunctionType } from 'src/types/UnsubscribeFunctionType';

@injectable()
export class PubsubHelper {
  private eventEmitter = new EventEmitter();

  on<T>(
    eventName: string,
    callback: (data: T) => void
  ): UnsubscribeFunctionType {
    this.eventEmitter.on(eventName, callback);

    return () => {
      this.off(eventName, callback);
    };
  }

  off<T>(eventName: string, callback: (data: T) => void): any {
    return this.eventEmitter.off(eventName, callback);
  }

  once<T>(
    eventName: string,
    callback: (data: T) => void
  ): UnsubscribeFunctionType {
    this.eventEmitter.once(eventName, callback);

    return () => {
      this.off(eventName, callback);
    };
  }

  trigger<T>(eventName: string, data: T): void {
    this.eventEmitter.emit(eventName, data);
  }
}
