import { Button, Caption, Headline4, mediaQuery } from '@salutejs/plasma-ui';
import styled from 'styled-components';
import { colors, colorValues, fonts } from '@salutejs/plasma-tokens';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: stretch;
  width: 10rem;
  min-width: 10rem;

  ${mediaQuery('S')(`
    right: auto;
    width: 8rem;
  `)}

  ${mediaQuery('XL')(`
    width: 21rem;
    min-width: 21rem;
    align-self: center;
  `)}
`;

export const Title = styled(Caption)`
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 0.25rem;

  ${mediaQuery('XL')(`
    font-size: 1rem;
    line-height: 1.375rem;
    font-weight: normal;
  `)}
`;

export const ConfirmationText = styled(Title)`
  margin-top: 1rem;
`;

export const CardButton = styled(Button)`
  width: fit-content;

  ${mediaQuery('S')(`
    width: 100%;

    &:hover {
      transform: scale(0.96);
    }
  `)}
`;

export const TextWrapper = styled(Headline4)`
  margin-bottom: 0.75rem;

  ${mediaQuery('XL')(`
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
    line-height: 1.75rem;
  `)}
`;

export const Link = styled.button`
  justify-content: flex-start;
  width: 100%;
  padding: 0;
  margin: 0;
  margin-top: 0.75rem;
  font-family: ${fonts.Medium};
  font-size: 0.625rem;
  line-height: 0.75rem;
  font-weight: 500;
  text-align: start;
  color: ${colors.accent};
  border: 0;
  background-color: transparent;

  ${mediaQuery('S')(`
    margin-top: 1.5rem;
    font-size: 0.75rem;
    line-height: 1rem;
  `)}

  ${mediaQuery('XL')(`
    font-size: 0.75rem;
    line-height: 1rem;

    &:focus {
      font-weight: bold;
      text-decoration: underline;
    }
  `)}
`;

export const ErrorBadge = styled.div`
  font-family: ${fonts.Regular};
  margin-bottom: 1rem;
  background-color: ${colorValues.buttonCritical};
  font-size: 0.625rem;
  max-width: 100%;
  overflow: hidden;
  padding: 0.5rem;
  border-radius: 5px;
  opacity: 0.9;
`;
