import React from 'react';

export function useScrollIntoView(): React.FocusEventHandler<HTMLElement> {
  return React.useCallback((event: React.FocusEvent<HTMLElement>) => {
    event.target.scrollIntoView({
      behavior: 'smooth',
      inline: 'center',
      block: 'center',
    });
    event.preventDefault();
  }, []);
}
