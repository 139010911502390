import { Button, Container, Spinner } from '@salutejs/plasma-ui';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { ApplicationHeader } from 'src/components/ApplicationHeader';
import { RootWrapper } from 'src/components/RootWrapper';
import { formatPrice } from 'src/utils/formatPrice';
import {
  CartControlWrapper,
  Content,
  ContentColumn,
  CountControl,
  CountControlButton,
  CountLabel,
  Price,
  ProductRadiobox,
  RadioboxTitle,
  RadioboxText,
  RadioboxesWrapper,
  SpinnerWrapper,
  StyledRootWrapper,
  Title,
} from './StyledComponents';
import { AdditionalInfo } from './AdditionalInfo';
import plusIcon from './assets/plusIcon.svg';
import minusIcon from './assets/minusIcon.svg';
import { AppContext } from 'src/lib/contexts/AppContext';
import { observer } from 'mobx-react';
import { isIncreaseProductCountCommand } from 'src/types/AssistantCommands/SmartAppDataTypeCheckers/isIncreaseProductCountCommand';
import { PriceType, ProductType } from 'src/types';
import { isSelectProductCommand } from 'src/types/AssistantCommands/SmartAppDataTypeCheckers/isSelectProductCommand';
import { SelectProductCommand } from 'src/types/AssistantCommands/SelectProductCommand';
import { ImageGallery } from './ImageGallery';
import { isDecreaseProductCountCommand } from 'src/types/AssistantCommands/SmartAppDataTypeCheckers/isDecreaseProductCountCommand';
import { ProductImageCarousel } from './ProductImageCarousel';
import { DeviceQueryEnum } from 'src/enums/DeviceQueryEnum';
import { DeviceQuery } from 'src/components/DeviceQuery';
import { IncreaseProductCountCommand } from 'src/types/AssistantCommands/IncreaseProductCountCommand';
import { DecreaseProductCountCommand } from 'src/types/AssistantCommands/DecreaseProductCountCommand';
import { isAddToCartCommand } from 'src/types/AssistantCommands/SmartAppDataTypeCheckers/isAddToCartCommand';
import { AddToCartCommand } from 'src/types/AssistantCommands/AddToCartCommand';
import { changeQuotes } from 'src/utils/changeQuotes';
import { MobileCartButtonSpacer } from 'src/components/MobileCartButtonSpacer';
import { useLoadingWrapper } from 'src/hooks/useLoadingWrapper';
import { FullScreenLoading } from 'src/components/FullScreenLoading';
import { removeRoundBracketsContent } from 'src/utils/removeRoundBracketsContent';

const ADD_BUTTON_TEXT = 'Добавить\u00A0';
const SAVE_BUTTON_TEXT = 'Сохранить\u00A0';
const IN_CART_BUTTON_TEXT = 'В\u00A0корзине\u00A0';
const ADD_TO_CART_BUTTON_TEXT = 'В\u00A0корзину\u00A0';

interface RouteParams {
  productId: string;
}

export const ProductComponent: React.FunctionComponent<
  RouteComponentProps<RouteParams>
> = (props) => {
  const context = React.useContext(AppContext);
  const productId = Number(props.match.params.productId);
  const [product, setProduct] = React.useState(null);
  const [price, setPrice] = React.useState(null);
  const currentInCartCount =
    context.CartService.cartStore.countByPositionIdMap.get(price?.id) || 0;
  const [productCount, setProductCount] = React.useState(
    currentInCartCount > 0 ? currentInCartCount : 1
  );
  const useLoading = useLoadingWrapper();
  const waitForPromise = useLoading.waitForPromise;
  const isProcessing = useLoading.isLoading;

  const getButtonText = (text: string): string => {
    return productCount === 0
      ? text
      : `${text}${formatPrice(price.price * productCount)}`;
  };

  const setData = (currentProduct: ProductType) => {
    setProduct(currentProduct);

    setPrice(currentProduct.prices[0]);

    const isInCart =
      context.CartService.cartStore.countByPositionIdMap.get(
        currentProduct.prices[0].id
      ) > 0;

    context.AssistantService.openProduct(currentProduct, false, isInCart);
  };

  const onIncrement = React.useCallback(() => {
    setProductCount(productCount + 1);
  }, [productCount]);

  const onDecrement = React.useCallback(() => {
    setProductCount(productCount - 1);
  }, [productCount]);

  const onAddToCart = React.useCallback(() => {
    waitForPromise(
      context.CartService.changePositionCount(
        price.id,
        product,
        productCount,
        true
      )
    );
  }, [price, context.CartService, product, productCount]);

  const onChange = React.useCallback(
    (event) => {
      const price = product?.prices.find(
        (price: PriceType) => price.id === Number(event.target.value)
      );

      setPrice(price);
    },
    [product]
  );

  React.useEffect(() => {
    const productInCurrentCategoryProducts =
      context.CategoriesService.categoriesStore.productByIdMap.get(productId);

    if (productInCurrentCategoryProducts) {
      setData(productInCurrentCategoryProducts);
    } else {
      context.CategoriesService.setCurrentProduct(productId).then(() => {
        const product =
          context.CategoriesService.categoriesStore.currentProduct;

        setData(product);
      });
    }
  }, [productId]);

  React.useEffect(() => {
    setProductCount(currentInCartCount > 0 ? currentInCartCount : 1);
  }, [currentInCartCount]);

  React.useEffect(() => {
    if (product) {
      const isInCart =
        context.CartService.cartStore.countByPositionIdMap.get(price?.id) > 0;

      context.AssistantService.openProduct(product, true, isInCart);
    }
  }, [product, price, currentInCartCount]);

  React.useEffect(() => {
    const unsubscribeFromOnIncreaseProductCount =
      context.SmartAppAssistantHelper.conditionalOnData(
        isIncreaseProductCountCommand,
        (data: IncreaseProductCountCommand) => {
          setProductCount(productCount + Number(data.smart_app_data.count));
        }
      );

    const unsubscribeFromOnDecreaseProductCount =
      context.SmartAppAssistantHelper.conditionalOnData(
        isDecreaseProductCountCommand,
        (data: DecreaseProductCountCommand) => {
          setProductCount(productCount - Number(data.smart_app_data.count));
        }
      );

    const unsubscribeFromSelectProduct =
      context.SmartAppAssistantHelper.conditionalOnData(
        isSelectProductCommand,
        (data: SelectProductCommand) => {
          const selectedPrice: PriceType = product.prices.find(
            (price: PriceType) => price.title === data.smart_app_data.modifier
          );

          setPrice(selectedPrice);
        }
      );

    const unsubscribeFromAddToCart =
      context.SmartAppAssistantHelper.conditionalOnData(
        isAddToCartCommand,
        (data: AddToCartCommand) => {
          onAddToCart();
        }
      );

    return () => {
      unsubscribeFromOnIncreaseProductCount();
      unsubscribeFromOnDecreaseProductCount();
      unsubscribeFromSelectProduct();
      unsubscribeFromAddToCart();
    };
  }, [productCount, product, price, onAddToCart]);

  const onFocus = React.useCallback((event) => {
    event.target.scrollIntoView({
      behavior: 'smooth',
      inline: 'center',
      block: event.target.classList.contains('scrollToBottom')
        ? 'end'
        : 'center',
    });
  }, []);

  return (
    <>
      <ApplicationHeader title="" isBack isCart isOrders isSearch />

      {product && (
        <StyledRootWrapper>
          {price && (
            <DeviceQuery on={[DeviceQueryEnum.mobile]}>
              <ProductImageCarousel product={product} />
            </DeviceQuery>
          )}
          <Container onFocus={onFocus}>
            {price && (
              <Content>
                <ContentColumn>
                  <Title>
                    {removeRoundBracketsContent(
                      changeQuotes(product.title_short)
                    )}
                  </Title>

                  <Price>{formatPrice(price.price)}</Price>

                  <CartControlWrapper>
                    <CountControl>
                      <CountControlButton
                        size="s"
                        pin="circle-circle"
                        square
                        disabled={productCount === 0}
                        onClick={onDecrement}
                        className="scrollToBottom"
                      >
                        <img src={minusIcon} />
                      </CountControlButton>

                      <CountLabel>{productCount}</CountLabel>

                      <CountControlButton
                        size="s"
                        square
                        pin="circle-circle"
                        onClick={onIncrement}
                        className="scrollToBottom"
                      >
                        <img src={plusIcon} />
                      </CountControlButton>
                    </CountControl>

                    <>
                      <DeviceQuery on={[DeviceQueryEnum.mobile]}>
                        {!isProcessing && (
                          <Button
                            size="s"
                            view="primary"
                            disabled={productCount === currentInCartCount}
                            onClick={onAddToCart}
                          >
                            {productCount === currentInCartCount
                              ? getButtonText(IN_CART_BUTTON_TEXT)
                              : currentInCartCount > 0
                              ? getButtonText(SAVE_BUTTON_TEXT)
                              : getButtonText(ADD_BUTTON_TEXT)}
                          </Button>
                        )}
                        {isProcessing && (
                          <SpinnerWrapper>
                            <Spinner size={40} />
                          </SpinnerWrapper>
                        )}
                      </DeviceQuery>

                      <DeviceQuery off={[DeviceQueryEnum.mobile]}>
                        {!isProcessing && (
                          <Button
                            size="s"
                            view="primary"
                            disabled={productCount === currentInCartCount}
                            onClick={onAddToCart}
                            className="scrollToBottom"
                          >
                            {productCount === currentInCartCount
                              ? getButtonText(IN_CART_BUTTON_TEXT)
                              : currentInCartCount > 0
                              ? getButtonText(SAVE_BUTTON_TEXT)
                              : getButtonText(ADD_TO_CART_BUTTON_TEXT)}
                          </Button>
                        )}
                        {isProcessing && (
                          <SpinnerWrapper>
                            <Spinner size={80} />
                          </SpinnerWrapper>
                        )}
                      </DeviceQuery>
                    </>
                  </CartControlWrapper>

                  <RadioboxesWrapper>
                    {product.prices.map((currentPrice: PriceType) => (
                      <ProductRadiobox
                        className="productRadiobox"
                        tabIndex={0}
                        key={currentPrice.id}
                        label={
                          (
                            <RadioboxTitle>
                              {formatPrice(currentPrice.price)}
                              <RadioboxText>
                                {'\u00A0'}
                                {currentPrice.title.toLowerCase() !==
                                  'без размера' && currentPrice.title}
                              </RadioboxText>
                            </RadioboxTitle>
                          ) as unknown as string
                        }
                        checked={currentPrice.id === price.id}
                        value={currentPrice.id}
                        onChange={onChange}
                      />
                    ))}
                  </RadioboxesWrapper>

                  <AdditionalInfo product={product} price={price} />
                </ContentColumn>

                <DeviceQuery off={[DeviceQueryEnum.mobile]}>
                  <ImageGallery product={product} />
                </DeviceQuery>
              </Content>
            )}
          </Container>

          <DeviceQuery on={[DeviceQueryEnum.mobile]}>
            <MobileCartButtonSpacer />
          </DeviceQuery>
        </StyledRootWrapper>
      )}

      {!product && <FullScreenLoading />}
    </>
  );
};

export const Product = observer(ProductComponent);
