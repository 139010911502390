import * as React from 'react';
import 'reflect-metadata';
import ReactDOM from 'react-dom';
import { App } from './App';
import { createContainer } from './lib/compositionRoot';
import { AppContext, AppContextValue } from './lib/contexts/AppContext';
import { Identifiers } from './lib/identifiers';
import { AppInitializationService } from './lib/services/AppInitializationService';
import { AssistantService } from './lib/services/AssistantService';
import { CategoriesService } from './lib/services/CategoriesService';
import { CartService } from './lib/services/CartService';
import { UserService } from './lib/services/UserService';
import { SmartAppAssistantHelper } from './lib/helpers/SmartAppAssistantHelper';
import { OrdersService } from './lib/services/OrdersService';
import * as Sentry from '@sentry/react';
import '@salutejs/spatial-navigation';

import { Integrations } from '@sentry/tracing';

Sentry.init({
  dsn: 'https://1ec86f1ead1745b2b33bb0c0fba81486@o403358.ingest.sentry.io/6109705',
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const container = createContainer();

const defaultValue: AppContextValue = {
  AppInitializationService: container.get<AppInitializationService>(
    Identifiers.services.AppInitializationService
  ),
  AssistantService: container.get<AssistantService>(
    Identifiers.services.AssistantService
  ),
  CategoriesService: container.get<CategoriesService>(
    Identifiers.services.CategoriesService
  ),
  CartService: container.get<CartService>(Identifiers.services.CartService),
  OrdersService: container.get<OrdersService>(
    Identifiers.services.OrdersService
  ),
  SmartAppAssistantHelper: container.get<SmartAppAssistantHelper>(
    Identifiers.helpers.SmartAppAssistantHelper
  ),
  UserService: container.get<UserService>(Identifiers.services.UserService),
};

ReactDOM.render(
  <AppContext.Provider value={defaultValue}>
    <App />
  </AppContext.Provider>,
  document.querySelector('.root')
);
