import * as React from 'react';
import { CategoryType } from 'src/types';
import { ProductFilterType } from 'src/types/ProductFilterType';
import { SortOptionType } from 'src/types/SortOptionType';
import { OptionsTitle, OptionItemsWrapper } from './StyledComponents';
import { OptionItem } from './OptionItem';
import { OptionsButton } from './OptionsButton';

const CATEGORIES = 'Категории';
const MAX_CATEGORIES_SHOWN = 3;
const MAX_CATEGORIES_SHOWN_MOBILE = 5;

export const CategoriesOptions: React.FunctionComponent<{
  id: number;
  isShowMore: boolean;
  isMobile: boolean;
  isTitleShown: boolean;
  items: CategoryType[];
  onChange: (option: CategoryType | ProductFilterType | SortOptionType) => void;
  onShowMoreClick: () => void;
}> = (props) => {
  const categoriesNumber = props.isMobile
    ? MAX_CATEGORIES_SHOWN_MOBILE
    : MAX_CATEGORIES_SHOWN;

  const { isShowMore, items } = props;

  const categories = React.useMemo(() => {
    return isShowMore ? items : items.slice(0, categoriesNumber);
  }, [isShowMore, items]);

  return (
    <>
      {props.isTitleShown && <OptionsTitle>{CATEGORIES}</OptionsTitle>}
      <OptionItemsWrapper>
        {categories.map((item, index) => (
          <OptionItem
            key={item.id}
            text={item.title}
            value={item}
            isSelected={props.id === item.id}
            onChange={props.onChange}
          />
        ))}
        {(!props.isMobile || (props.isMobile && !props.isShowMore)) && (
          <OptionsButton
            isUp={props.isShowMore}
            onClick={props.onShowMoreClick}
          />
        )}
      </OptionItemsWrapper>
    </>
  );
};
