import {
  CarouselCol,
  Container,
  Footnote2,
  Headline1,
  mediaQuery,
  ParagraphText1,
  TextField,
  TextFieldProps,
} from '@salutejs/plasma-ui';
import styled from 'styled-components';
import { colors } from '@salutejs/plasma-tokens';
import { withAutoFocus } from '@salutejs/plasma-core/hocs';

const AutoFocusTextField = withAutoFocus<TextFieldProps>(TextField);

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const StyledCarouselCol = styled(CarouselCol)`
  padding-left: 0;
  padding-right: 1rem;
  display: flex;
`;

export const ApplicationCarousel = styled.div`
  width: 100%;
  flex: 1;
  box-sizing: border-box;
  overflow: auto;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  min-height: var(--content-height);

  & > * {
    margin: 0 1rem;

    &:first-child {
      margin-left: 0;
    }
  }
`;

export const Input = styled(AutoFocusTextField)`
  width: 100%;
  margin-bottom: 1.5rem;
`;

export const Wrapper = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    &:last-child {
      margin-left: 0;

      > div {
        padding: 0;
      }
    }
  }

  ${mediaQuery('XL')(`
    justify-content: flex-start;
  `)}
`;

export const StyledContainer = styled(Container)`
  height: var(--content-height);
`;

export const SearchCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 1rem;
  padding-right: 1.5rem;
  min-width: 10.3125rem;
  width: 10.3125rem;
  height: var(--content-height);
  margin-right: 1rem;
  border-radius: 20px;
  background: ${colors.backgroundSecondary};
  overflow: hidden;
  box-sizing: border-box;
`;

export const CardText = styled(Footnote2)`
  margin-bottom: 0.75rem;
  font-weight: 500;
  color: ${colors.whiteTertiary};

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const CardAmount = styled(Headline1)`
  margin-bottom: 0.75rem;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const ProductsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
`;

export const Inner = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TextWrapper = styled(ParagraphText1)`
  margin-bottom: 1.5rem;
`;
