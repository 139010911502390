import { TextFieldInput } from '@salutejs/plasma-core';
import { TextField } from '@salutejs/plasma-ui';
import * as React from 'react';

export const TestScreen = () => {
  return (
    <div>
      <div style={{ margin: '1rem', background: 'gray', minHeight: 200 }} />
      <div style={{ margin: '1rem', background: 'gray', minHeight: 200 }} />
      <div style={{ margin: '1rem', background: 'gray', minHeight: 200 }} />
      <TextField label="text input" />
      <div style={{ margin: '1rem', background: 'gray', minHeight: 200 }} />
    </div>
  );
};
