import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { AppContext } from 'src/lib/contexts/AppContext';
import { Container, Footnote2, Spinner } from '@salutejs/plasma-ui';
import { ApplicationHeader } from 'src/components/ApplicationHeader';
import { observer } from 'mobx-react';
import { RootWrapper } from 'src/components/RootWrapper';
import {
  CardsWrapper,
  LoaderWrapper,
  MobileButton,
  MobileFooterRow,
  MobileFooterRowWrapper,
  SubmitButton,
  StyledContainer,
  Wrapper,
} from './StyledComponents';
import { CheckoutCard } from './CheckoutCard';
import { CartInfoCard } from 'src/components/CartInfoCard';
import { formatPrice } from 'src/utils/formatPrice';
import { clientHistory } from 'src/history';
import { LoadingStateEnum } from 'src/types/enums/LoadingStateEnum';
import { DeviceQueryEnum } from 'src/enums/DeviceQueryEnum';
import { DeviceQuery } from 'src/components/DeviceQuery';
import { Link } from 'src/components/CartInfoCard/StyledComponents';
import { MobileCartInner } from '../Cart/StyledComponents';

const TITLE = 'Оплата заказа';
const SUBTITLE = 'Проверьте правильность введенных данных';
const NAME = 'Имя';
const PHONE = 'Номер телефона';
const ADDRESS = 'Адрес доставки';
const COMMENT = 'Комментарий к доставке';
const DATE_TIME = 'Дата и время доставки';
const NO_COMMENT = 'Нет комментария';
const NO_ADDRESS = 'Нет адреса доставки';
const NO_DATE = 'Нет даты доставки';
const NO_PHONE = 'Нет телефона получателя';
const NO_NAME = 'Нет имени получателя';

const AGREEMENT = 'Условия продажи';
const PRIVACY_POLICY = 'Политика\u00A0конфиденциальности';

export const Checkout: React.FunctionComponent<RouteComponentProps> = observer(
  () => {
    const context = React.useContext(AppContext);
    const name =
      context.CartService.cartStore.cart.receiver.name ||
      context.UserService.userStore.user.name;
    const phone =
      context.CartService.cartStore.cart.receiver.phone ||
      context.UserService.userStore.user.phone;
    const address = context.CartService.cartStore.cart.selectedAddress;
    const comment = context.CartService.cartStore.cart.comment;
    const interval =
      context.CartService.cartStore.intervalDescriptionByIdMap.get(
        String(context.CartService.cartStore.cart.delivery?.intervalId)
      );
    const dateTime = `${context.CartService.cartStore.cart.delivery?.date}${
      interval ? ', ' + interval : ''
    }`;
    const cartAmount = context.CartService.cartStore.amount;
    const deliveryCostCalculated =
      context.CartService.cartStore.deliveryCostCalculated;

    const deliveryPrice =
      deliveryCostCalculated.deliveryCost +
      deliveryCostCalculated.exactIntervalAdditionalCost +
      deliveryCostCalculated.intervalAdditionalCost;

    const cartTotal = cartAmount + deliveryPrice;
    const loadingState = context.CartService.cartStore.loadingState;

    React.useEffect(() => {
      // on mount
      context.AssistantService.openCheckout();
    }, []);

    const onGoToPayment = () => {
      context.AssistantService.onGoToPayment();
    };

    const onChangeAddressClick = () => {
      clientHistory.replace('/address');
      context.CartService.setShouldNavigateToCheckout(true);
    };

    const onChangeCommentClick = () => {
      clientHistory.replace('/comment');
      context.CartService.setShouldNavigateToCheckout(true);
    };

    const onChangeDateClick = () => {
      clientHistory.replace('/time');
      context.CartService.setShouldNavigateToCheckout(true);
    };

    const onChangeNameClick = () => {
      clientHistory.replace('/name');
      context.CartService.setShouldNavigateToCheckout(true);
    };

    const onChangePhoneClick = () => {
      clientHistory.replace('/phone');
      context.CartService.setShouldNavigateToCheckout(true);
    };

    const onTermsClick = () => {
      context.AssistantService.onGoToAgreement();
    };

    const onPrivacyPolicyClick = () => {
      context.AssistantService.onGoToPrivacyPolicy();
    };

    return (
      <RootWrapper>
        <ApplicationHeader title={TITLE} subtitle={SUBTITLE} isBack={true} />

        {loadingState === LoadingStateEnum.processing && (
          <LoaderWrapper>
            <Spinner />
          </LoaderWrapper>
        )}

        <StyledContainer>
          {loadingState === LoadingStateEnum.success && (
            <Wrapper>
              <CardsWrapper>
                <CheckoutCard
                  title={ADDRESS}
                  text={address?.value ? address?.value : NO_ADDRESS}
                  onClick={onChangeAddressClick}
                />
                <CheckoutCard
                  title={COMMENT}
                  text={comment ? comment : NO_COMMENT}
                  onClick={onChangeCommentClick}
                />
                <CheckoutCard
                  title={DATE_TIME}
                  text={dateTime ? dateTime : NO_DATE}
                  onClick={onChangeDateClick}
                />
                <CheckoutCard
                  title={NAME}
                  text={name ? name : NO_NAME}
                  onClick={onChangeNameClick}
                />
                <CheckoutCard
                  title={PHONE}
                  text={phone ? phone : NO_PHONE}
                  onClick={onChangePhoneClick}
                />
              </CardsWrapper>
              <DeviceQuery off={[DeviceQueryEnum.mobile]}>
                <CartInfoCard
                  buttonText={`К оплате ${formatPrice(
                    cartAmount + deliveryPrice
                  )}`}
                  orderSum={cartAmount + deliveryPrice}
                  deliveryPrice={deliveryPrice}
                  loadingState={loadingState}
                  isTerms
                  isPrivacy
                  onClick={onGoToPayment}
                />
              </DeviceQuery>
              <DeviceQuery on={[DeviceQueryEnum.mobile]}>
                <MobileFooterRowWrapper>
                  <MobileFooterRow>
                    <Footnote2>Стоимость доставки</Footnote2>
                    <Footnote2>
                      {!!deliveryPrice
                        ? formatPrice(deliveryPrice)
                        : 'Бесплатно'}
                    </Footnote2>
                  </MobileFooterRow>
                </MobileFooterRowWrapper>
                <MobileCartInner>
                  <Link onClick={onTermsClick}>{AGREEMENT}</Link>
                  <Link onClick={onPrivacyPolicyClick}>{PRIVACY_POLICY}</Link>
                </MobileCartInner>
                <SubmitButton
                  view="primary"
                  size="m"
                  text={`К оплате ${formatPrice(cartTotal)}`}
                  onClick={onGoToPayment}
                  stretch
                />
              </DeviceQuery>
            </Wrapper>
          )}
        </StyledContainer>
      </RootWrapper>
    );
  }
);
