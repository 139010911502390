import * as React from 'react';
import { AppInitializationService } from '../services/AppInitializationService';
import { AssistantService } from '../services/AssistantService';
import { CategoriesService } from '../services/CategoriesService';
import { CartService } from '../services/CartService';
import { SmartAppAssistantHelper } from '../helpers/SmartAppAssistantHelper';
import { OrdersService } from '../services/OrdersService';
import { UserService } from '../services/UserService';

export type AppContextValue = {
  AppInitializationService: AppInitializationService;
  AssistantService: AssistantService;
  CategoriesService: CategoriesService;
  CartService: CartService;
  OrdersService: OrdersService;
  SmartAppAssistantHelper: SmartAppAssistantHelper;
  UserService: UserService;
};

export const AppContext = React.createContext<AppContextValue>(
  {} as AppContextValue
);
