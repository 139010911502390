import * as React from 'react';
import { ProductType, PriceType } from 'src/types';
import { changeQuotes } from 'src/utils/changeQuotes';
import {
  AdditionalInfoTitle,
  AdditionalInfoInner,
  AdditionalInfoText,
  List,
  ListItem,
} from './StyledComponents';

export const AdditionalInfo: React.FunctionComponent<{
  product: ProductType;
  price: PriceType;
}> = (props) => {
  const getProductComposition = React.useCallback(() => {
    const composition = props.price.additional_params[0].value.split(', ');
    return composition.map((item) => {
      return {
        text: item.replace(/ *\([^)]*\) */g, ''),
        count: item.replace(/[^0-9]/g, ''),
      };
    });
  }, [props.price]);

  const productComposition = getProductComposition();

  return (
    <>
      <AdditionalInfoInner tabIndex={0}>
        <AdditionalInfoTitle>Артикул</AdditionalInfoTitle>
        <AdditionalInfoText>{props.product.article}</AdditionalInfoText>
      </AdditionalInfoInner>

      {props.product.description && (
        <AdditionalInfoInner tabIndex={0}>
          <AdditionalInfoTitle>Описание</AdditionalInfoTitle>
          <AdditionalInfoText>{props.product.description}</AdditionalInfoText>
        </AdditionalInfoInner>
      )}

      <AdditionalInfoInner tabIndex={0}>
        <AdditionalInfoTitle>Состав</AdditionalInfoTitle>
        <List>
          {productComposition.map((item, index) => (
            <ListItem key={`${item}${index}`}>
              <AdditionalInfoText>{`${changeQuotes(item.text)} (${
                item.count
              })`}</AdditionalInfoText>
            </ListItem>
          ))}
        </List>
      </AdditionalInfoInner>
    </>
  );
};
