import * as React from 'react';
import { useScrollIntoView } from 'src/hooks/useScrollintoView';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  height: 100%;
  left: 0;
  right: 0;
  overflow: hidden;
`;

const Block = styled.div`
  flex: 1;
  min-width: 10px;
`;

export const ScrollablePgeComponent = () => {
  const containerRef = React.useRef<HTMLDivElement>();

  const [blocks, setBlocks] = React.useState([]);

  React.useEffect(() => {
    const height = containerRef.current.clientHeight;
    const scrollHeight = window.innerHeight / 4;
    const blocksCount = Math.ceil(height / scrollHeight);
    const blocks = new Array(blocksCount).fill('');
    setBlocks(blocks);
  }, []);

  const onFocus = useScrollIntoView();

  return (
    <Container ref={containerRef}>
      {blocks.map((block, index) => (
        <Block key={index} tabIndex={0} onFocus={onFocus} />
      ))}
    </Container>
  );
};
