import { colors, colorValues } from '@salutejs/plasma-tokens';
import { Container, Footnote1, Headline3, Body1 } from '@salutejs/plasma-ui';
import styled from 'styled-components';

export const Wrapper = styled(Container)`
  background-color: ${colorValues.backgroundPrimary};
`;

export const Title = styled(Headline3)`
  margin-bottom: 0.75rem;
`;

export const Subtitle = styled(Body1)`
  margin-bottom: 0.75rem;
`;

export const TextWrapper = styled.div`
  margin-bottom: 1.5rem;
`;

export const Separator = styled.div`
  height: 1.125rem;
`;

export const Text = styled(Footnote1)`
  color: ${colors.secondary};
`;
