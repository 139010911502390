import { inject, injectable } from 'inversify';
import { Identifiers } from '../identifiers';
import { IDBStorageHelper } from '../helpers/IDBStorageHelper';
import { OrderType } from 'src/types/OrderType';
import { OrdersProviderInterface } from '../interfaces/providers/OrdersProviderInterface';
import { ApiRequestHelper } from '../helpers/ApiRequestHelper';

@injectable()
export class OrdersProvider implements OrdersProviderInterface {
  @inject(Identifiers.helpers.StorageHelper)
  private storageHelper: IDBStorageHelper;

  @inject(Identifiers.helpers.ApiRequestHelper)
  private apiRequestHelper: ApiRequestHelper;

  static ordersStorageKey = 'orders';

  async getOrder(id: string): Promise<OrderType> {
    try {
      const url = `${process.env.REACT_APP_API_URL}/api/v1/me/orders/${id}`;
      const response = await this.apiRequestHelper.makeAuthorizedRequest(url);

      return response.data.data as OrderType;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  async getOrders(): Promise<OrderType[]> {
    try {
      const url = `${process.env.REACT_APP_API_URL}/api/v1/me/orders`;
      const response = await this.apiRequestHelper.makeAuthorizedRequest(url);

      return response.data.data as OrderType[];
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  async createOrder(order: OrderType): Promise<void> {
    try {
      const storedOrders = await this.getOrders();

      this.storageHelper.setItem(
        OrdersProvider.ordersStorageKey,
        JSON.stringify(storedOrders.concat(order))
      );
    } catch (error) {
      console.error(error);
    }
  }
}
