import { Button, Spinner } from '@salutejs/plasma-ui';
import * as React from 'react';
import { SpinnerWrapper } from 'src/screens/Cart/StyledComponents';
import { WrapperOuter } from './StyledComponents';

export const SberboxLisingShowMore = React.forwardRef<
  HTMLButtonElement,
  {
    onClick(): void;
    isLoading: boolean;
    onFocus?: (e: React.FocusEvent<HTMLButtonElement>) => void;
  }
>((props, ref) => {
  return (
    <WrapperOuter>
      {props.isLoading ? (
        <>
          <SpinnerWrapper>
            <Spinner size={40} />
          </SpinnerWrapper>
        </>
      ) : (
        <Button
          size="m"
          stretch
          onClick={props.onClick}
          onFocus={props.onFocus}
          ref={ref}
        >
          Показать еще
        </Button>
      )}
    </WrapperOuter>
  );
});

SberboxLisingShowMore.displayName = 'SberboxLisingShowMore';
