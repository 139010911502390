import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { ApplicationHeader } from 'src/components/ApplicationHeader';
import { RootWrapper } from 'src/components/RootWrapper';
import { Container } from '@salutejs/plasma-ui';
import {
  BackButton,
  ParagraphTitle,
  ParagraphText,
  ParagraphWrapper,
  Title,
  BackButtonSpacer,
  PageContainer,
} from './StyledComponents';
import { clientHistory } from 'src/history';
import { AppContext } from 'src/lib/contexts/AppContext';
import { isGoBackToCheckoutCommand } from 'src/types/AssistantCommands/SmartAppDataTypeCheckers/isGoBackToCheckoutCommand';
import { useScrollIntoView } from 'src/hooks/useScrollintoView';
import { ScrollablePgeComponent } from 'src/components/ScrollablePageComponent';
import { DeviceQuery } from 'src/components/DeviceQuery';
import { DeviceQueryEnum } from 'src/enums/DeviceQueryEnum';

const HEADER_TITLE = 'Условия продажи';
const TITLE = 'ПУБЛИЧНАЯ ОФЕРТА';
const BUTTON_TEXT = 'Понятно';
const termsOfService = [
  {
    title:
      'nо заключении договора на выполнение работ по изготовлению цветочной композиции и оказание услуг по их доставке',
    paragarphs: [
      {
        text: 'Данный документ является официальным предложением заключить договор на выполнение работ по изготовлению цветочной композиции и оказание услуг по их доставке на определенных договором условиях. В соответствии со ст. 437 Гражданского Кодекса РФ данное предложение, содержащее все существенные условия выполнения работ и предоставления услуг, является публичной офертой, и совершение действий по выполнению указанных в ней условий договора (в частности, оплата) считается акцептом оферты. При этом договор считается заключенным без подписания в каждом конкретном случае, т.к. акцепт оферты приравнивается к заключению договора на указанных ниже условиях.',
      },
    ],
  },
  {
    title: '1. ОПРЕДЕЛЕНИЯ И ТЕРМИНЫ',
    paragarphs: [
      {
        text: '1.1. В целях настоящей Оферты нижеприведенные термины используются в следующем значении:',
      },
      {
        text: 'Акцепт Оферты – признаваемое заключением Договора полное и безоговорочное согласие Заказчика с условиями Оферты путем оплаты Работ и Услуг Компании в соответствии с требованиями раздела 6 Оферты',
      },
      {
        text: 'Договор – заключаемый посредством Акцепта Оферты договор между Заказчиком и Компанией на выполнение Работ и оказание Услуг. Любая ссылка в Оферте на Договор (раздел или пункт Договора) и/или его условия означает соответствующую ссылку на настоящую Оферту (ее пункт или раздел) и/или ее условия.',
      },
      {
        text: 'Заказ – отдельное задание Заказчика на выполнение Работ и оказание Услуг, содержащее согласованную с Компанией информацию о количественных и качественных характеристиках Продукции, а также данные о времени и месте их доставки. Перечень информации, подлежащей согласованию сторонами Договора при оформлении Заказа, определяется пунктом 3.1 Договора. Каждый Заказ имеет свой уникальный номер, присваиваемый Компанией при получении Заказа.',
      },
      {
        text: 'Заказчик – осуществившее Акцепт Оферты физическое лицо, по заданию которого Компания выполняет работы по изготовлению цветочных композиций из материалов Компании и оказывает услуги по доставке Продукции по указанному Заказчиком адресу (указанному Заказчиком получателю).',
      },
      {
        text: 'Компания – Общество с ограниченной ответственностью «АМФ Лтд» (ИНН 7723419082), исполнитель Работ и Услуг в рамках настоящей Оферты',
      },
      {
        text: 'Опубликование – размещение текста настоящей Оферты в свободном доступе в сети Интернет по адресу: https://sendflowers.ru/rus/page/quality',
      },
      {
        text: 'Оферта – адресованное любому физическому лицу предложение Компании на заключение Договора на условиях, содержащихся в настоящей Оферте.',
      },
      {
        text: 'Продукция  –  цветочные композиции и/или сопутствующие товары, перечень которых содержится на сайте Компании в сети Интернет по адресу www.sendflowers.ru.',
      },
      {
        text: 'Работы и Услуги –  выполняемые Компанией по заданию Заказчика работы по изготовлению цветочной композиции и оказываемые Компанией услуги по доставке Продукции.',
      },
      {
        text: 'Цветочная Композиция – результат работ Компании, а именно: оформленная в соответствии с требованиями Заказчика и с использованием профессиональных флористических навыков совокупность цветов и иных продуктов растениеводства (при необходимости – сухоцветов), специальной упаковки (включая корзины, плошки, ленты и др.), средств обеспечения свежести растений, средств идентификации повода направления композиции (открытки и иные носители текста).',
      },
      {
        text: 'При наличии в Оферте терминов, значение которых не определено в пункте 1.1 Оферты и не может быть однозначно истолковано исходя из общеупотребимой практики, при их толковании следует руководствоваться: в первую очередь – толкованием, определенным на Сайте Компании в сети Интернет по адресу www.sendflowers.ru (далее – Сайт), во вторую очередь – исходя из обычаев делового оборота в сфере реализации цветов (цветочных композиций).',
      },
    ],
  },
  {
    title: '2. ПРЕДМЕТ ДОГОВОРА',
    paragarphs: [
      {
        text: '2.1. Компания обязуется по заданию Заказчика выполнять Работы и оказывать Услуги, а Заказчик обязуется оплачивать Работы и Услуги Компании в соответствии с разделом 6 Оферты.',
      },
    ],
  },
  {
    title: '3. УСЛОВИЯ ВЫПОЛНЕНИЯ РАБОТ И ОКАЗАНИЯ УСЛУГ',
    paragarphs: [
      {
        text: '3.1. Обязательства Компании по выполнению Работ и оказанию Услуг распространяются на Заказы, содержащие следующую информацию (доводимую Заказчиком до Компании после регистрации Заказчика на Сайте Компании путем самостоятельного заполнения формы Заказа при использовании соответствующей возможности на Сайте):',
      },
      {
        text: 'а) персональные данные Заказчика, позволяющие идентифицировать Заказчика, а также поступающие от него в счет оплаты Работ и Услуг денежные средства.',
      },
      {
        text: 'б) наименование или код Продукции в соответствии с наименованием и/или кодировкой предложений, содержащимся в момент оформления Заказа на Сайте. В случае, если характеристики необходимой Заказчику цветочной композиции не соответствуют ни одному из предложений размещенных на Сайте, - желаемый состав и количество основных цветов (растений) и/или иных материалов, подлежащих включению в состав композиции.',
      },
      {
        text: 'в) точный адрес доставки / местонахождение получателя Заказа и его телефон (либо указание на желание Заказчика забрать Продукцию самостоятельно.',
      },
      {
        text: 'г) дата и желаемое время доставки.',
      },
      {
        text: 'д) фамилия и имя получателя (при необходимости - наименование организации).',
      },
      {
        text: 'е) телефон или иное средство оперативной связи с Заказчиком (обязательно при необходимости оперативного уведомления о доставке).',
      },
      {
        text: 'ж) при необходимости - текст поздравительной открытки (не более 350 символов).',
      },
      {
        text: '3.2. Приведённые примеры работ на Сайте являются возможными вариантами изготовления цветочных композиций. Каждая изготавливаемая флористом Компании цветочная композиция является индивидуальной и может отличаться упаковкой, внешним видом, размером и прочими внешними данными. Также возможны замены по количеству, ассортименту и составу цветов в зависимости от сезонности, наличия цветов в том или ином регионе и т.п. факторов. Итоговое исполнение Заказа может отличаться от иллюстрации на Сайте или от представленного в печатных каталогах. Тем не менее, каждая цветочная композиция составляется из свежих цветов и Компания предпримет все усилия для того, чтобы цвет, форма цветочной композиции как можно полнее соответствовали иллюстрации на Сайте.',
      },
      {
        text: '3.3. В случае указания ошибочного адреса или указания неполных данных, необходимых для осуществления доставки, отсутствия получателя по указанному адресу (по любой причине) в течение даты доставки и т.п., Компания обязуется приложить достаточные усилия, чтобы получатель получил Продукцию. Однако, если это не удаётся, либо получатель отказывается принять Продукцию по любой причине, Заказ признается Сторонами исполненным (вне зависимости от присутствия получателя по указанному Заказчиком адресу).',
      },
      {
        text: 'Указание телефона получателя позволяет уточнить информацию, необходимую для успешной доставки Заказа. При отсутствии получателя по указанному Заказчиком адресу, цветы могут быть оставлены любому человеку в квартире или соседям, либо сотрудникам офиса.',
      },
      {
        text: '3.4. При Заказе с доставкой в гостиницы или иные организации (бизнес-центры, больницы и т.д.), где отсутствует возможность личного вручения Продукции получателю,  следует сообщить номер апартаментов (офиса, палаты и т.д.)  и имя получателя. В большинстве случаев Заказ может быть доставлен только в зону рецепшн или аналогичной службы организации. Дальнейшее движение Заказа зависит от сотрудников организации, принявших Продукцию. Компания не несет ответственности за невозможность доставки цветочной композиции из-за отъезда получателя из отеля (увольнения из организации, выписки из больницы и т.д.). Заказ считается доставленным в момент передачи сотрудникам рецепшн организации или аналогичной службы с указанием ФИО и номера апартаментов (офиса, палаты и т.д.) получателя.',
      },
      {
        text: '3.5. Время доставки. Обязательства Компании распространяются исключительно на дату доставки. Заказы по рабочим адресам доставляются в большинстве случаев в рабочее время с 10-00 до 19-00, если не оговорено иное время, или в зависимости от ситуации; заказы по домашним адресам доставляются в большинстве случаев в течение дня с 09-00 до 21-00, если не оговорено иное. Компания стремится выполнить пожелания Заказчика по времени доставки (доставка на "утро", "вечер" или на конкретное время), но не принимает претензии по времени доставки в течение указанной даты доставки. ',
      },
      {
        text: '3.6. Компания оставляет за собой право отказать Заказчику в размещении его Заказа по собственному усмотрению. Извещение об отказе в выполнении Работ и оказании Услуг направляется на адрес электронной почты Заказчика, указанный им при оформлении Заказа, в срок не позднее 2 часов до даты доставки. При этом поступившие от Заказчика денежные средства возвращаются Компанией Заказчику тем же способом, которым была произведена оплата.',
      },
      {
        text: '3.7. Информация о результате доставки Продукции предоставляется Заказчику в течение 3 (трех) рабочих дней начиная со следующего дня после даты доставки.',
      },
      {
        text: '3.8. Если Заказчик изменяет Заказ (переносит сроки доставки Заказа или состав Заказа) менее чем за 1 рабочий день до даты исполнения Заказа, это будет считаться как новый заказ и подлежит 100 % предварительной оплате Заказчиком.',
      },
      {
        text: '3.9. Если Заказчик отказывается от Заказа, Компания возвращает Заказчику сумму оплаты за Заказ за минусом суммы расходов, понесенных Компанией при выполнении Заказа.',
      },
    ],
  },
  {
    title: '4.  ОБЯЗАТЕЛЬСТВА КОМПАНИИ',
    paragarphs: [
      {
        text: 'Компания обязуется:',
      },
      {
        text: '4.1. Выполнить Работы и оказать Услуги таким образом, чтобы обеспечить надлежащее качество исполнения Заказов в соответствии с условиями Оферты и Договора. При этом условие об обеспечении надлежащего качества Работ и Услуг признается выполненным при отсутствии обоснованных письменных жалоб и претензий Заказчика к такому качеству, поступивших Компании не позднее 24 часов с момента исполнения соответствующего Заказа.',
      },
      {
        text: '4.2 Приостановить по запросу Заказчика на согласованный с ним срок, в совокупности не превышающий трех месяцев, исполнение оплаченного Заказа с возможностью внесения Заказчиком изменений в его характеристики, при условии получения Компанией уведомления за 1 рабочий день до первоначально заявленного Заказчиком дня исполнения Заказа. При этом приостановленный Заказ будет считаться исполненным, а Работы и Услуги в отношении такого Заказа выполненными и оказанными (и подлежащими оплате в полном объеме):',
      },
      {
        text: 'а) с даты фактического исполнения Заказа по требованию Заказчика, если такое требование получено Компанией до истечения трех месяцев с даты приостановления;',
      },
      {
        text: 'б) по истечении трех месяцев с даты приостановления исполнения Заказа, если в течение указанного срока, заявление об исполнении приостановленного Заказа не было получено Компанией от Заказчика.',
      },
      {
        text: '4.3. Компания предоставляет Заказчику доступ к Сайту и поддерживает надлежащее функционирование Сайта, оперативно восстанавливает его работоспособность в случае технических сбоев и перерывов. Однако Компания не предоставляет гарантий бесперебойной работы Сайта и соблюдения каких-либо сроков восстановления работоспособности Сайта в случае перерывов в работе.',
      },
      {
        text: '4.3.1. Сайт предоставляется на условиях «как есть» (в частности, это означает, что Компания не несет ответственности и не компенсирует Заказчику убытки в связи с временной невозможностью использования Сайта, с утратой или искажением информации, размещенной на Сайте, в том числе Заказчиком в личном кабинете. Компания принимает необходимые меры в целях обеспечения Заказчикам качественного сервиса, в том числе во время технических перерывов в работе Сайта.',
      },
      {
        text: '4.3.2. Компания вправе размещать на Сайте либо направлять на предоставленный Заказчиком электронный адрес или номер контактного телефона информационные или рекламные сообщения в связи с деятельностью Компании или исполнением Заказа. Компания обязуется прекратить отправку Заказчику сообщений о деятельности Компании в случае получения отказа Заказчика от рассылки.',
      },
      {
        text: '4.3.3. Компания вправе изменять содержание, программное обеспечение, оформление Сайта, изменять или дополнять используемые скрипты, любые серверные приложения по своему усмотрению, с/без предварительного уведомления Заказчика (при этом Компания не несет ответственности в случае возникновения у Заказчика в результате внесенных изменений неудобств при пользовании Сайтом или причинения ему какого-либо ущерба).',
      },
      {
        text: '4.4. Компания обязуется при исполнении Оферты выполнять требования законодательства, в том числе о защите прав потребителя, о защите персональных данных.',
      },
      {
        text: '4.4.1. Компания обязуется сохранять конфиденциальность предоставленных Заказчиком данных и не разглашать их третьим лицам (за исключением уполномоченных государственных органов, а также службы доставки, аудиторов, консультантов, бухгалтеров, юристов на условиях сохранения ими конфиденциальности полученной информации). Положение об обработке персональных данных в Компании является неотъемлемой частью настоящей Оферты и размещено на сайте Компании по адресу в сети Интернет: https://sendflowers.ru/rus/page/quality',
      },
      {
        text: '4.4.2. Компания вправе хранить в своей базе данных информацию, предоставленную Заказчиком при оформлении Заказов, в том числе персональные данные, в течение неограниченного срока в целях оформления новых Заказов, получения статистической информации и информирования Заказчиков о деятельности Компании.',
      },
      {
        text: '4.4.3. В целях исполнения Оферты и на её условиях, Заказчик предоставляет Компании согласие на обработку его персональных данных, предоставленных Компании, и подтверждает акцептом настоящей Оферты, что ознакомлен и согласен с Политикой конфиденциальности Компании.',
      },
      {
        text: '4.4.4. При обработке персональных данных Компания обеспечивает соответствие содержания и объема обрабатываемых персональных данных заявленным целям обработки.',
      },
      {
        text: '4.5. Компания гарантирует Заказчикам информационную поддержку по телефонам контактного центра («горячей линии») или по электронной почте (контактный электронный адрес и номер телефона контактного центра опубликованы на Сайте). Не предоставляются консультации по вопросам, не имеющим отношения к Продукции, а также в случае злоупотребления Заказчиком правом на консультирование.',
      },
      {
        text: '4.6. Компания вправе осуществлять запись телефонных разговоров между Заказчиком и операторами контактного центра Компании в целях подтверждения сделанных Заказов, предоставленной Заказчиком информации, условий договора между Компанией и Заказчиком. Запись телефонного разговора может храниться Компанией в течение 12 месяцев и после истечения этого срока подлежит уничтожению. Запись телефонного разговора не может быть предоставлена третьим лицам, за исключением правоохранительных органов или суда в случае необходимости.',
      },
      {
        text: '4.7. Компания имеет другие права и обязанности, предусмотренные действующим законодательством РФ.',
      },
    ],
  },
  {
    title: '5. ОБЯЗАТЕЛЬСТВА ЗАКАЗЧИКА',
    paragarphs: [
      {
        text: 'Заказчик обязуется:',
      },
      {
        text: '5.1. Направлять Компании информацию, предусмотренную пунктом 3.1. Оферты, путем самостоятельного заполнения формы Заказа (воспользовавшись соответствующей возможностью на Сайте), не позднее 3 (трех) рабочих часов (рабочие часы: с 10-00 до 19-00, с понедельника по пятницу, кроме праздничных дней) до необходимого времени исполнения соответствующего Заказа (в случае доставки цветочных композиций по г. Москве) или не позднее 2 (двух) рабочих дней до необходимого времени исполнения Заказа (в случае доставки цветочных композиций вне г. Москвы).',
      },
      {
        text: '5.2. Оплачивать Работы и Услуги Компании в соответствии с разделом 6 Оферты.',
      },
      {
        text: '5.3. В случае получения от Компании персональных регистрационных данных (логин и пароль), предназначенных для авторизации на Сайте в целях оформления Заказов, обеспечивать сохранность и конфиденциальность регистрационных сведений. Все действия по оформлению Заказов осуществленные в период действия Договора с использованием логина и пароля Заказчика, считаются осуществленными Заказчиком. Компания не несет ответственность за несанкционированное использование регистрационных данных Заказчика третьими лицами',
      },
      {
        text: '5.4 При оформлении Заказа и направлении Компании соответствующей информации соблюдать все применимые нормы и требования действующего законодательства, в частности, но не ограничиваясь:',
      },
      {
        text: '-  не использовать в тексте поздравлений ненормативную лексику, ',
      },
      {
        text: '- не использовать результаты Работ и Услуг в качестве взятки должностным лицам,',
      },
      {
        text: '- не использовать сотрудников службы доставки Заказчика для проникновения на заведомо закрытые территории.',
      },
      {
        text: '5.5. Заказчик обязуется до оформления Заказа ознакомиться с настоящей Офертой, информацией о Продукции, а также иной информацией для Заказчиков, опубликованной на Сайте. Оформление и оплата Заказа являются подтверждением факта ознакомления и согласия Заказчика с условиями настоящей Оферты, иных документов и иной информации, опубликованных на Сайте или сообщенных Заказчику по телефону или электронной почте. Заказчик обязуется проверять самостоятельно наличие изменений настоящей Оферты.',
      },
      {
        text: '5.6. Заказчик обязуется использовать Продукцию в течение сроков годности и в соответствии с инструкциями Компании (производителя) о порядке её хранения и использования. В случае нарушения указанных требований, уплаченные за Продукцию и её доставку денежные средства возврату не подлежат.',
      },
      {
        text: '5.7. Заказчик обязуется своевременно и в полном объеме предоставлять Компании достоверную информацию, необходимую для заключения и исполнения настоящей Оферты. В частности, в целях идентификации поступающих от Заказчика платежей Компания вправе запросить у него персональные данные. В случае недостаточности такой информации или наличия сомнений в ее достоверности Компания вправе приостановить исполнение Договора, уведомив Заказчика.',
      },
      {
        text: '5.8. Сообщать Компании в течение 24 часов об обнаружении попытки несанкционированного доступа к персональной информации Заказчика на Сайте. Компания не несет ответственности за убытки Заказчика, возникшие с момента потери им контроля над паролями, до получения в рабочее время Компании уведомления Заказчика.',
      },
      {
        text: '5.9. Заказчик дает свое согласие на получение от Компании любой информации коммерческого и/или информационного и/или рекламного характера через любые каналы коммуникации, в том числе по почте, смс, сети подвижной радиотелефонной связи, электронной почте, телефону, иным средствам связи. Заказчик вправе отказаться от получения от Компании вышеуказанной информации путем направления в Компанию соответствующего письменного уведомления по адресу Компании. Указанное письменное уведомление может быть составлено Заказчиком в свободной форме.',
      },
    ],
  },
  {
    title: '6. СТОИМОСТЬ РАБОТ И УСЛУГ КОМПАНИИ И УСЛОВИЯ ИХ ОПЛАТЫ',
    paragarphs: [
      {
        text: '6.1. Стоимость Работ и Услуг определяется в отношении каждого Заказа при его оформлении (в соответствии с порядком, предусмотренным пунктом 3.1 Оферты), исходя из характеристик Заказа, цен и скидок, указанных в момент оформления Заказа для соответствующих характеристикам Заказа Цветочных композиций на Сайте www.sendflowers.ru.',
      },
      {
        text: '6.2. Стоимость Работ и Услуг определяется исходя из цен, указанных на Сайте в рублях Российской Федерации (независимо от наличия на Сайте в момент оформления Заказа цен, номинированных в других валютах). Указанная стоимость включает в себя налог на добавленную стоимость, рассчитанный по ставке 20 процентов.',
      },
      {
        text: '6.3. В случае оформления Заказа с характеристиками, которые не соответствуют ни одному из предложений Компании, размещенных в момент оформления Заказа на Сайте, стоимость Работ и Услуг Компании в отношении такого Заказа дополнительно согласуется Заказчиком с представителем Компании (посредством телефонной, факсимильной или иной связи).',
      },
      {
        text: '6.4. Оплата Работ и Услуг производится Заказчиком в российских рублях авансовым платежом в размере 100% (Ста процентов) стоимости Работ и Услуг по соответствующему Заказу.',
      },
      {
        text: '6.5. Оплата Работ и Услуг может быть осуществлена Заказчиком в наличной или безналичной форме (путем перечисления денежных средств на расчетный счет Компании, в том числе с использованием платежных карт и иных платежных систем) любым из способов, указанных в момент оформления Заказа на Сайте по адресу https://sendflowers.ru. При этом выбор и использование способа и формы оплаты Работ и Услуг производится Заказчиком по собственному усмотрению.',
      },
      {
        text: '6.6. Безопасность, конфиденциальность, а также иные условия использования выбранных Заказчиком способа/формы оплаты выходят за рамки Оферты (Договора) и регулируются договорами между Заказчиком и соответствующими организациями. Последнее не относится к наличной форме оплаты непосредственно Компании.',
      },
      {
        text: '6.7. В случае оплаты почтовым/телеграфным переводом, Заказчик обязуется уведомить Компанию о произведенном платеже с предоставлением копии платежного документа с отметкой исполняющего банка в отсканированном виде по электронной почте или на факс Компании. При этом Заказ принимается к исполнению не ранее, чем соответствующие средства поступят на расчётный счёт Компании.',
      },
      {
        text: '6.8. При безналичных формах оплаты Работы и Услуги считаются оплаченными Заказчиком с момента получения Компанией подтверждения из банка Компании о поступлении суммы оплаты на расчетный счет Компании. В исключительных случаях по усмотрению Компании подтверждением факта оплаты могут служить: а) копия платежного поручения или копия квитанции о внесении наличных средств через отделение банка с печатью этого отделения банка; б) верификация Компанией факта платежа в пользу Компании через платежную систему в случае осуществления Заказчиком электронного платежа.',
      },
    ],
  },
  {
    title: '7. СРОК ДЕЙСТВИЯ И ИЗМЕНЕНИЕ УСЛОВИЙ ОФЕРТЫ',
    paragarphs: [
      {
        text: '7.1. Оферта вступает в силу с момента опубликования на Сайте в сети Интернет по адресу https://sendflowers.ru/rus/page/quality и действует до момента отзыва Оферты Компанией.',
      },
      {
        text: '7.2. Компания оставляет за собой право внести изменения в условия Оферты и/или отозвать Оферту в любой момент по своему усмотрению. В случае внесения Компанией изменений в Оферту, такие изменения вступают в силу с момента опубликования на Сайте, если иной срок вступления изменений в силу не определен дополнительно при их публикации.',
      },
    ],
  },
  {
    title: '8.  СРОК ДЕЙСТВИЯ, ИЗМЕНЕНИЕ И РАСТОРЖЕНИЕ ДОГОВОРА',
    paragarphs: [
      {
        text: '8.1 Договор вступает в силу с момента Акцепта Оферты и действует до момента исполнения Компанией обязательств по выполнению Работ и оказанию Услуг в объеме, соответствующем размеру произведенной Заказчиком по Договору оплаты, либо до момента расторжения Договора.',
      },
      {
        text: '8.3 В течение срока действия Договора, но не менее чем за 1 рабочий день до даты исполнения Заказа Заказчик вправе внести изменения в характеристики Заказа адекватные изменяемому Заказу (в том числе, его стоимости). Такие изменения вступают в силу с момента подтверждения Компанией Заказчику возможности выполнения Работ и оказания Услуг на измененных Заказчиком условиях.',
      },
      {
        text: '8.4 Договор может быть расторгнут:',
      },
      {
        text: '- Заказчиком или Компанией в случаях, предусмотренных действующим законодательством РФ;',
      },
      {
        text: '- Компанией в любой момент посредством направления Заказчику соответствующего уведомления.',
      },
    ],
  },
  {
    title: '9. ГАРАНТИИ',
    paragarphs: [
      {
        text: '9.1 В течение срока действия Договора Компания предпримет все усилия для исполнения Заказа в соответствии со всеми его характеристиками, указанными Заказчиком. При этом Компания не гарантирует совершенно точного исполнения всех пожеланий Заказчика при исполнении Заказа.',
      },
      {
        text: '9.2 За исключением гарантий, прямо указанных в Оферте, Компания не предоставляет никаких иных прямых или подразумеваемых гарантий по Договору и прямо отказывается от каких-либо гарантий или условий в отношении соответствия Работ и Услуг конкретным целям Заказчика, или их пригодности для иных целей и задач.',
      },
      {
        text: '9.3 Принимая условия настоящей Оферты путем Акцепта, Заказчик заверяет Компанию и гарантирует, что он:',
      },
      {
        text: '9.3.1 заключает Договор добровольно и при этом полностью ознакомился с условиями Оферты, понимает предмет Договора, значение и последствия своих действий в отношении заключения и исполнения Договора.',
      },
      {
        text: '9.3.2 обладает всеми правами и полномочиями, необходимыми для заключения и исполнения Договора.',
      },
      {
        text: '9.3.3 использует достоверные данные и информацию, передаваемые Компании при регистрации на Сайте,  оформлении Заказа и платежных документов, на основании которых производится оплата Работ и Услуг.',
      },
    ],
  },
  {
    title: '10. ОТВЕТСТВЕННОСТЬ КОМПАНИИ',
    paragarphs: [
      {
        text: '10.1. В случае, если по какой-либо причине Компания не начнет исполнение Заказа, или начнет исполнение Заказа с нарушением сроков, ответственность Компании за допущенное нарушение Договора и средства правовой защиты Заказчика в отношении такого нарушения ограничиваются исключительно продлением срока исполнения Заказа до момента, согласованного с Заказчиком.',
      },
      {
        text: '10.2. Компания ни при каких обстоятельствах не несет ответственности по Договору за а) какие-либо действия/бездействие, являющиеся прямым или косвенным результатом действий/бездействия каких-либо третьих сторон; б) какие-либо косвенные убытки и/или упущенную выгоду Заказчика и/или третьих сторон вне зависимости от того, могла Компания предвидеть возможность таких убытков или нет; в) использование (невозможность использования) и какие бы то ни было последствия использования (невозможности использования) Заказчиком выбранной им формы оплаты Работ и Услуг',
      },
      {
        text: '10.3. Компания освобождается от ответственности за несвоевременную доставку Продукции в случаях, невыполнения Заказчиком каких-либо из условий оформления Заказа, предусмотренных пунктом 3.1 Оферты, а также в случаях соответствия Работ и Услуг условиям, приведенным в пунктах 3.3 – 3.5 Оферты.',
      },
      {
        text: '10.4. Совокупная ответственность Компании по Договору, по любому иску или претензии в отношении Договора ограничивается суммой платежа, уплаченного Компании Заказчиком в рамках Заказа, исполнение, неисполнение или некачественное исполнение которого явились причинами наступления ответственности Компании.',
      },
      {
        text: '10.5. Не вступая в противоречие с указанным выше, Компания освобождается от ответственности за нарушение условий Договора, если такое нарушение вызвано действием обстоятельств непреодолимой силы (форс-мажор), включая: действия органов государственной власти, пожар, наводнение, землетрясение, другие стихийные действия, отсутствие электроэнергии и/или сбои работы компьютерной сети, забастовки, гражданские волнения, беспорядки, любые иные обстоятельства, не ограничиваясь перечисленным, которые могут повлиять на исполнение Компанией Договора и неподконтрольные Компании.',
      },
    ],
  },
  {
    title: '11. ОТВЕТСТВЕННОСТЬ ЗАКАЗЧИКА',
    paragarphs: [
      {
        text: '11.1. Заказчик несет всю ответственность:',
      },
      {
        text: 'а) за несоблюдение требований действующего законодательства при осуществлении им любых действий в качестве Заказчика,',
      },
      {
        text: 'б) за недостоверность сведений, переданных им Компании,',
      },
      {
        text: 'в) за невыполнение гарантий, содержащихся в пункте 9.3 настоящей Оферты.',
      },
      {
        text: '11.2. Заказчик обязуется своими силами и за свой счет разрешать все споры и урегулировать все претензии и иски третьих лиц, предписания и требования уполномоченных государственных и муниципальных исполнительных органов власти, поступившие как в адрес Заказчика, так и в адрес Компании, в связи с использованием результатов Работ и Услуг (в том числе их получателями), а также возместить все убытки и расходы, понесенные Компанией в связи с такими претензиями и исками.',
      },
      {
        text: '11.3. В случае нарушения Заказчиком условий Договора Компания вправе приостановить выполнение Работ и оказание Услуг до момента устранения Заказчиком допущенных нарушений и возмещения (компенсации) причиненных Компании таким нарушением убытков и/или расторгнуть Договор с направлением соответствующего уведомления Заказчику. При этом Компания вправе взыскать с Заказчика неустойку в размере разницы между суммой уплаченного Заказчиком в соответствии с разделом 6 Оферты авансового платежа и стоимостью результатов Работ и Услуг, фактически полученных Заказчиком до момента такого расторжения Договора.',
      },
    ],
  },
  {
    title: '12. ПРОЧИЕ УСЛОВИЯ',
    paragarphs: [
      {
        text: '12.1. Все вопросы, неурегулированные Офертой или Договором, регламентируются действующим законодательством Российской Федерации. Споры и разногласия, которые могут возникнуть при исполнении настоящего Договора, будут по возможности разрешаться путем переговоров между сторонами Договора.',
      },
      {
        text: '12.2. Любое уведомление, требуемое или допускаемое Договором, должно быть совершено в письменном виде. Все уведомления должны быть написаны на русском языке и направлены уведомляющей стороной в адрес другой стороны по электронной почте, по факсу или заказным письмом с уведомлением о вручении.',
      },
      {
        text: '12.3. Целостность Договора. Договор представляет собой полную договоренность между Компанией и Заказчиком. Компания не принимает на себя никаких условий и обязательств в отношении предмета Оферты (Заказа), не указанных в Оферте. Исключение могут составлять случаи, когда такие условия или обязательства зафиксированы в письменном виде и подписаны Компанией и Заказчиком.',
      },
      {
        text: '12.4. Раздельность положений. Если какое-либо из условий Оферты признано недействительным или незаконным, или не может вступить в силу в соответствии с действующим законодательством, такое положение должно быть выделено из Оферты и заменено новым положением, максимально отвечающим изначальным намерениям, содержавшимся в Оферте, при этом остальные положения Оферты (Договора) не меняются и остаются в силе.',
      },
    ],
  },
  {
    title: '',
    paragarphs: [
      {
        text: 'Опубликовано «01» февраля 2022 г.',
      },
    ],
  },
];

export const TermsOfService: React.FunctionComponent<RouteComponentProps> =
  () => {
    const context = React.useContext(AppContext);

    const onClick = () => {
      clientHistory.goBack();
    };

    React.useEffect(() => {
      context.AssistantService.openTermsOfService();

      const unsubscribeFromOnGoToCheckout =
        context.SmartAppAssistantHelper.conditionalOnData(
          isGoBackToCheckoutCommand,
          onClick
        );

      return () => {
        unsubscribeFromOnGoToCheckout();
      };
    }, []);

    const onFocus = useScrollIntoView();

    return (
      <>
        <ApplicationHeader title={HEADER_TITLE} isBack={true} />

        <RootWrapper>
          <PageContainer>
            <Title>{TITLE}</Title>
            {termsOfService.map((section, index) => (
              <ParagraphWrapper key={index}>
                <ParagraphTitle>{section.title}</ParagraphTitle>
                {section.paragarphs.map((paragarph, index) => (
                  <ParagraphText key={index}>{paragarph.text}</ParagraphText>
                ))}
              </ParagraphWrapper>
            ))}
            <BackButtonSpacer />
            <DeviceQuery on={[DeviceQueryEnum.mobile]}>
              <BackButton
                view="primary"
                size="m"
                text={BUTTON_TEXT}
                onClick={onClick}
              />
            </DeviceQuery>
            <ScrollablePgeComponent />
          </PageContainer>
        </RootWrapper>
      </>
    );
  };
