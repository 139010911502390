import * as React from 'react';
import {
  Headline4,
  P,
  Price,
  Spinner,
  withAutoFocus,
} from '@salutejs/plasma-ui';
import { LoadingStateEnum } from 'src/types/enums/LoadingStateEnum';
import { clientHistory } from 'src/history';
import {
  CardButton,
  TextWrapper,
  Link,
  Title,
  Wrapper,
  ConfirmationText,
  ErrorBadge,
} from './StyledComponents';
import { AppContext } from 'src/lib/contexts/AppContext';
import { DeviceQuery } from '../DeviceQuery';
import { DeviceQueryEnum } from 'src/enums/DeviceQueryEnum';

const BUTTON_TEXT = 'К оплате';
const ORDER_SUM = 'Предварительная\nсумма заказа';
const DELIVERY_PRICE = 'Стоимость доставки';
const FREE_OF_CHARGE = 'Бесплатно';
const AGREEMENT = 'Условия продажи';
const PRIVACY_POLICY = 'Политика конфиденциальности';

interface Props {
  buttonText?: string;
  orderSum: number;
  deliveryPrice: number;
  loadingState: LoadingStateEnum;
  isTerms?: boolean;
  isPrivacy?: boolean;
  showDelivery?: boolean;
  onClick: (address?: string) => void;
}

export const CartInfoCard: React.FunctionComponent<Props> = (props) => {
  const context = React.useContext(AppContext);

  const onCartClick = React.useCallback(() => {
    props.onClick();
  }, []);

  const onTermsClick = () => {
    context.AssistantService.onGoToAgreement();
  };

  const onPrivacyPolicyClick = () => {
    context.AssistantService.onGoToPrivacyPolicy();
  };

  return (
    <Wrapper>
      <Title>{DELIVERY_PRICE}</Title>
      {props.loadingState === LoadingStateEnum.fail && <Spinner />}
      {props.showDelivery && (
        <>
          {props.loadingState === LoadingStateEnum.success &&
            props.deliveryPrice === 0 && (
              <TextWrapper>
                <Headline4>{FREE_OF_CHARGE}</Headline4>
              </TextWrapper>
            )}
          {props.loadingState === LoadingStateEnum.success &&
            props.deliveryPrice > 0 &&
            !context.CartService.cartStore.cartValidationError && (
              <TextWrapper>
                <Price>{props.deliveryPrice}</Price>
              </TextWrapper>
            )}
        </>
      )}

      {!!context.CartService.cartStore.cartValidationError && (
        <ErrorBadge>
          {context.CartService.cartStore.cartValidationError}
        </ErrorBadge>
      )}

      <DeviceQuery off={[DeviceQueryEnum.sberPortal]}>
        <CardButton
          view="primary"
          size="s"
          onClick={onCartClick}
          autoFocus
          disabled={!!context.CartService.cartStore.cartValidationError}
        >
          {props.buttonText ? props.buttonText : BUTTON_TEXT}
        </CardButton>
      </DeviceQuery>

      <DeviceQuery on={[DeviceQueryEnum.sberPortal]}>
        <CardButton
          view="primary"
          size="m"
          onClick={onCartClick}
          disabled={!!context.CartService.cartStore.cartValidationError}
        >
          {props.buttonText ? props.buttonText : BUTTON_TEXT}
        </CardButton>
      </DeviceQuery>

      <ConfirmationText>
        Переходя к оплате вы соглашаетесь с условиями продажи и политикой
        конфиденциальности
      </ConfirmationText>

      {props.isTerms && <Link onClick={onTermsClick}>{AGREEMENT}</Link>}

      {props.isPrivacy && (
        <Link onClick={onPrivacyPolicyClick}>{PRIVACY_POLICY}</Link>
      )}
    </Wrapper>
  );
};

CartInfoCard.defaultProps = {
  showDelivery: true,
};
