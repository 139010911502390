import { inject, injectable } from 'inversify';
import { Identifiers } from '../identifiers';
import { UserStore } from '../stores/UserStore';
import { UserServiceInterface } from '../interfaces/services/UserServiceInterface';
import { IDBStorageHelper } from '../helpers/IDBStorageHelper';
import { UserInfoType } from 'src/types/UserInfoType';

@injectable()
export class UserService implements UserServiceInterface {
  @inject(Identifiers.stores.UserStore)
  public userStore: UserStore;

  @inject(Identifiers.helpers.StorageHelper)
  private storageHelper: IDBStorageHelper;

  static userStorageKey = 'userStorageKey';

  async setToken(token: string): Promise<void> {
    this.userStore.updateState({
      token,
    });
  }

  async getUserInfo(): Promise<void> {
    const userString = await this.storageHelper.getItem(
      UserService.userStorageKey
    );

    let user;

    try {
      user = JSON.parse(userString);
    } catch (error) {
      return null;
    }

    this.userStore.updateState({
      user,
    });
  }

  async setUserId(sub: string): Promise<void> {
    const id = sub;
    this.userStore.updateState({
      id,
    });
  }

  setUserInfo = async ({ name, phone, email }: UserInfoType) => {
    const user = { name, phone, email };

    try {
      this.storageHelper.setItem(
        UserService.userStorageKey,
        JSON.stringify(user)
      );
    } catch (error) {
      console.error(error);
    }

    await this.getUserInfo();
  };
}
