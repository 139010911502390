import * as React from 'react';
import {
  CarouselGridWrapper,
  Carousel,
  PaginationDot,
  PaginationDots,
} from '@salutejs/plasma-ui';
import { ImageType } from 'src/types/ImageType';
import {
  ApplicationCarouselWrapper,
  PaginationDotsWrapper,
  ProductImage,
  ProductImageWrapper,
  ProductImageCarouselWrapper,
  StyledCarouselCol,
  ProductImageCarouselOutterWrapper,
} from './StyledComponents';
import { screenResolution } from 'src/constants';
import { ProductType } from 'src/types';
import placeholder from '../../assets/empty.svg';

export const ProductImageCarousel: React.FunctionComponent<{
  product: ProductType;
}> = (props) => {
  const [index, setIndex] = React.useState(0);

  return (
    <ProductImageCarouselOutterWrapper>
      <ProductImageCarouselWrapper>
        <ApplicationCarouselWrapper>
          <CarouselGridWrapper className="carousel">
            <Carousel
              axis="x"
              index={index}
              scrollSnapType="mandatory"
              detectActive
              detectThreshold={0.5}
              onIndexChange={(i) => setIndex(i)}
            >
              {props.product.images2.map((image: ImageType) => (
                <StyledCarouselCol key={`${image.position}`} tabIndex={0}>
                  <ProductImageWrapper>
                    <ProductImage
                      src={image.lowres_url || placeholder}
                      srcSet={`${image.lowres_url} ${screenResolution.s}w, ${image.midres_url} ${screenResolution.m}w`}
                      alt={props.product.title_short}
                    />
                  </ProductImageWrapper>
                </StyledCarouselCol>
              ))}
            </Carousel>
          </CarouselGridWrapper>
        </ApplicationCarouselWrapper>
        <PaginationDotsWrapper>
          <PaginationDots>
            {Array(props.product.images.length)
              .fill(1)
              .map((_, i) => (
                <PaginationDot
                  key={`item:${i}`}
                  isActive={i === index}
                  onClick={() => setIndex(i)}
                />
              ))}
          </PaginationDots>
        </PaginationDotsWrapper>
      </ProductImageCarouselWrapper>
    </ProductImageCarouselOutterWrapper>
  );
};
