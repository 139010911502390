import { CarouselCol } from '@salutejs/plasma-ui';
import styled from 'styled-components';

export const StyledCarouselCol = styled(CarouselCol)`
  padding-left: 0;
  padding-right: 1rem;
  display: flex;
`;

export const WrapperOuter = styled.div`
  align-self: center;
  display; inline-block;
`;
