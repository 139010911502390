import {
  createAssistant,
  createSmartappDebugger,
} from '@sberdevices/assistant-client';
import { inject, injectable } from 'inversify';
import { ProductType, SberCharacterEnum, SberInsetsType } from 'src/types';
import { AssistantClientInterface } from 'src/types/AssistantClientInterface';
import { AssistantCommandType } from 'src/types/AssistantCommands/AssistantCommandType';
import { CharacterAssistantCommand } from 'src/types/AssistantCommands/CharacterAssistantCommand';
import { AssistantCommandTypeEnum } from 'src/types/AssistantCommandTypeEnum';
import { UnsubscribeFunctionType } from 'src/types/UnsubscribeFunctionType';
import { CategoriesListActionType } from 'src/types/SaluteActions/CategoriesListActionType';
import { PositionsCountChangeActiontype } from 'src/types/SaluteActions/PositionsCountChangeActiontype';
import { CategoryActionType } from 'src/types/SaluteActions/CategoryActionType';
import { SmartAppAssistantHelper } from '../helpers/SmartAppAssistantHelper';
import { CategoriesService } from './CategoriesService';
import { CartService } from './CartService';
import { GenericSmartAppDataCommand } from 'src/types/AssistantCommands/GenericSmartAppDataCommand';
import { SelectCategoryCommand } from 'src/types/AssistantCommands/SelectCategoryCommand';
import { InitCommand } from 'src/types/AssistantCommands/InitCommand';
import { isSelectCategoryCommand } from 'src/types/AssistantCommands/SmartAppDataTypeCheckers/isSelectCategoryCommand';
import { isGoToAddressCommand } from 'src/types/AssistantCommands/SmartAppDataTypeCheckers/isGoToAddressCommand';
import { isGoToTimeCommand } from 'src/types/AssistantCommands/SmartAppDataTypeCheckers/isGoToTimeCommand';
import { isGenericSmartAppDataCommand } from 'src/types/AssistantCommands/SmartAppDataTypeCheckers/isGenericSmartAppDataCommand';
import { isOpenCartCommand } from 'src/types/AssistantCommands/SmartAppDataTypeCheckers/isOpenCartCommand';
import { isGoBackCommand } from 'src/types/AssistantCommands/SmartAppDataTypeCheckers/isGoBackCommand';
import { isGoToProductCommand } from 'src/types/AssistantCommands/SmartAppDataTypeCheckers/isGoToProductCommand';
import { clientHistory } from 'src/history';
import { CartOpenActionType } from 'src/types/SaluteActions/CartOpenActionType';
import { TimeOpenActionType } from 'src/types/SaluteActions/TimeOpenActionType';
import { TimeCheckActionType } from 'src/types/SaluteActions/TimeCheckActionType';
import { PaymentActionType } from 'src/types/SaluteActions/PaymentActionType';
import { Identifiers } from '../identifiers';
import { MakeHelpCallActionType } from 'src/types/SaluteActions/MakeHelpCallActionType';
import { OpenProductActionType } from 'src/types/SaluteActions/OpenProductActionType';
import { GoToProductCommand } from 'src/types/AssistantCommands/GoToProductCommand';
import { UserInfoOpenActionType } from 'src/types/SaluteActions/UserInfoOpenActionType';
import { AddressSelectActionType } from 'src/types/SaluteActions/AddressSelectActionType';
import { AssistantServiceInterface } from '../interfaces/services/AssistantServiceInterface';
import { ASK_ADDRESS, FILTER_NAMES } from 'src/constants';
import { UserInitActionType } from 'src/types/SaluteActions/UserInitActionType';
import { AddressAddActionType } from 'src/types/SaluteActions/AddressAddActionType';
import { isGoToUserNameCommand } from 'src/types/AssistantCommands/SmartAppDataTypeCheckers/isGoToUserNameCommand';
import { UserNameOpenActionType } from 'src/types/SaluteActions/UserNameOpenActionType';
import { UserPhoneOpenActionType } from 'src/types/SaluteActions/UserPhoneOpenActionType';
import { CommentOpenActionType } from 'src/types/SaluteActions/CommentOpenActionType';
import { PrivacyPolicyOpenActionType } from 'src/types/SaluteActions/PrivacyPolicyOpenActionType';
import { isGoToCommentCommand } from 'src/types/AssistantCommands/SmartAppDataTypeCheckers/isGoToCommentCommand';
import { ChangeAddressActionType } from 'src/types/SaluteActions/ChangeAddressActionType';
import { ParsedAddressesActionType } from 'src/types/SaluteActions/ParsedAddressesActionType';
import { isSkipCommentCommand } from 'src/types/AssistantCommands/SmartAppDataTypeCheckers/isSkipCommentCommand';
import { EnterUserNameActionType } from 'src/types/SaluteActions/EnterUserNameActionType';
import { EnterCommentActionType } from 'src/types/SaluteActions/EnterCommentActionType';
import { EnterAddressActionType } from 'src/types/SaluteActions/EnterAddressActionType';
import { EnterUserPhoneActionType } from 'src/types/SaluteActions/EnterUserPhoneActionType';
import { EnterExactTimeActionType } from 'src/types/SaluteActions/EnterExactTimeActionType';
import { CheckoutOpenActionType } from 'src/types/SaluteActions/CheckoutOpenActionType';
import { isGoToAgreementCommand } from 'src/types/AssistantCommands/SmartAppDataTypeCheckers/isGoToAgreementCommand';
import { isGoToPaymentCommand } from 'src/types/AssistantCommands/SmartAppDataTypeCheckers/isGoToPaymentCommand';
import { isCancelPaymentCommand } from 'src/types/AssistantCommands/SmartAppDataTypeCheckers/isCancelPaymentCommand';
import { ChangePaymentStatusActionType } from 'src/types/SaluteActions/ChangePaymentStatusActionType';
import { OrdersOpenActionType } from 'src/types/SaluteActions/OrdersOpenActionType';
import { UserConfirmationOpenActionType } from 'src/types/SaluteActions/UserConfirmationOpenActionType';
import { OnboardingOpenActionType } from 'src/types/SaluteActions/OnboardingOpenActionType';
import { TermsOfServiceOpenActionType } from 'src/types/SaluteActions/TermsOfServiceOpenActionType';
import { EnterInputActionType } from 'src/types/SaluteActions/EnterInputActionType';
import { isGoToHelpCommand } from 'src/types/AssistantCommands/SmartAppDataTypeCheckers/isGoToHelpCommand';
import { OpenOnboardingCommand } from 'src/types/AssistantCommands/OpenOnboardingCommand';
import { isGoToPrivacyPolicyCommand } from 'src/types/AssistantCommands/SmartAppDataTypeCheckers/isGoToPrivacyPolicyCommand';
import { changeQuotes } from 'src/utils/changeQuotes';
import { OpenSearchActionType } from 'src/types/SaluteActions/OpenSearchActionType';
import { isGoToSearchCommand } from 'src/types/AssistantCommands/SmartAppDataTypeCheckers/isGoToSearchCommand';
import { isMakeSearchCommand } from 'src/types/AssistantCommands/SmartAppDataTypeCheckers/isMakeSearchCommand';
import { isGlobalMakeSearchCommand } from 'src/types/AssistantCommands/SmartAppDataTypeCheckers/isGlobalMakeSearchCommand';
import { MakeGlobalSearchCommand } from 'src/types/AssistantCommands/MakeGlobalSearchCommand';

@injectable()
export class AssistantService implements AssistantServiceInterface {
  @inject(Identifiers.helpers.SmartAppAssistantHelper)
  private smartAppAssistantHelper: SmartAppAssistantHelper;

  @inject(Identifiers.services.CategoriesService)
  private categoriesService: CategoriesService;

  @inject(Identifiers.services.CartService)
  private cartService: CartService;

  close(): void {
    this.smartAppAssistantHelper.close();
  }

  changePaymentStatus(status: string): void {
    const action: ChangePaymentStatusActionType = {
      action_id: 'changePaymentStatus',
      payload: { status },
    };

    this.smartAppAssistantHelper.sendAction(action);
  }

  enterAddress(): void {
    const action: EnterAddressActionType = {
      action_id: 'enterAddress',
    };

    this.smartAppAssistantHelper.sendAction(action);
  }

  enterComment(): void {
    const action: EnterCommentActionType = {
      action_id: 'enterComment',
    };

    this.smartAppAssistantHelper.sendAction(action);
  }

  enterExactTime(): void {
    const action: EnterExactTimeActionType = {
      action_id: 'enterExactTime',
    };

    this.smartAppAssistantHelper.sendAction(action);
  }

  enterTextInput(screen?: string): void {
    const tips = [{ text: 'Продолжить' }];

    if (screen === 'comment') {
      tips.push({ text: 'Пропустить' });
    } else if (screen === 'name') {
      tips.push({ text: 'Я сам/а получу цветы' });
    } else if (screen === 'address') {
      tips.push({ text: 'Узнать адрес у получателя' });
    }

    const action: EnterInputActionType = {
      action_id: 'enterInput',
      payload: { tips },
    };

    this.smartAppAssistantHelper.sendAction(action);
  }

  enterUserName(name: string): void {
    const action: EnterUserNameActionType = {
      action_id: 'enterUserName',
      payload: {
        name,
      },
    };

    this.smartAppAssistantHelper.sendAction(action);
  }

  enterUserPhone(phone: string): void {
    const action: EnterUserPhoneActionType = {
      action_id: 'enterUserPhone',
      payload: {
        phone,
      },
    };

    this.smartAppAssistantHelper.sendAction(action);
  }

  initializeAssistant(): void {
    let assistant: AssistantClientInterface<any>;
    let smartAppState: any;

    console.info(process.env.REACT_APP_SMART_APP_ENV);
    if (process.env.REACT_APP_SMART_APP_ENV === 'development') {
      assistant = createSmartappDebugger({
        token: process.env.REACT_APP_SMART_APP_TOKEN,
        initPhrase: `Запусти ${process.env.REACT_APP_SMART_APP_NAME}`,
        getState: () => smartAppState,
      });

      console.info(`Запусти ${process.env.REACT_APP_SMART_APP_NAME}`);
    } else {
      assistant = createAssistant({
        getState: () => smartAppState,
      });
    }

    this.smartAppAssistantHelper.setAssistantClient(assistant);
    this.smartAppAssistantHelper.createAssistantEvents();
    this.smartAppAssistantHelper.conditionalOnData(
      isGenericSmartAppDataCommand,
      this.onSmartAppData
    );
  }

  makeHelpCall = () => {
    const action: MakeHelpCallActionType = {
      action_id: 'makeHelpCall',
    };

    this.smartAppAssistantHelper.sendAction(action);
  };

  setMainCategories(): void {
    const categories = this.categoriesService.categoriesStore.categories.map(
      (category) => category.title
    );
    const tips = this.categoriesService.categoriesStore.categories
      .slice(0, 2)
      .map((category) => ({
        text: 'Открой категорию «' + changeQuotes(category.title) + '»',
      }));

    const action: CategoriesListActionType = {
      action_id: 'categoriesList',
      payload: {
        categories,
        tips,
      },
    };

    this.smartAppAssistantHelper.sendAction(action);
  }

  setCategoryProducts(): void {
    const category = this.categoriesService.categoriesStore.currentCategory;
    const categoryProducts =
      this.categoriesService.categoriesStore.currentProducts;
    const products: string[] = categoryProducts.map(
      (product) => product.title_short
    );

    const tips: { text: string }[] = [];

    if (categoryProducts.length > 0) {
      tips.push({
        text: `Покажи ${changeQuotes(categoryProducts[0].title_short)}`,
      });

      if (!!categoryProducts[1]) {
        tips.push({
          text: `Покажи ${changeQuotes(categoryProducts[1].title_short)}`,
        });
      }
    }

    const subcategoriesNames = category.children.map((item) => item.title);
    const categoryName = category.title;

    const action: CategoryActionType = {
      action_id: 'category',
      payload: {
        products,
        subcategories: subcategoriesNames,
        tips,
        categoryName,
        filters: FILTER_NAMES,
      },
    };
    this.smartAppAssistantHelper.sendAction(action);
  }

  openCart(isSilent: boolean): void {
    const positions = this.cartService.cartStore.cart.positions.map(
      (position) =>
        position.item.prices.find((item) => item.id === position.id).title
    );

    const action: CartOpenActionType = {
      action_id: 'cart',
      payload: {
        positions,
        isSilent,
      },
    };

    this.smartAppAssistantHelper.sendAction(action);
  }

  openCheckout(): void {
    const action: CheckoutOpenActionType = {
      action_id: 'checkout',
    };

    this.smartAppAssistantHelper.sendAction(action);
  }

  openComment(): void {
    const action: CommentOpenActionType = {
      action_id: 'comment',
    };

    this.smartAppAssistantHelper.sendAction(action);
  }

  openAddress(): void {
    const action: AddressAddActionType = {
      action_id: 'addAddress',
    };

    this.smartAppAssistantHelper.sendAction(action);
  }

  openOnboarding(): void {
    const action: OnboardingOpenActionType = {
      action_id: 'onboarding',
    };

    this.smartAppAssistantHelper.sendAction(action);
  }

  openOrders(status: string): void {
    const action: OrdersOpenActionType = {
      action_id: 'orders',
      payload: {
        status,
      },
    };

    this.smartAppAssistantHelper.sendAction(action);
  }

  openTermsOfService(): void {
    const action: TermsOfServiceOpenActionType = {
      action_id: 'termsOfService',
    };

    this.smartAppAssistantHelper.sendAction(action);
  }

  openUserConfirmation(): void {
    const action: UserConfirmationOpenActionType = {
      action_id: 'userConfirmation',
    };

    this.smartAppAssistantHelper.sendAction(action);
  }

  openUserName(): void {
    const action: UserNameOpenActionType = {
      action_id: 'userName',
    };

    this.smartAppAssistantHelper.sendAction(action);
  }

  openUserPhone(): void {
    const action: UserPhoneOpenActionType = {
      action_id: 'userPhone',
    };

    this.smartAppAssistantHelper.sendAction(action);
  }

  openUserInfo(): void {
    const action: UserInfoOpenActionType = {
      action_id: 'userInfo',
    };

    this.smartAppAssistantHelper.sendAction(action);
  }

  openTime(dates: string[], intervals: string[]): void {
    const action: TimeOpenActionType = {
      action_id: 'time',
      payload: {
        dates,
        intervals,
      },
    };

    this.smartAppAssistantHelper.sendAction(action);
  }

  selectAddress(address: string): void {
    const action: AddressSelectActionType = {
      action_id: 'selectAddress',
      payload: {
        address,
      },
    };

    this.smartAppAssistantHelper.sendAction(action);
  }

  changeAddress(): void {
    const action: ChangeAddressActionType = {
      action_id: 'changeAddress',
    };

    this.smartAppAssistantHelper.sendAction(action);
  }

  checkTime(dateTime: string): void {
    const action: TimeCheckActionType = {
      action_id: 'checkTime',
      payload: {
        dateTime,
      },
    };

    this.smartAppAssistantHelper.sendAction(action);
  }

  changePositionsCount(positionsCount: number): void {
    const action: PositionsCountChangeActiontype = {
      action_id: 'positionsCountChange',
      payload: {
        positionsCount,
      },
    };

    this.smartAppAssistantHelper.sendAction(action);
  }

  openPrivacyPolicy = () => {
    const action: PrivacyPolicyOpenActionType = {
      action_id: 'privacyPolicy',
    };

    this.smartAppAssistantHelper.sendAction(action);
  };

  openProduct = (
    product: ProductType,
    isSilent: boolean,
    isInCart: boolean
  ) => {
    const productTypes = product.prices.map((price) => price.title);
    const tips =
      product.prices.length >= 2
        ? product.prices.map((price) => ({
            text: `Добавь в заказ ${price.title}`,
          }))
        : [];
    const productName = product.title_short;
    const action: OpenProductActionType = {
      action_id: 'openProduct',
      payload: {
        isSilent,
        productName,
        productTypes,
        tips,
        isInCart,
      },
    };

    this.smartAppAssistantHelper.sendAction(action);
  };

  makePayment = async () => {
    const invoiceId = await this.cartService.getInvoiceId();

    const action: PaymentActionType = {
      action_id: 'payment',
      payload: {
        invoiceId,
      },
    };

    this.smartAppAssistantHelper.sendAction(action);
  };

  parseAddresses = async () => {
    const action: ParsedAddressesActionType = {
      action_id: 'parsedAddresses',
    };

    this.smartAppAssistantHelper.sendAction(action);
  };

  sendUserInfo = () => {
    const action: UserInitActionType = {
      action_id: 'userInit',
    };

    this.smartAppAssistantHelper.sendAction(action);
  };

  onInit = (callback: (data: InitCommand) => void): UnsubscribeFunctionType => {
    return this.smartAppAssistantHelper.conditionalOnData(
      (data: InitCommand): boolean => {
        return (
          data.type === AssistantCommandTypeEnum.smartAppData &&
          data.smart_app_data.action === 'init'
        );
      },
      callback
    );
  };

  onInsets(
    callback: (props: { insets: SberInsetsType }) => void
  ): UnsubscribeFunctionType {
    return this.smartAppAssistantHelper.conditionalOnData(
      (data: AssistantCommandType): boolean => {
        return data.type === AssistantCommandTypeEnum.insets;
      },
      callback
    );
  }

  onOpenOnboarding = (
    callback: (data: OpenOnboardingCommand) => void
  ): UnsubscribeFunctionType => {
    return this.smartAppAssistantHelper.conditionalOnData(
      (data: OpenOnboardingCommand): boolean => {
        return (
          data.type === AssistantCommandTypeEnum.smartAppData &&
          data.smart_app_data.action === 'openOnboarding'
        );
      },
      callback
    );
  };

  onSmartAppData = (data: GenericSmartAppDataCommand) => {
    console.info({ data });

    this.smartAppCommandReactions.forEach((entry) => {
      if (entry.validator(data)) {
        entry.callback(data);
      }
    });
  };

  onCharacter(
    callback: (char: SberCharacterEnum) => void
  ): UnsubscribeFunctionType {
    return this.smartAppAssistantHelper.conditionalOnData(
      (data: AssistantCommandType): boolean => {
        return data.type === AssistantCommandTypeEnum.character;
      },
      (data: CharacterAssistantCommand) => callback(data.character.id)
    );
  }

  onCancelPayment = () => {
    clientHistory.push('/');
  };

  onCategorySelect = (data: SelectCategoryCommand) => {
    console.info(`select category ${data.smart_app_data.category}`);
    const categoryName = data.smart_app_data.category;
    const category = this.categoriesService.categoriesStore.categories.find(
      (category) => categoryName === category.title
    );

    clientHistory.push(`/catalog/${category.id}`);
  };

  onCartOpen = () => {
    clientHistory.push('/cart');
  };

  onGoBack = () => {
    clientHistory.goBack();
  };

  onGoToAddress = () => {
    clientHistory.push('/address');
  };

  onGoToAgreement = () => {
    clientHistory.push('/agreement');
  };

  onGoToHelp = () => {
    clientHistory.push('/about');
  };

  onGoToCheckout = () => {
    if (this.cartService.cartStore.shouldNavigateToCheckout) {
      this.cartService.setShouldNavigateToCheckout(false);
      clientHistory.replace('/checkout');
      return;
    }

    clientHistory.push('/checkout');
  };

  onGoToComment = () => {
    if (this.cartService.cartStore.shouldNavigateToCheckout) {
      return this.onGoToCheckout();
    }

    clientHistory.push('/comment');
  };

  onGoToPayment = () => {
    clientHistory.push('/payment');
  };

  onGoToTime = () => {
    if (this.cartService.cartStore.shouldNavigateToCheckout) {
      return this.onGoToCheckout();
    }

    clientHistory.push('/time');
  };

  onGoToUserInfo = () => {
    if (this.cartService.cartStore.shouldNavigateToCheckout) {
      return this.onGoToCheckout();
    }

    clientHistory.push('/user');
  };

  onGoToUserName = () => {
    if (this.cartService.cartStore.shouldNavigateToCheckout) {
      return this.onGoToCheckout();
    }

    clientHistory.push('/name');
  };

  onGoToUserPhone = () => {
    if (this.cartService.cartStore.shouldNavigateToCheckout) {
      return this.onGoToCheckout();
    }

    clientHistory.push('/phone');
  };

  onGoToPrivacyPolicy = () => {
    clientHistory.push('/privacy');
  };

  onGoToTestScreen = () => {
    clientHistory.push('/testScreen');
  };

  onGoToProduct = (data: GoToProductCommand) => {
    console.info(`open product ${data.smart_app_data.product}`);
    const productName = data.smart_app_data.product;
    const product = this.categoriesService.categoriesStore.currentProducts.find(
      (item) => item.title_short.toLowerCase() === productName.toLowerCase()
    );

    if (!product) {
      console.error(`Unable to find product ${productName}`);
      return;
    }

    clientHistory.push(`/catalog/products/${product.id}`);
  };

  onSkipAddress = async () => {
    await this.cartService.changeCartOption({
      ['isAskAddress']: true,
    });

    await this.cartService.changeAddress(ASK_ADDRESS);

    this.onGoToComment();
  };

  onSkipComment = async () => {
    await this.cartService.changeComment('');

    this.onGoToTime();
  };

  openSearch(): void {
    const action: OpenSearchActionType = {
      action_id: 'openSearch',
    };

    this.smartAppAssistantHelper.sendAction(action);
  }

  private onGoToSearch = () => {
    clientHistory.push('/search');
  };

  private onGoToSearchWithText = (command: MakeGlobalSearchCommand) => {
    this.openSearch();
    const location = `/search?text=${command.smart_app_data.searchText}`;

    if (clientHistory.location.pathname.includes('/search')) {
      clientHistory.replace(location);
    } else {
      clientHistory.push(location);
    }
  };

  private smartAppCommandReactions: {
    validator: (data: GenericSmartAppDataCommand) => boolean;
    callback: (data: GenericSmartAppDataCommand) => void;
  }[] = [
    { validator: isSelectCategoryCommand, callback: this.onCategorySelect },
    { validator: isOpenCartCommand, callback: this.onCartOpen },
    { validator: isCancelPaymentCommand, callback: this.onCancelPayment },
    { validator: isGoBackCommand, callback: this.onGoBack },
    { validator: isGoToAgreementCommand, callback: this.onGoToAgreement },
    {
      validator: isGoToPrivacyPolicyCommand,
      callback: this.onGoToPrivacyPolicy,
    },
    { validator: isGoToTimeCommand, callback: this.onGoToTime },
    { validator: isGoToCommentCommand, callback: this.onGoToComment },
    { validator: isGoToUserNameCommand, callback: this.onGoToUserName },
    { validator: isGoToPaymentCommand, callback: this.onGoToPayment },
    { validator: isGoToProductCommand, callback: this.onGoToProduct },
    { validator: isGoToHelpCommand, callback: this.onGoToHelp },
    { validator: isGoToSearchCommand, callback: this.onGoToSearch },
    {
      validator: isGlobalMakeSearchCommand,
      callback: this.onGoToSearchWithText,
    },
    {
      validator: (data: GenericSmartAppDataCommand) => {
        return data?.smart_app_data?.action === 'gotToTestScreen';
      },
      callback: this.onGoToTestScreen,
    },
  ];
}
