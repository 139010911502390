import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { AppContext } from 'src/lib/contexts/AppContext';
import { Col, Spinner } from '@salutejs/plasma-ui';
import { ApplicationHeader } from 'src/components/ApplicationHeader';
import { RootWrapper } from 'src/components/RootWrapper';
import { Order } from './Order';
import { observer } from 'mobx-react';
import { LoadingStateEnum } from 'src/types/enums/LoadingStateEnum';
import { TabItem } from '@salutejs/plasma-ui/components/Tabs';
import { OrderStatus } from 'src/constants';
import { OrderType } from 'src/types/OrderType';
import { Container } from '@salutejs/plasma-ui';
import {
  EmptyText,
  Inner,
  OrdersCount,
  TabsWithMargin,
  TabsWrapper,
  SpinnerWrapper,
  StyledRow,
} from './StyledComponents';
import { isShowActiveOrdersCommand } from 'src/types/AssistantCommands/SmartAppDataTypeCheckers/isShowActiveOrdersCommand';
import { isShowClosedOrdersCommand } from 'src/types/AssistantCommands/SmartAppDataTypeCheckers/isShowClosedOrdersCommand';
import { FullScreenLoading } from 'src/components/FullScreenLoading';

const TITLE = 'Мои заказы';
const EMPTY = 'Заказов пока нет';

export const Orders: React.FunctionComponent<RouteComponentProps> = observer(
  () => {
    const context = React.useContext(AppContext);
    const storeOrders = context.OrdersService.ordersStore.orders;
    const orders = React.useMemo(() => {
      return storeOrders.filter((order) => order.is_paid === 'yes');
    }, [storeOrders]);
    const isLoading = context.OrdersService.ordersStore.loadingState;
    const [activeTab, setActiveTab] = React.useState(0);
    const tabs = ['Активные', 'Завершенные'];

    const isOrderCompleted = (order: OrderType) =>
      [
        OrderStatus.delivered.id,
        OrderStatus.notDelivered.id,
        OrderStatus.cancelled.id,
      ].includes(order.status_code);

    const getActiveOrdersCount = () => {
      let activeOrdersCount = 0;

      orders.forEach((order) => {
        if (!isOrderCompleted(order)) {
          activeOrdersCount++;
        }
      });

      return activeOrdersCount;
    };

    const getCompletedOrdersCount = () => {
      let completedOrdersCount = 0;

      orders.forEach((order) => {
        if (isOrderCompleted(order)) {
          completedOrdersCount++;
        }
      });

      return completedOrdersCount;
    };

    const activeOrdersCount = getActiveOrdersCount();
    const completedOrdersCount = getCompletedOrdersCount();

    React.useEffect(() => {
      //on mount
      context.OrdersService.getOrders();

      const unsubscribeFromOnActiveOrdersClick =
        context.SmartAppAssistantHelper.conditionalOnData(
          isShowActiveOrdersCommand,
          () => setActiveTab(0)
        );

      const unsubscribeFromOnClosedOrdersClick =
        context.SmartAppAssistantHelper.conditionalOnData(
          isShowClosedOrdersCommand,
          () => setActiveTab(1)
        );

      return () => {
        unsubscribeFromOnActiveOrdersClick();
        unsubscribeFromOnClosedOrdersClick();
      };
    }, []);

    React.useEffect(() => {
      context.AssistantService.openOrders(tabs[activeTab]);
    }, [activeTab]);

    const renderOrders = () => {
      return orders.length > 0 ? (
        activeTab === 0 ? (
          renderActiveOrders()
        ) : (
          renderCompletedOrders()
        )
      ) : (
        <EmptyText>{EMPTY}</EmptyText>
      );
    };

    const renderActiveOrders = () => {
      return orders
        .filter((order) => !isOrderCompleted(order))
        .map((order) => <Order order={order} key={order.id} />);
    };

    const renderCompletedOrders = () => {
      return orders
        .filter((order) => isOrderCompleted(order))
        .map((order) => <Order order={order} key={order.id} />);
    };

    const onFocus = React.useCallback(
      (event: React.FocusEvent<HTMLElement>) => {
        event.target.scrollIntoView({
          behavior: 'smooth',
          inline: 'center',
          block: 'center',
        });
      },
      []
    );

    return (
      <>
        <ApplicationHeader title={TITLE} isBack={true} />

        <RootWrapper>
          <Container>
            {orders.length > 0 && (
              <StyledRow>
                <Col sizeS={12} sizeM={4} sizeXL={8}>
                  <TabsWrapper>
                    <TabsWithMargin stretch>
                      {tabs.map((tab, index) => (
                        <TabItem
                          isActive={index === activeTab}
                          key={index}
                          onClick={() => setActiveTab(index)}
                          onFocus={onFocus}
                        >
                          <Inner>
                            {tab}
                            {index === 0 && (
                              <OrdersCount>{activeOrdersCount}</OrdersCount>
                            )}
                            {index === 1 && (
                              <OrdersCount>{completedOrdersCount}</OrdersCount>
                            )}
                          </Inner>
                        </TabItem>
                      ))}
                    </TabsWithMargin>
                  </TabsWrapper>
                </Col>
              </StyledRow>
            )}

            {isLoading === LoadingStateEnum.processing ? (
              <FullScreenLoading />
            ) : (
              <StyledRow>
                <Col sizeS={12} sizeM={4} sizeXL={8}>
                  {renderOrders()}
                </Col>
              </StyledRow>
            )}
          </Container>
        </RootWrapper>
      </>
    );
  }
);
