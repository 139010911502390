import { inject, injectable } from 'inversify';
import { Identifiers } from '../identifiers';
import { AppInitializationServiceInterface } from '../interfaces/services/AppInitializationServiceInterface';
import { AssistantServiceInterface } from '../interfaces/services/AssistantServiceInterface';
import { CartServiceInterface } from '../interfaces/services/CartServiceInterface';
import { CategoriesServiceInterface } from '../interfaces/services/CategoriesServiceInterface';
import { UserServiceInterface } from '../interfaces/services/UserServiceInterface';

@injectable()
export class AppInitializationService
  implements AppInitializationServiceInterface
{
  @inject(Identifiers.services.AssistantService)
  private assistantService: AssistantServiceInterface;

  @inject(Identifiers.services.UserService)
  private userService: UserServiceInterface;

  @inject(Identifiers.services.CartService)
  private cartService: CartServiceInterface;

  @inject(Identifiers.services.CategoriesService)
  private categoriesService: CategoriesServiceInterface;

  async initialize(): Promise<void> {
    await this.waitForAssistantInitialized();
  }

  private async waitForAssistantInitialized(): Promise<void> {
    this.assistantService.initializeAssistant();

    await Promise.all([
      new Promise<void>((resolve) => {
        const unsubscribe = this.assistantService.onInsets(() => {
          console.info('waitForAssistantInitialized on insets');
          unsubscribe();
          resolve();
        });
      }),
      new Promise<void>((resolve) => {
        const unsubscribe = this.assistantService.onCharacter(() => {
          console.info('waitForAssistantInitialized on character');
          unsubscribe();
          resolve();
        });
      }),
    ]);

    this.assistantService.sendUserInfo();

    await new Promise<void>((resolve) => {
      const unsubscribe = this.assistantService.onInit(({ smart_app_data }) => {
        console.info('waitForAssistantInitialized on init');
        this.userService.setToken(smart_app_data.token);

        this.userService.setUserId(smart_app_data.sub);
        unsubscribe();
        resolve();
      });
    });

    this.assistantService.openOnboarding();

    await new Promise<void>((resolve) => {
      const unsubscribe = this.assistantService.onOpenOnboarding(() => {
        console.info('waitForOnboardingOpened');
        unsubscribe();
        resolve();
      });
    });

    await this.categoriesService.setCategories();

    try {
      await this.cartService.getCart();
    } catch (error) {
      console.warn(error);
    }
  }
}
