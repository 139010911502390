import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Container } from '@salutejs/plasma-ui';
import { ApplicationHeader } from 'src/components/ApplicationHeader';
import { Products } from './Products';
import { RootWrapper } from 'src/components/RootWrapper';
import { AppContext } from 'src/lib/contexts/AppContext';
import { changeQuotes } from 'src/utils/changeQuotes';
import { observer } from 'mobx-react';
import { FullScreenLoading } from 'src/components/FullScreenLoading';

export const Category: React.FunctionComponent<
  RouteComponentProps<{ categoryId: string }>
> = observer((props) => {
  const context = React.useContext(AppContext);
  const categoryId = Number(props.match.params.categoryId);
  const [category, setCurrentCategory] = React.useState(null);

  React.useEffect(() => {
    context.CategoriesService.setCurrentCategory(categoryId).then(() => {
      setCurrentCategory(
        context.CategoriesService.categoriesStore.currentCategory
      );
    });
  }, [context.CategoriesService, categoryId]);

  const title = changeQuotes(category?.title);

  return (
    <>
      <ApplicationHeader title={title} isBack isCart isOrders isSearch />

      {category && (
        <>
          <RootWrapper>
            <Container>
              <Products category={category} />
            </Container>
          </RootWrapper>
        </>
      )}
      {!category && <FullScreenLoading />}
    </>
  );
});
