import { injectable } from 'inversify';
@injectable()
export abstract class AbstractStore {
  updateState = (state: Partial<Omit<this, 'updateState'>>) => {
    for (const key in state) {
      if (!(key in this)) continue;
      // @ts-expect-error skip some mindblowing typescript type casting
      this[key] = state[key];
    }
  };
}
