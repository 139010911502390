import * as React from 'react';
import styled from 'styled-components';
import { Headline3, Image } from '@salutejs/plasma-core';
import { mediaQuery } from '@salutejs/plasma-ui';
import { CategoryType } from 'src/types';
import { changeQuotes } from 'src/utils/changeQuotes';
import placeholder from '../../assets/empty.svg';
import { screenResolution } from 'src/constants';

const IMAGE_ALT = 'Цветы';

const Wrapper = styled.div`
  position: relative;
  width: 10rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${mediaQuery('XL')(`
    width: 13.75rem;
  `)}
`;

const Title = styled(Headline3)`
  font-weight: 500;
  overflow: hidden;
`;

const ImageWrapper = styled.div`
  width: 10rem;
  height: 10rem;
  margin-bottom: 0.625rem;
  border-radius: 50%;

  ${mediaQuery('XL')(`
    width: 13.75rem;
    height: 13.75rem;
  `)}
`;

export const MainItem: React.FunctionComponent<{ item: CategoryType }> = (
  props
) => {
  const imageS = props.item.images2.lowres_url;
  const imageM = props.item.images2.midres_url;
  const imageL = props.item.images2.highres_url;

  return (
    <Wrapper>
      <ImageWrapper className="mainItemWrapper">
        <Image
          src={imageM || placeholder}
          srcSet={`${imageM} ${screenResolution.m}w, ${imageL} ${screenResolution.l}w`}
          alt={IMAGE_ALT}
        />
      </ImageWrapper>

      <Title>{changeQuotes(props.item.title)}</Title>
    </Wrapper>
  );
};
