import { colors } from '@salutejs/plasma-tokens';
import { ProductSortTypeEnum } from 'src/types/ProductSortTypeEnum';
import { SortOptionType } from 'src/types/SortOptionType';
import { ProductFilterType } from './types/ProductFilterType';

export const BRAND_COLOR = '#F3D711';

export const sortOptions: SortOptionType[] = [
  { value: ProductSortTypeEnum.default, label: 'по популярности' },
  { value: ProductSortTypeEnum.priceUp, label: 'по цене' },
  { value: ProductSortTypeEnum.priceDown, label: 'по цене' },
  { value: ProductSortTypeEnum.time, label: 'по новизне' },
  { value: ProductSortTypeEnum.timeReverse, label: 'по новизне' },
];

export const FILTER_NAMES = [
  'трех тысяч',
  'трех тысяч до шести тысяч',
  'шести тысяч до десяти тысяч',
  'десяти тысяч',
];

export const FiltersMap: { [key: string]: ProductFilterType } = {
  ['трех тысяч']: {
    lowest: 0,
    highest: 3000,
  },
  ['трех тысяч до шести тысяч']: {
    lowest: 3000,
    highest: 6000,
  },
  ['шести тысяч до десяти тысяч']: {
    lowest: 6000,
    highest: 10000,
  },
  ['десяти тысяч']: {
    lowest: 10000,
    highest: 9999999,
  },
};

export const screenResolution = {
  s: 275,
  m: 768,
  l: 1280,
  xl: 1920,
};

export const OrderStatus = {
  new: { id: 1, name: 'Новый', color: `${colors.accent}` },
  confirmed: { id: 2, name: 'Подтверждён', color: `${colors.accent}` },
  manufactured: { id: 3, name: 'Изготавливается', color: `${colors.accent}` },
  collected: { id: 4, name: 'Собран', color: `${colors.accent}` },
  shipped: { id: 5, name: 'Отгружен', color: `${colors.accent}` },
  delivered: { id: 6, name: 'Доставлен', color: `${colors.accent}` },
  notDelivered: { id: 7, name: 'Не доставлен', color: `${colors.critical}` },
  cancelled: { id: 8, name: 'Отменён', color: `${colors.critical}` },
};

export const PHOTO_SERVICE_ID = 13;

export const SUPPORT_PHONE = '+7 (495) 974-70-90';

export const ASK_ADDRESS = 'Узнаем адрес у получателя';
