import * as React from 'react';
import { AppContext } from 'src/lib/contexts/AppContext';
import styled from 'styled-components';

const Spacer = styled.div`
  min-height: 4rem;
`;

export const MobileCartButtonSpacer = () => {
  const context = React.useContext(AppContext);
  const cartAmount = context.CartService.cartStore.amount;
  if (!cartAmount) return null;
  return <Spacer />;
};
